

@commonFontSize: 18px;

.container {
  width: 1100px;
  margin: 0 auto;
  padding: 0 10px 0 10px;
}

#content.full_width { width:100%;}


// sidebar
#sidebar_left { float:left; width:361px; font-size: 16px;}

.edit_layout_content li .std_block {  border:none;  border-radius:0;  margin:0; background:none;}
.std_block {  display:block;  clear:both; background:#FFF;  border:1px solid #CCC;  border-radius:9px;  position:relative;  min-height:100px;}
.std_block:after {  content:''; display:table;  clear:both;}
.std_block.ui-sortable-handle.ui-sortable-helper {  box-shadow:0 0 100px 0 rgba(0, 0, 0, 0.3);}
.std_block .std_block_shadow {  display:block;}
.std_block .std_block_header { background:#FFF;}
.std_block_header{border:none;}
.ordering_tbl { width:100%; margin:0 0 22px 0;}
.ordering_tbl td {  height:22px;  line-height:22px; border-right:2px solid #575757; text-align:center;  border-bottom:10px solid #FFF;  padding:0px 9px 0px 9px;}
.ordering_tbl td:first-child {  border-right:none;  width:15px; text-align:right; padding:0;}
.ordering_tbl td:first-child + td { border-right:none;  width:100%; padding:0;}
.ordering_tbl td:last-child { padding:0;}

.ordering_box { padding:23px 36px 17px 20px;}
.ordering_box:after { content:''; display:table;  clear:both;}

.view_all_requests {  float:left; margin:5px 5px 0 0;  font-size:21px; line-height:28px; text-decoration:underline;}
.view_all_requests:hover {  text-decoration:none;}

.recent_actions_refresh { float:left; width:26px; height:25px;  background:url(../../img/ico_refresh_gray.png); margin:2px 0 0 6px;}

// content
#content .smile_man_block:first-child { margin-top:67px;}
#content:only-child { width:100%;}
#content.full_width { width:100%;}
#content {  float:right;  width:689px;}
#sidebar_right ~ #content { float:left; width:720px;}
#content .std_block_2:first-child { margin-top:67px;}
#content .std_block_3:first-child { margin-top:67px;}

#content .smile_man_block:first-child { margin-top:67px;}
.smile_man_block {  background:#F6F6F6; min-height:90px;  margin:0 0 24px 0;  position:relative;  clear:both;}
.smile_man_block:after {  content:''; display:table;  clear:both;}
.smile_man_block.type_2 { border-radius:9px;  border:1px solid #CCC;  margin-top:67px;  background:#FFF;}
.smile_man_block.type_2 .smb_right {  background:#EDEDED; padding:38px 51px 21px 19px;  margin:0; border-radius:0 9px 9px 0;}
.smile_man_block.type_2 .smb_left { padding:15px 227px 15px 17px;}

.affiliate_banner { float: right; text-align:center;  min-height:150px; width:427px;  padding:16px 0 0 0;   vertical-align:bottom; border: 1px solid #CCC;}

.edit_layout_content li .std_block_header,.edit_layout_content li .std_block_content,.edit_layout_content li .std_block_close,.edit_layout_content li .std_block_shadow { display:none;}
.std_block_content {  position:relative;}

.the_button_2.ordering_refresh {  display:block;  height:22px;  width:65px; float:left; margin:0 7px 0 0; font-size:0px;  text-indent:-9999px;  background-image:url(../../img/ico_refresh_white.png);  background-repeat:no-repeat;  background-position:center; background-color: #888888}


.edit_layout_box .std_block_shadow {  height:16px;}
.edit_layout_content li .std_block_header,.edit_layout_content li .std_block_content,.edit_layout_content li .std_block_close,.edit_layout_content li .std_block_shadow { display:none;}
.std_block_shadow { top:100%; left:10px;  right:10px; position:absolute;  height:10px;  overflow:hidden;}
.std_block_shadow:before, .std_block_shadow:after { content:''; display:block;  float:left; width:50%;  height:100%;  background:url(../../img/bgr_std_block_shadow.png) no-repeat;}
.std_block_shadow:before {  background-position:0 bottom;}
.std_block_shadow:after { background-position:right bottom;}
.std_block.banners_container {  border:none;  border-radius:0px;}
.std_block.banners_container .std_block_shadow {  display:none;}
.order_slider_item .std_block_shadow {  height:4px; left:7px; right:7px;  margin:1px 0 0 0;}

.banner2 {  float:left; border: 1px solid #CCC;}

.edit_layout_content li .std_block_header,.edit_layout_content li .std_block_content,.edit_layout_content li .std_block_close,.edit_layout_content li .std_block_shadow { display:none;}
.std_block_header { background:#F6F6F6 !important; line-height:36px; font-size:22px; padding:13px 8px 7px 8px; border-radius:8px 8px 0 0;  min-height:56px;}
.std_block_header:after { content:''; display:table;  clear:both;}
.std_block_header h2 {  font-size:33px; font-weight:bold; float:right;  color:#000;}
.std_block_header h2 small {  font-weight:normal;}
.std_block_header ~ * .graph_widget_tabs a {  border-top:1px solid #CCC;}
.std_block_header ~ * .graph_widget_tabs a:first-child {  border-radius:0;}
.my_users_block .std_block_header { padding:24px 21px 23px 21px;  border-radius:0;}
.fees_report_tbl_1 .std_block_header {  padding:24px 21px 23px 21px;  border-radius:0;  margin:0 0 24px 0;}
.std_block.popular_themes_block .std_block_header { background:#FFF;  border-bottom:1px solid #CCC;}

// graph in the content
.the_button_2.view_all_comissions { display:block;  line-height:32px; height:34px;  left:12px;  top:10px; position:absolute;  padding:0 15px 0 15px;}
.the_button_2.view_all_comissions:hover{
  color: #fff;
  text-decoration: none;
}
.graph_widget_container {}
.graph_widget_container:after { content:''; display:table;  clear:both;}

.graph_widget_graph_box {  min-height:278px; position:relative;  direction:ltr;  margin:0 -220px 0 0;}
.graph_2 .graph_widget_graph_box {  padding-top:60px;}
.graph_widget_graph_box.detailed {  margin-right:-240px;  padding:0 240px 0 0;}
.graph_widget_graph_box h3 {  background:#f6f6f6; border-radius:8px 0 0 0;  color:#9D9D9C;  padding:9px 23px 2px 0; font-size:39px; line-height:45px;}
.graph_widget_graph_box.detailed .users_comissions_graph {  height:346px; padding:35px 75px 0 55px;}
.business_activity .graph_widget_graph_box {  padding:0 220px 0 0;}

.detailed_graph_right_part {  float:right;  width:240px;  border-left:1px solid #CCC; background:#f6f6f6; padding:82px 27px 78px 30px;  position:relative;  z-index:10; border-radius:0 8px 8px 0;}
.detailed_graph_right_part h4 { font-size:26px; line-height:24px; color:#000; border-bottom:1px solid #CCC;}
.detailed_graph_right_part a {  color:#0D83DD;}
.detailed_graph_right_part section {  border-bottom:1px solid #CCC; font-size:25px; margin:0 0 13px 0;  padding:0 4px 0 0;}

.detailed_graph_table { width:100%; font-size:18px;}
.detailed_graph_table td {  text-align:left;  border-right:1px solid #CCC;  height:28px;  vertical-align:middle;}
.detailed_graph_table td:first-child {  text-align:right; border-right:none;}
.detailed_graph_table tr:first-child td { padding-top:8px;}
.detailed_graph_table tr:last-child td {  padding-bottom:8px;}

.graph_widget_tabs_valign {
  display:table-cell; width:100%; height:100%;  vertical-align:middle;  padding:10px 69px 0 0;

  strong {
    font-size: 18px;
  }
}
.graph_tab_icon_3:before {  content: '';  display: block;  position: absolute;  top: 9px;  right: 7px;  width: 69px;  height: 66px;  background: url(../../img/sprite_income_graph_tabs.png) 0 -200px no-repeat;  z-index: 0;}
.graph_tab_icon_3:hover:before, .graph_tab_icon_3.active:before { background-position:-100px -200px;}

.graph_widget_tabs {  float:right;  width:220px;  display:table;  height:100%;}
.graph_widget_tabs a {  display:table;  width:100%; border-left:1px solid #CCC; color:#DADADA;  font-size:32px; line-height:30px; min-height:139px; position:relative;  z-index:10; height:50%;}
.std_block_header ~ * .graph_widget_tabs a {  border-top:1px solid #CCC;}
.graph_widget_tabs a + a {  border-top:1px solid #CCC;}
.graph_widget_tabs a:first-child {  border-radius:0 8px 0 0;}
.std_block_header ~ * .graph_widget_tabs a:first-child {  border-radius:0;}
.graph_widget_tabs a:last-child { border-radius:0 0 8px 0;}
.graph_widget_tabs a span { position:relative;  z-index:10; display:block;}
.graph_widget_tabs a strong { display:block;  font-size:20px; line-height:37px; font-weight:normal; position:relative; z-index:10;}
.graph_widget_tabs a:hover {  color:#0a81dd;  text-decoration:none; background:#e7f4fe;}
.graph_widget_tabs a[class*="graph_tab_icon_"]:before { content:''; display:block;  position:absolute;  z-index:0;}
.graph_widget_tabs .active {  color:#0a81dd;  background:#e7f4fe; font-weight:bold;}
.graph_widget_tabs .active:after {  content:''; display:block;  position:absolute;  width:12px; height:37px;  right:100%; top:50%;  background:url(../../img/pic_income_tab_active.png);  margin:-18px 0 0 0;}
.mac.firefox .graph_widget_tabs .active:after { margin-right:-1px;}
.graph_widget_tabs .active strong { font-weight:bold;}
.graph_2 .graph_widget_tabs a { height:160px;}
.business_activity .graph_widget_tabs a { min-height:175px;}

.graph_tab_icon_2:before {  content:''; display:block;  position:absolute;  top:9px;  right:7px;  width:69px; height:66px;  background:url(../../img/sprite_income_graph_tabs.png) 0 -100px no-repeat;  z-index:0;}
.graph_tab_icon_2:hover:before, .graph_tab_icon_2.active:before { background-position:-100px -100px;}



.the_table_type_1 { width:100%; color:#000000;  clear:both; border:1px solid #CCC;}
.oldie .the_table_type_1 {  /*table-layout:fixed;*/ float:right;  width:100%; clear:both;}
.the_table_type_1 tr > * {  text-align:center;  padding:10px; vertical-align:middle;  white-space:nowrap;}
.the_table_type_1 td {  border:1px solid #CCC;}
.the_table_type_1 thead th {  background:#0d83dd; color:#FFF; border-right:1px solid transparent; border-left:1px solid transparent;}
.the_table_type_1 thead th:first-child {  border-right:1px solid #CCC;}
.the_table_type_1 thead th:last-child { border-left:1px solid #CCC;}
.the_table_type_1 thead td {  background-color:#ededed;}
.the_table_type_1 thead input[type="text"], .the_table_type_1 thead select {  display:block;  width:100%; padding:0 5px;  text-align:center;  border:2px inset #e5e5e5; height:32px;  background-color:#F6F6F6;}
.the_table_type_1 tr > * > input[type="checkbox"] { display:block;  padding:0;  margin:0 auto;}
.the_table_type_1 thead select, .the_table_type_1 thead .custom_select_type_1 { min-width:60px;}
.the_table_type_1 thead input[type="text"]:focus, .the_table_type_1 thead select:focus {  background-color:#FFF;}
.the_table_type_1 .custom_select_type_1 { display:block;}
.the_table_type_1 tbody tr:nth-of-type(even) td { background:#f6f6f6;}
.the_table_type_1 small { font-size:16px;}
.the_table_type_1 tbody tr:hover td { background:#e7f4fe; text-decoration:underline;}
.the_table_type_1 tbody tr:hover td > * { text-decoration:underline;}
.the_table_type_1 tr:hover .nc_buttons {  display:block;}
.the_table_type_1.small_tbl { font-size:18px;}
.the_table_type_1.small_tbl tr > * {  padding:7px;}
.the_table_type_1.small_tbl thead input[type="text"], .the_table_type_1.small_tbl thead select {  height:18px;  font-size:14px; border-width:1px; }
.the_table_type_1.small_tbl thead select {  padding-left:22px;  background-position:3px 5px;    background-size:16px auto;  -moz-background-size:16px auto; -webkit-background-size:16px auto;}
.my_users_tab .the_table_type_1 { border:none;}
.my_users_tab .the_table_type_1 tr > *:first-child {  border-right:none;}
.my_users_tab .the_table_type_1 tr > *:last-child { border-left:none;}
.my_users_tab .the_table_type_1 tbody tr:last-child > * { border-bottom:none;}

.table_controls_type_1 {  border:2px dashed #CCC; float:right;  margin: 0px 153px 29px 0; clear:both; padding:18px 16px 18px 16px;  position:relative;}
.table_controls_type_1:before { content:''; display:block;  position:absolute;  top:42px; left:100%;  border-top:2px dashed #CCCCCC;  border-right:2px dashed #CCCCCC;  width:124px;  height:51px;}
.table_controls_type_1:after {  content:''; display:block;  position:absolute;  width:0;  height:0; right:-133px; top:95px; border:10px solid transparent;  border-top:17px solid #CCC;}
.table_controls_type_1 .the_button_2 {  float:right;  font-size:30px; height:48px;  line-height:46px; padding:0 28px;}
.table_controls_type_1 .the_button_2.export:before {  content:''; display:inline-block; width:20px; height:27px;  margin:0 0 0 8px; position:relative;  top:3px;  background:url(../../img/sprite_tbl_icons.png) 0 -100px no-repeat;}
.table_controls_type_1 .the_button_2 + .the_button_2 {  margin:0 12px 0 0;}

.name_cell .the_button_2.print {  background-image:url(../../img/sprite_tbl_icons.png); background-position:2px -48px;}
.name_cell .the_button_2.print:before { display:none;}
.the_button_2.print:before {  content:''; display:inline-block; width:35px; height:27px;  margin:0 0 0 8px; position:relative;  top:5px;  background:url(../../img/sprite_tbl_icons.png) 0 -150px no-repeat;}
.table_controls_type_2 .the_button_2.print:before { content:''; display:inline-block; vertical-align:top; width:23px; height:23px;  margin:0 0 0 8px; position:relative;  top:2px;  background:url(../../img/sprite_tbl_icons.png) 0 -50px no-repeat;}
.the_button_3.ctrl_1.print:before { width:26px; height:19px;  margin:-2px 0 -2px 5px; background-position:0 -550px;}
.std_block_4_buttons_box .the_button_2.print:before { background-position:0 -100px; width:22px; height:16px;  margin:-3px 0 3px 5px;}
.tbl_type_5_controls .print {background-position:0 -50px;}
.the_table_type_6_controls .the_button_2.print:before { content:''; display:inline-block; width:26px; height:19px;  margin:-2px 0 0 8px;  position:relative;  top:5px;  background:url(../../img/sprite_tbl_icons.png) 0 -550px no-repeat;}


.tbl_time_range { width:137px;  margin:auto;  position:relative;}
.tbl_time_range > div { width:50%;  float:right;  padding:0 0 0 8px;}
.tbl_time_range > div input { width:100%; text-align:center;}
.tbl_time_range > div ~ div { float:left; padding:0 8px 0 0;}
.tbl_time_range span {  float:right;  width:16px; margin:0 -8px 0 -8px; font-size:20px; line-height:32px; text-align:center;  position:relative;}
.small_tbl .tbl_time_range span { line-height:18px; font-size:18px;}
.generate_report_form .tbl_time_range { width:225px;  float:left;}
.generate_report_form .tbl_time_range input { display:block;  font-size:18px; text-align:center;}

.smb_buttons_box .the_button { float:left; height:54px;  line-height:54px; padding:0 15px 0 15px;  margin:0 21px 0 0;  font-size:32px;}
.smb_buttons_box .the_button:before { content:''; display:inline-block; width:16px; height:16px;  background:url(../../img/ico_plus_16x16.png) no-repeat; margin:0 0 0 10px;  position:relative;  top:1px;}
.the_button, .the_button[type="submit"] { display:inline-block; border:1px solid #659C17; border-radius:3px;  background:#85C51F; color:#FFF; padding:0 60px 0 60px;  font-weight:bold; line-height:50px; text-align:center;}
input.the_button[type="submit"], button.the_button[type="submit"] { height:39px;  line-height:39px;}
.the_button:hover {color:#fff; text-decoration:none; background-color:#74ab1b;}
.the_button.load_more_action {  float:left; padding:0 8px 0 8px;  line-height:34px; height:36px;  font-size:19px;}
.the_button.load_more_action:before { content:''; display:inline-block; width:16px; height:16px;  background:url(../../img/ico_plus_16x16.png) no-repeat; margin:0 0 0 7px; position:relative;  top:2px;}
.the_button.press_to_update { float:left; height:46px;  width:202px;  line-height:46px; font-size:31px; border-radius:3px;  margin:36px 0 0 0;}
.the_button.back_to_affiliate { height:47px;  padding:0 16px 0 16px;  font-size:31px; line-height:46px; border-radius:3px;}
.the_button.bd_add_fields { float:left; min-width:144px;  height:38px;  line-height:38px; font-size:22px;}
.the_button.bd_add_fields:before {  content:''; display:inline-block; width:13px; height:13px;  background:url(../../img/ico_plus_16x16.png) center no-repeat;  margin:0 0 0 12px;    background-size:contain;  -moz-background-size:contain; -webkit-background-size:contain;}
.email_settings_form button.the_button[type="submit"] { float:right;  border-width:1px; height:35px;  line-height:35px; width:167px;  padding:0 5px 0 5px;  font-size:20px;}
.email_settings_form button.the_button[type="submit"]:before {  content:''; display:inline-block; width:12px; height:12px;  margin:0 0 0 7px; background:url(../../img/ico_plus_16x16.png) center no-repeat;}
.the_button.add_comment { float:left; margin:0 0 0 18px;  font-size:22px; width:130px;  height:38px;  line-height:36px;}
.the_button.add_comment:before {  content:''; display:inline-block; width:15px; height:15px;  background:url(../../img/ico_plus_16x16.png) center no-repeat;  margin:0px 0px -1px 8px;    background-size:contain;  -moz-background-size:contain; -webkit-background-size:contain;}
.doc_design_gallery_left .the_button {  height:32px;  width:119px;  font-size:17px; font-weight:normal; line-height:30px; margin:0 3px 13px 3px;}
.doc_design_gallery_left .the_button.delete { background:#F90D0D; border-color:#D10909;}
.doc_design_gallery_left .the_button.delete:before {  content:''; display:inline-block; width:18px; height:18px;  background:url(../../img/sprite_iconset_1.png) 0 -50px no-repeat; margin:-3px 0 -3px 5px;}
.doc_design_gallery_left .the_button.delete:hover { background:#D10909;}
.save_changes_buttons_box .the_button, .save_changes_buttons_box .the_button_2 {  font-size:23px; line-height:28px; height:29px;  font-weight:bold; float:right;}
.the_button.choose {  display:block;  width:100px;  height:36px;  margin:auto;  font-size:28px; line-height:39px; border:none;}
.generate_report_form .the_button[type="submit"] {  display:block;  width:250px;  height:40px;  line-height:40px; font-size:28px; border-width:1px; margin:46px auto 0 auto;}

.the_button.load_more_action { float:left; padding:0 8px 0 8px;  line-height:34px; height:36px;  font-size:19px;}
.the_button.load_more_action:before { content:''; display:inline-block; width:16px; height:16px;  background:url(../../img/ico_plus_16x16.png) no-repeat; margin:0 0 0 7px; position:relative;  top:2px;}

td.name_cell {  width:100%; text-align:right;}
.name_cell > span { float:right;  line-height:30px;}
.name_cell .the_button_2.export { background-image:url(../../img/sprite_tbl_icons.png); background-position:2px 2px;}
.name_cell .the_button_2.print {  background-image:url(../../img/sprite_tbl_icons.png); background-position:2px -48px;}
.name_cell .the_button_2.print:before { display:none;}
.name_cell .the_button_2.print_2 {  width:37px;}
.name_cell .the_button_2.print_2:before { content:''; display:block;  width:26px; height:19px;  background:url(../../img/sprite_tbl_icons.png) 0 -550px no-repeat;  margin:4px auto 0 auto;}
.name_cell .the_button_2.duplicate {  width:37px;}
.name_cell .the_button_2.duplicate:before { content:''; display:block;  width:27px; height:17px;  background:url(../../img/sprite_tbl_icons.png) 0 -950px no-repeat;  margin:5px auto 0 auto;}



.the_button_2.my_users_send_msg {  float:left;  height:36px;  line-height:34px; font-size:20px; }
.the_button_2.my_users_send_msg:before {  content:''; display:inline-block; position:relative; top:3px;}
.the_button_2.my_users_send_msg:hover,.the_button_2.my_users_send_msg:focus,.the_button_2.my_users_send_msg:active,.the_button_2.my_users_send_msg:visited {  color: white; text-decoration: none;}

.the_table_type_1 tr:hover .nc_buttons {  display:block;}
.nc_buttons { float:left; display:none;}
.selected .nc_buttons { display:block;}
.nc_buttons .the_button_2 { display:block;  float:right;  margin:0 4px 0 0; width:30px; height:30px;  border-width:1px; padding:0;}

// popup
.the_blue_popup_2 {
  background:#FFF;
  border:8px solid #ddecfc;
  border-left-width:6px;
  border-radius:12px;
  padding:15px 32px 0px 41px;
  box-shadow:3px 3px 0 0 rgba(0, 0, 0, 0.5);
  width:1000px;
  min-height:185px;
  text-align:left;
  position:relative;
  direction: rtl;
  text-align: right;
  color: #666666;
  font-size: @commonFontSize;
  font-family: Arial, sans-serif;

  .btn-success {
    margin: 20px 0;
  }

  h1 {
    line-height: 50px;
    border-bottom: 10px solid #ddecfc;
    margin-left: -40px;
    margin-right: -40px;
    padding-right: 50px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  @imageSize: 80px;

  .your_link_box {
    padding-right: @imageSize + 30;
    font-size: @commonFontSize;

    &:before {
        width:@imageSize;  height:@imageSize;
        background-size: contain;
    }
  }

  .std_block_content.users {
    display: block;
    clear: both;
  }

  .my_users_tabs {
    float: left;

    a {
      background: none;
      border: none;
      color: gray;
    }

    a.active {
      background-color: gray;
      color: white;
    }

  }

  .the_table_type_1 thead th {
    background: gray !important;
  }

  .inviter_link_text {
    float: right;
  }

  .your_message {
    border-top: 1px solid black;
    padding: 20px;
  }

  textarea {
    border: 0;
    background-color: #f6f6f6;
    width: 100%;
    height: 200px;
  }
}

.the_blue_popup_2:before {  content:''; display:block;  position:absolute;  width:0;  height:0; border:30px solid transparent;  border-top-color:#DDEDFD; border-right-color:#DDEDFD; top:0;  right:0;}
.popup_save_changes_box {
  min-height:136px;

  .std_block {
    clear: none;
  }
}
.popup_save_changes_box h4 {  font-size:36px; line-height:44px; color:#0D83DD;  margin:0 0 18px 0;}
.the_blue_popup_close { display:block;  position:absolute;  top:3px;  right:2px;  width:22px; height:22px;  border-radius:22px; background:#6EB5EB url(../../img/ico_popup_close.png) 0 0 no-repeat;}
.the_blue_popup_close:hover { background-position:0 -50px;}

.save_changes_buttons_box { font-size:20px; float:left; color:#3A3A3A;}
.save_changes_buttons_box span {  float:right;  margin:0 7px 0 7px;}
.save_changes_buttons_box .the_button, .save_changes_buttons_box .the_button_2 {  font-size:23px; line-height:28px; height:29px;  font-weight:bold; float:right;}
.save_changes_buttons_box .cancel { width:auto; height:auto;  border:none;  background:none;  font-weight:normal; color:#00F; padding:0;  font-size:20px; line-height:29px;}
.save_changes_buttons_box .cancel:hover { text-decoration:underline;}

// select box:
.custom_select_type_1 .selectboxit-container {  width:100%;}
.custom_select_type_1 .selectboxit-container .selectboxit { display:block;  border:2px inset #e5e5e5; border-radius:0;  background-color:#F6F6F6; width:100%; padding:0 7px 0 40px; height:33px;  font:24px/1.4 'fahrenheit', Arial, Helvetica, sans-serif;   color:#575757;}
.custom_select_type_1 .selectboxit-container .selectboxit.selectboxit-focus { background:#FFF;}
.custom_select_type_1 .selectboxit-container .selectboxit-options { border:2px solid #CCCCCC; text-align:right; color:#575757;  font-size:24px; line-height:33px; font-weight:normal;}
.small_tbl .custom_select_type_1 .selectboxit-container .selectboxit {  border-width:1px; font-size:14px; height:18px;  padding:0 5px 0 22px;}
.small_tbl .custom_select_type_1 .selectboxit-container .selectboxit-options {  border-width:1px; font-size:14px; line-height:20px;}
.small_tbl .custom_select_type_1 .selectboxit-container .selectboxit-options a {  height:26px;  line-height:26px;}
.custom_select_type_2 .selectboxit-container {  width:100%;}
.custom_select_type_2 .selectboxit-container .selectboxit { display:block;  border:1px solid #0D83DD; border-radius:4px;  background:#6EB5EB; width:100%; padding:0 7px 0 40px; height:38px;  font:22px/40px 'fahrenheit', Arial, Helvetica, sans-serif;    color:#FFFFFF;}
.custom_select_type_2 .selectboxit-container .selectboxit.selectboxit-focus { background:#0D83DD;}
.custom_select_type_2 .selectboxit-container .selectboxit.selectboxit-open {  border-radius:4px 4px 0 0;}
.custom_select_type_2 .selectboxit-container .selectboxit-options { border:1px solid #0D83DD; text-align:right; color:#FFFFFF;  background:#6EB5EB; font-size:24px; line-height:33px; border-radius:0 0 4px 4px;}
.custom_select_type_3 .selectboxit-container {  width:100%;}
.custom_select_type_3 .selectboxit-container .selectboxit { display:block;  border:1px solid #CCCCCC; border-radius:2px;  background:#FFFFFF; width:100%; padding:0 7px 0 40px; height:35px;  font:20px/37px 'fahrenheit', Arial, Helvetica, sans-serif;    color:#575757;}
.custom_select_type_3 .selectboxit-container span { height:35px;  line-height:37px;}
.custom_select_type_3 .selectboxit-container .selectboxit.selectboxit-focus { background:#FFF8D5; border-color:#FFB600;}
.custom_select_type_3 .selectboxit-container .selectboxit.selectboxit-open {  }
.custom_select_type_3 .selectboxit-container .selectboxit-options { border:1px solid #CCCCCC; text-align:right; color:#575757;  background:#FFFFFF; font-size:20px; line-height:33px; border-radius:0 0 4px 4px;  right:100%; margin-top:-35px;}
.custom_select_type_3 .selectboxit-container.selectboxit-bottom-top .selectboxit-options {  margin-top:35px;}
.custom_select_type_3[lang="en"] .selectboxit-container .selectboxit {  font:18px/40px 'Myriad Pro', Arial, Helvetica, sans-serif;  padding:0 40px 0 7px;}
.custom_select_type_3[lang="en"] .selectboxit-container .selectboxit-options {  left:100%;  right:auto; font-size:18px; text-align:left;}
.custom_select_type_3.business_details_email .selectboxit-container .selectboxit {  font-size:16px; font-family:'Myriad Pro', Arial, Helvetica, sans-serif;}
.payment_info .row .custom_select_type_1 .selectboxit-container .selectboxit {  border:1px solid #CCC;  background-color:#F6F6F6; height:36px;}
.payment_info .row .custom_select_type_1 .selectboxit-container .selectboxit-options {  border-width:1px;}
.payment_info .row .card_info .custom_select_type_1 .selectboxit-container .selectboxit { font-size:23px; padding:0 3px 0 18px;}
.generate_report_form .custom_select_type_1 .selectboxit-container .selectboxit { background-color:#FFF;}
.generate_report_form .custom_select_type_1 .selectboxit-container .selectboxit-options { right:100%; border-width:1px; margin-top:-33px; min-width:150px;}
.generate_report_form .custom_select_type_1 .selectboxit-container.selectboxit-bottom-top .selectboxit-options {  margin-top:33px;  }

.custom_select_type_1 .selectboxit-arrow-container {  display:block;  position:absolute;  top:0;  left:0; right:auto; height:100%;  width:36px;}
.custom_select_type_1 .selectboxit .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow {  width:0;  height:0; border:9px solid transparent; border-top:9px solid #dadada; margin:-4px auto 0 auto;  top:50%;}
.small_tbl .custom_select_type_1 .selectboxit-arrow-container { width:22px;}
.small_tbl .custom_select_type_1 .selectboxit .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow { border-width:7px; margin-top:-3px;}
.custom_select_type_2 .selectboxit-arrow-container {  display:block;  position:absolute;  top:0;  left:0; right:auto; height:100%;  width:36px;}
.custom_select_type_2 .selectboxit .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow {  width:0;  height:0; border:9px solid transparent; border-top:9px solid #0969b0; margin:15px auto 0 auto;  top:0;}
.custom_select_type_2 .selectboxit.selectboxit-focus .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow {  border-top-color:#FFF;}
.custom_select_type_3 .selectboxit-arrow-container {  display:block;  position:absolute;  top:0;  left:0; right:auto; height:100%;  width:36px;}
.custom_select_type_3 .selectboxit .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow {  width:0;  height:0; border:6px solid transparent; border-top:6px solid #DADADA; margin:15px auto 0 auto;  top:0;}
.custom_select_type_3 .selectboxit.selectboxit-focus .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow {  border-top-color:#FFB600; }
.custom_select_type_3[lang="en"] .selectboxit-arrow-container { left:auto;  right:0;}
.payment_info .row .card_info .custom_select_type_1 .selectboxit-arrow-container {  width:23px;}

#dtUsersTable_wrapper > .row {
    display: none;
    visibility: hidden;
}

#dtUsersTable>tfoot>tr>th>span>input {
    height: 18px;
}

#dtUsersTable>tbody>tr>td, #dtUsersTable>tbody>tr>th, #dtUsersTable>tfoot>tr>td, 
#dtUsersTable>tfoot>tr>th, #dtUsersTable>thead>tr>td, #dtUsersTable>thead>tr>th {
    font-size: 15px;
    padding: 4px;
}

div.my_users_tabs a {
    direction: ltr;
}