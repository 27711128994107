.right_side_menu a{
    color:#00F;
}
.help-popup-description{
    position: absolute;
    top: 7px;
    left: 92px;
}
.right_side_menu li a.active{
    border-bottom: none;
    border: none;
    font-weight: normal;
}

.export,.view,.email,.print{
    cursor: pointer;
}
.export-filter-settings{
    .tab-content {
        > .tab-pane {
            margin-top: 0;
            display: none;
        }
        > .active {
            background: #F6F6F6;
            display: block;
        }
    }
    .nav-tabs{
        background: none;
        margin: 0;
    }
    .nav-tabs>li.active>a,
    .nav-tabs>li.active{
        background: #f6f6f6;
        font-weight: bold;
    }

    .ui-select-multiple.ui-select-bootstrap input.ui-select-search{
        outline: none;
        height: 2.2em;
        background: #f6f6f6;
        border-radius: 2px!important;
        margin-bottom: 3px;
        border: 1px solid #ccc;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
        width: 100%!important;
    }
    .ui-select-multiple.form-control{
        border:none!important;
        box-shadow: none;
        -webkit-box-shadow: none;
    }
    .ui-select-multiple.ui-select-bootstrap span.btn-xs{
        padding: 1px 2px;
        font-size: 11px;
        line-height: 1;
        border-radius: 3px;
        padding-left: 0;
    }
    .ui-select-bootstrap > .ui-select-match > .btn{
        display: block;
        border: 2px inset #e5e5e5;
        border-radius: 0;
        background-color: #FFF;
        width: 100%;
        padding: 0 7px 0 40px;
        height: 33px;
        font: 20px/1.4 fahrenheit,Arial,Helvetica,sans-serif;
        color: #9d9d9b;
        cursor: pointer;
        outline: 0;
    }
    .custom_select_type_4_box .SumoSelect > .CaptionCont {
        color: #9d9d9b;
    }

    .ui-select-bootstrap .ui-select-toggle > .caret{
        margin-top: -3px;
        border-top: 9px dashed;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
    }
    .dateBold{
        display: inline-block;
        border: 2px inset #e5e5e5;
        border-radius: 0;
        background-color: #FFF;
        width: 45%;
        height: 33px;
        font: 20px/1.4 fahrenheit,Arial,Helvetica,sans-serif;
        color: #575757;
        cursor: pointer;
        outline: 0;
    }
    .SumoSelect:focus{
        border-color: #66afe9;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
    }
    .SumoSelect.open > .optWrapper {
        display: block;
    }
    .SumoSelect.open .search-txt{
        height: 100%;
        padding: 0px 8px;
    }
    .search-form-holder{
        width: 186px;
        padding-top: 35px;
        direction: ltr;
        unicode-bidi: embed;
    }
}

.he {

    .report-page {

        .clearfix {

            > .pull-right {
                width: auto !important;
            }
        }

        .search-form-holder {
            width: 240px;
        }
    }
}

.recent-reports.no-table-buttons .dataTables_wrapper{
    top:0;
}
.SumoSelect select{
    display: none;
}
.no-decoration{
    text-decoration: none !important;
}
.select-pull-right .ui-select-match-text.pull-left{
    float: right!important;
}
.he .select-pull-right .ui-select-choices-row-inner{
    text-align: right;
    direction: rtl;
}
.optWrapper .options li.opt{
    padding: 5px!important;
}
.dropdown-selected-items{
    position: relative;
    top:6px;
    word-wrap: break-word;
}
.button-holder{
    padding-top: 30px;
}
.mb70{
    margin-bottom: 70px;
}
.reports-table {


    table tr.tbl_summary_group_title td{
        font-weight: bold;
        background-color: #e1e1e1 !important;

    }

     tr.odd {

         &>td{
             padding: 0;
         }

         table tr td {
            background-color: #F6F6F6;
        }
    }

     tr.even {

         &>td{
             padding: 0;
         }

         table tr td {
            background-color: #FFF;
        }
    }
}
.no-buttons .dt-buttons{
    display:none;
}
.taxes_box:before{
    content: " ";
    display: table;
}
.taxes_box:after{
    content: '';
    display: table;
    clear: both;
}
.taxes_box{
    background: #ffffff;
    padding: 35px 0 16px 0;
}
.std_block_4_content:before{
    content: " ";
    display: table;
}
.std_block_4_content:after{
    content: '';
    display: table;
    clear: both;
}
.pv10{
    padding-top: 10px;
    padding-bottom: 10px;
}
.std_block_4.light{
    border:1px solid #f9f9f9;
    background: #fcfcfc;
}
.mb70{
    margin-bottom:70px
}
.he .pagination-holder-absolute{
    left: 38px;
}
.en .pagination-holder-absolute{
}
.pagination-holder-absolute{
    position: absolute;
    direction: ltr;
    width: 100%;
    white-space: nowrap;
}
.en .minusButton:focus:after{
    border-right-color: black;
}
.en .plusButton:focus:after{
    border-left-color: black;
}
.he .minusButton:focus:after{
    border-left-color: black;
}
.he .plusButton:focus:after{
    border-right-color: black;
}

.pagination-holder{
    unicode-bidi: bidi-override;
    padding-top: 7px;

    input{
        width: 35px;
        border:1px solid #ccc;
        text-align: center;
        font-weight: bold;
        display: inline-block;
        vertical-align: top;
        margin-top: 2px;
    }
    .plusButton{
        height: 26px;
        display: inline-block;
        vertical-align: top;
        width: 13px;
        cursor: pointer;
        //top: 9px;
        position: relative;
        background: none;
        border: none;
    }
    .plusButton.disabled{
        cursor: not-allowed;
    }
    .plusButton:after {
        left: 2px;
        top: 13px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-left-color: #ccc;
        border-width:10px;
        margin-top: -10px;
    }
    .minusButton.disabled{
        cursor: not-allowed;
    }

    .minusButton:focus,
    .plusButton:focus{

        outline: 0;
    }

    .minusButton{
        height: 26px;
        display: inline-block;
        vertical-align: top;
        width: 13px;
        //top: 9px;
        position: relative;
        cursor: pointer;
        background: none;
        border: none;
    }
    .minusButton:after {

        right: 2px;
        left: auto;
        top: 13px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: #ccc;
        border-width: 10px;
        margin-top: -10px;
    }

}
.he .paginationInput{
    direction: rtl;
    margin-left: 5px;
    unicode-bidi: embed;
}
.en .paginationInput{
    direction: ltr;
    margin-left: 5px;
    unicode-bidi: embed;
}
.he .minusButton:after{
    left: 2px;
    right: auto;
    border-left-color: #ccc;
    border-right-color: transparent;
}
.he .plusButton:after{
    right: 2px;
    left: auto;
    border-right-color: #ccc;
    border-left-color: transparent;
}
.the_table_type_6_controls .the_button_2.print-grey:before{
    background-image: url(../../img/sprite_iconset_2.png);
}
.no-border{
    border:none!important;

}
.the_table_type_6{
    width: 710px!important;
}
.the_table_type_6_box{
    width: 730px!important;
    overflow-y: auto;
    height: auto;
    max-height: 339px;
}
.the_table_type_6_box td,
.the_table_type_6_box td>span
{
    font-size: 12px;
}
.the_table_type_6_box .amountColumn{
    word-wrap: break-word;
}

.the_table_type_6_box .docNumberColumn,
    .the_table_type_6_box .amountColumn,
.the_table_type_6_box .dateColumn{
    width: 55px;
    max-width: 55px;

}



.the_table_type_6_box .titleColumn{
    max-width: 186px;
    width: 186px;
}
.aff_bnnr_text{
    display: none;
    position: absolute;
    left: 115px;
    top: 81px;
}
.super-partners{
    display: none;
    position: absolute;
    left: 145px;
    top: 35px;
    width: 95px;
}
.bg-info.white{
    position: relative;
    background: none;
    border: 1px solid #ccc;
    border-radius: 9px;
}
.he .bg-info.white .aff_bnnr_text,
.he .bg-info.white .super-partners{
    display: block;
}

.report-page-title {

    span.smb_man {
        padding: 0 5px 0 0;
        text-align: center;
    }

}

.bg-info.white span.smb_man{
    padding: 10px;
    background: #f6f6f6;
    border-radius: 0 9px 9px 0;
}
.bg-info.white span.smb_man #businessLogoImg{
    position: relative;
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}
.bg-info.white .big_help_popup{
    left: -5px;
}
.bg-info.p15 .big_help_popup{
    left: -5px;
    top:69px;
}
.bg-info.white .page-title-holder{
    margin-top: 43px;
}
.bg-info.white .smb_man .smb_man_txt{
    top:54px;
    left:107px;
}
.table_controls_type_2 .the_button_2:before{
    background-image: url(../../img/sprite_iconset_2.png);
}
table.the_table_type_1.small_tbl.table thead .sorting,
table.the_table_type_1.small_tbl.table thead .sorting_asc,
table.the_table_type_1.small_tbl.table thead .sorting_desc{
    background: #0d83dd!important;
}
.no-buttons.tableToTop .dataTables_wrapper{
    top:-25px;
}
.commissions-graph .graph_widget_tabs a span{
    font-size: 29px;
}
.commissions-graph .graph_widget_tabs .active{
    font-weight: 400;
}
.commissions-graph .graph_widget_tabs a strong{
    font-size: 34px;
}
tspan{
    font-weight: 900;
}
table.commissions-table tfoot th {
    position: relative;
    line-height: 1;
    padding: 14px 8px !important;
}



tr:hover td.referralName .table_controls_type_2.row-buttons{
    display: table-cell;
}
td.referralName {

    .inner-wrapper{
        display: table;
        min-height: 35px;
        width:100%;
    }

    .name-wrapper{
        vertical-align: middle;
        display: table-cell;
        width: 100%;
    }

    .table_controls_type_2.row-buttons {
        border: none;
        background: none;
        padding: 0;
        vertical-align: top;
        display: none;
        width: 75px;
    }
    .table_controls_type_2.row-buttons:after {
        content: '';
        display: none;
    }
    .table_controls_type_2.row-buttons button {
        padding: 0;
        margin-right: 3px !important;
    }
    .table_controls_type_2.row-buttons button:before {
        margin: 0 4px 0 4px !important;
    }
    .table_controls_type_2.row-buttons button.the_button_2.print-grey:before {
        height: 20px;
    }

    text-align: right !important;
}
.active-page{
    position: relative;
}
.active-page:before{
    content: '';
    position: absolute;
    width:100%;
    height:1px;
    display: block;
    top:-45px;
    background-color: #ccc;
}
.he .active-page:after{
    left: 64.5%;
}
.active-page:after{
    content: '';
    position: absolute;
    top: -57px;
    background: url(../../img/img_001.png);
    height: 37px;
    width: 12px;
    left: 50%;
    margin-left: -6px;
    z-index: 10;
    transform: rotate(-90deg);
}
.the_table_type_6 table thead tr th:nth-child(5){
    border-right: 2px solid #ccc;
}
.the_table_type_6 table tbody  table tr td:nth-child(5){
    border-right: 2px solid #ccc;
}
.the_table_type_6 table tbody  table tr:first-child td{
    border-top: 1px solid transparent;
}
.dateRangeReportHolder{
    unicode-bidi: embed;
}
.en .dateRangeReportHolder{
    direction: ltr;
}
.he .dateRangeReportHolder{
    direction: rtl;
}


.calculation {
    display:block;
    text-align:center;
    font-size:0px;
}

.calculation li {
    display:inline-block;
    margin:0 0 0 43px;
    height:83px;
    background:#F6F6F6;
    width:174px;
    font-size:21px;
    line-height:30px;
    position:relative;
    direction: ltr;
}

.calculation li h4 {
    display:block;
    background:#DADADA;
    padding:9px 0 0 0;
    font-weight:normal;
    height:33px;
    margin:0 0 10px 0;
}

.calculation .minus:before {
    content:'';
    display:block;
    position:absolute;
    top:50%;
    right:-37px;
    width:30px;
    height:6px;
    margin:-3px 0 0 0;
    background:#CCC;
}

.calculation .equals:before {
    content:'';
    display:block;
    position:absolute;
    top:50%;
    right:-37px;
    width:30px;
    height:18px;
    margin:-9px 0 0 0;
    border-top:6px solid #CCC;
    border-bottom:6px solid #CCC;
}

.calculation .equals h4 {
    background:#0D83DD;
    color:#FFF;
}
.orders_slider_box .slick-prev,
.orders_slider_box .slick-next{
    display: block;
    height: 124px;
    width: 22px;
    position: absolute;
    top: 70px;
    margin: 0 13px 0 13px;
    font-size: 0px;
    text-indent: -9999px;
}
[dir="rtl"] .orders_slider_box .slick-prev{
    right:-55px;
}
[dir="rtl"] .orders_slider_box .slick-next{
    left:-55px;
}
.orders_slider_box .slick-prev:before,
.orders_slider_box .slick-next:before{
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 62px solid transparent;
    position: absolute;
    top: 0;
}
.orders_slider_box .slick-prev:before{
    border-left: 22px solid #DDEDFD;
    left: 0;
}
.orders_slider_box .slick-next:before{
    border-right: 22px solid #DDEDFD;
    right: 0;
}
.order_slider_item_content{
    width: 100%;
    overflow-y: scroll;
}
.orders_slider_box .order_slider_item_header span{
    font-size: 8px;
}
.orders_slider_box .order_slider_item_header h4{
    font-size: 8px;
}
.orders_slider_box .order_slider_item_content li{
    font-size: 12px;
    min-height: 18px;
}
.orders_slider_box .order_slider_item_footer h4{
    font-size: 10px;
}
.orders-template-changer{
    padding-top: 30px;
    padding-left: 30px;
}
.order_slider_item_footer h4{
    font-size: 16px;
}
.order_slider_item.big .order_slider_item_footer h4{
    font-size: 22px;
}
.big_orders_box .order_slider_item.big .order_slider_item_footer {
    padding-top: 9px;
}


.he .the_table_type_5.custom_1 .detailsRow td {
    direction: rtl;
}
.the_table_type_5.custom_1 .detailsRow td{
    text-align: right;
}
.order_slider_item_content{
    padding-top: 3px;
}

.big_orders_container {
    border-radius:2px;
    border:1px solid #CCC;
    padding:15px 0 0px 0;
    background:#F6F6F6;
    margin:0 0 41px 0;
}

.big_orders_box {
    text-align:center;
    font-size:0;
    direction:ltr;
}


.big_orders_box .order_slider_item {
    display:inline-block;
    vertical-align:middle;
    margin:0 10px 0 10px;
    text-align:right;
    width:176px;
    min-height:176px;
    border-color:#DADADA;
}

.big_orders_box .order_slider_item_header {
    font-size:11px;
    background:#EDEDED;
    height:29px;
    color:#C6C6C6;
    padding-top:11px;
}

.big_orders_box .order_slider_item_header > .wrapper > h4,
.big_orders_box .order_slider_item_header > .wrapper > span{
    font-size:12px;
    line-height: 1.4;
}

.big_orders_box .order_slider_item_content {
    font-size:10px;
    line-height:12px;
    font-weight:bold;
    color:#DADADA;
    height:120px;
}

.big_orders_box .order_slider_item_content li {
    border-color:#DADADA;
    min-height: 23px;
}
.big_orders_box .big .order_slider_item_content li {
    border-color:#DADADA;
    height: 20%;
}

.big_orders_box .order_slider_item_footer {
    font-size:16px;
    padding-top:7px;
    color:#575757;
}


.big_orders_box .order_slider_item.big {
    width:275px;
    min-height:275px;
    border-color:#DADADA;
}

.big_orders_box .big .order_slider_item_header {
    font-size:16px;
    line-height:22px;
    background:#0D83DD;
    height:46px;
    color:#FFFFFF;
    padding:14px 12px 0 12px;

}

.big_orders_box .big .order_slider_item_header > .wrapper {
    > h4 {
        font-size: 18px;
        line-height: 1;
    }

    > span {
        font-size: 18px;
        line-height: 1;
        word-wrap: break-word;
    }
}
.big_orders_box .big .order_slider_item_content {
    font-size:15px;
    line-height:18px;
    font-weight:normal;
    color:#575757;
    height:188px;
    padding:10px 19px 10px 19px;
}

.big_orders_box .big .order_slider_item_content li {
    border-color:#DADADA;
    padding:10px 12px 10px 12px;
}

.big_orders_box .big .order_slider_item_footer {
    font-size:25px;
    line-height:30px;
    padding:9px 12px 0 12px;
    color:#575757;
    height:39px;
}


.orders_slider_container .the_table_type_6_controls {
    clear:both;
    padding:0 0 22px 57px;
}

.orders_slider_box {
    clear:both;
    position:relative;
    padding:0 50px 0 50px;
    direction:ltr;
    margin:0 0 16px 0;
}

.orders_slider_box li {
    direction:rtl;
    text-align:right;
    padding:0 0 10px 0;
}

.order_slider_item {
    border-radius:5px;
    border:1px solid #CCC;
    width:136px;
    min-height:136px;
    font-size:7.45px;
    line-height:9px;
    position:relative;
    direction:rtl;
    margin:auto;
}

.order_slider_item:hover,
.order_slider_item:focus {
    border-color:#0d83dd;
}

.orders_slider_box .order_slider_item {
    cursor:pointer;
}

.order_slider_item .std_block_shadow {
    height:4px;
    left:7px;
    right:7px;
    margin:1px 0 0 0;
}

.order_slider_item_header {
    display:block;
    background:#0d83dd;
    color:#FFF;
    padding:7px 5px 0 5px;
    height:22px;
    border-radius:4px 4px 0 0;
}

.order_slider_item_header > .wrapper > h4 {
    float:right;
}

.order_slider_item_header > .wrapper > span {
    float:right;
    margin:0 7px 0 0;
    position:relative;
    word-wrap: break-word;
}

.order_slider_item_header > .wrapper > span:before {
    content:'|';
    display:block;
    position:absolute;
    right:-4px;
    top:0;
}

.order_slider_item_footer {
    background:#f6f6f6;
    height:24px;
    padding:6px 5px 0 5px;
    font-size:12.46px;
    border-radius:0 0 4px 4px;
}

.order_slider_item_footer h4:after {
    content:'|';
}

.order_slider_item_content {
    padding:7px 8px 6px 8px;
    display:block;
    height:93px;
    background:#FFF;
}

.order_slider_item_content li {
    display:block;
    border-bottom:1px solid #CCC;
    padding:4px 6px 4px 6px;
}

.order_slider_item_content li:last-child {
    border:none;
    margin:0;
}

.orders_slider_box .bx-prev, .orders_slider_box .bx-next {
    display:block;
    height:124px;
    width:22px;
    position:absolute;
    top:8px;
    margin:0 13px 0 13px;
    font-size:0px;
    text-indent:-9999px;
}

.orders_slider_box .bx-prev:before, .orders_slider_box .bx-next:before {
    content:'';
    display:block;
    width:0;
    height:0;
    border:62px solid transparent;
    position:absolute;
    top:0;
}

.orders_slider_box .bx-prev:before {
    border-right:22px solid #DDEDFD;
    right:0;
}
.orders_slider_box .bx-next:before {
    border-left:22px solid #DDEDFD;
    left:0;
}

.orders_slider_box .bx-prev {
    left:0;
}

.orders_slider_box .bx-next {
    right:0;
}


.orders_count {
    font-size:22px;
    font-weight:bold;
    text-align:center;
    display:block;
    padding:8px 0 0 0;
}

.big_orders_box .big .order_slider_item_content
{
    overflow-y: scroll;
    padding-top: 3px;
}

.big_orders_box .order_slider_item_header,
.big_orders_box .big .order_slider_item_header,
.orders_slider_box .order_slider_item_header
{
    padding-top: 0;
    padding-bottom: 0;
    display: table;
        width: 100%;

    .wrapper{
        width: 100%;
        display: table-cell;
        float: none;
        vertical-align: middle;
    }
}
.main-reports-page .dt-collection-button.export{
    background: #B2B2B2 url(../../img/sprite_tbl_5_controls.png);
    background-position: 1px -100px;
    border: none;
}
.report-page .dataTable {

    tbody td.details-column {
        text-align: right;
        word-wrap: break-word;

        *{
            display: block;
            float: right;
            direction: rtl;
            width: 100%;
            margin-left: 4px;
        }

        .title-text {
            font-weight: bold;
        }
    }

    tbody .detailsRow td{

        > span {
            direction: rtl;
            display: block;

            > *{
                direction: rtl;
                display: inline-block;
            }
        }
    }
}



.the_table_type_2 {
    width:100%;
    color:#B2B2B2;
    clear:both;

}

.oldie .the_table_type_2 {
    float:right;
    width:100%;
    clear:both;
}

.the_table_type_2 tbody tr {
    border:1px solid #CCC;
    padding:0 0 15px 0;
}

.the_table_type_2_thead,
.the_table_type_2 thead th {
    height:47px;
    vertical-align:middle;
    color:#575756;
    text-align:center;
    margin:10px;
    font-size: 22px;
    padding: 10px 0;
}

.the_table_type_2 tbody tr td {
    background-color:#fff;
    text-align:center;
    height:36px;
    font-size: 22px;
    vertical-align:middle;
    position: static;
}

.the_table_type_2 tbody tr:nth-of-type(odd) td {
    background-color:#F6F6F6;
}

.the_table_type_2 tr:nth-of-type(even) {
    border:none;
}

.the_table_type_2 tr:nth-of-type(even) td {
    background-color:none;
    height:15px;
}



.table_type_5_title {
    background:#F6F6F6;text-align:center;
    padding:19px 0 8px 0;
}

.table_type_5_title:after {
    content:'';
    display:table;
    clear:both;
}

.table_type_5_title h3 {
    font-weight:normal;
    font-size:22px;
    color:#B2B2B2;
    text-align:left;
    float:right;
    padding:0 0 0 170px;
    margin:0 0 0 -170px;
    width:100%;
}

.table_type_5_title h3 a {
    color:#B2B2B2;
    text-decoration:underline;
}

.table_5_pager {
    float:left;
    width:170px;
    padding:0 0 0 16px;
}

.table_5_pager a {
    float:left;
    margin:0 3px 0 3px;
    color:#0D83DD;
}

.table_5_pager .active {
    font-size:24px;
    font-weight:bold;
}

.table_5_pager .to_begining {
    width:11px;
    height:14px;
    margin:8px 6px 0 0;
    background:url(../../img/sprite_pager_arrows_1.png) 0 -50px;
}

.table_5_pager .prev {
    width:6px;
    height:14px;
    margin:8px 6px 0 0;
    background:url(../../img/sprite_pager_arrows_1.png) 0 0;
}

.table_5_pager .next {
    width:6px;
    height:14px;
    margin:8px 0 0 6px;
    background:url(../../img/sprite_pager_arrows_1.png) -50px 0;
}

.table_5_pager .to_end {
    width:11px;
    height:14px;
    margin:8px 0 0 6px;
    background:url(../../img/sprite_pager_arrows_1.png) -50px -50px;
}


.oldie .the_table_type_5 {
    float:right;
    width:100%;
    clear:both;
}



.the_table_type_5.custom_1 tbody > tr:nth-of-type(even) > td {
    background-color:#F6F6F6;
    border: none;
    table tbody tr {

        td {
            background-color: transparent;
            border: none;
        }
    }

    span.title{
        font-weight:normal;
        color: #B2B2B2;
    }
}

//.the_table_type_5.custom_1 tbody {
//
//    > tr {
//
//        &:hover {
//
//            td {
//                background-color: #F6F6F6 !important;
//            }
//        }
//    }
//}

.the_table_type_5.custom_1 tbody > tr:nth-of-type(odd)  td {
    background-color: transparent;
    border: none;
    table tbody tr {

        td {
            background-color: transparent;
            border: none;
        }
    }

    span.title{
        font-weight:bold;
        color: #575757;
    }
}

.the_table_type_5.custom_1 tbody tr:hover td {
    border-color:transparent !important;
    background-color: #e7f4fe !important;
}

table .the_table_type_5{

}
.income-items-page{
    .reports-table{
        width: 100%;
        thead th{
            width: 25%;
        }
    }


    .reports-table.income-by-items-inner-table td{
        width: 25%;
    }
}
.orders_slider_box .slick-slide{
    float: right;
}

.incomes_total {
    float:left;
    font-size:22px;
    line-height:30px;
    font-weight:bold;
    margin:0 0 41px 0;
    overflow:auto;
    display: table;
}

.income_total_title{
    text-align: right;
    font-size: 22px;
    font-weight: bold;
}

.income_total_row{
    display: table-row;
}
.income_total_cell{
    display: table-cell;
    vertical-align: top;
    padding-top: 10px;
}
.income_total_cell.income_label{
}
.income_total_cell.income_input{
}

.incomes_total label {
    padding:0 0 0 15px;
    direction: ltr;
}

.incomes_total {

    .income_total_cell > span{
        display: block;
        font-size: 19px;
        width: 100%;
        min-width: 149px;
    }

    .income_total_cell > span,
    input {
        direction: ltr;
        height:30px;
        border:1px solid #CCCCCC;
        font-size:19px;
        font-weight:bold;
        text-align:center;
        color:#9D9D9C;
    }

}
.cheque-status-change-block{
}


.the_table_type_5 {
    width:100%;
    border-collapse:separate;
}
.the_table_type_5 thead th:focus,
.the_table_type_5 thead th:hover{
    background: #6eb5eb;
}
.the_table_type_5 thead th {
    border-right:1px solid #FFF;
    height:56px;
    background:#0D83DD;
    text-align:center;
    vertical-align:middle;
    color:#FFF;
    font-size:26px;
}
.the_table_type_5 thead th.sorting {
    cursor: pointer;
}


.the_table_type_5 tbody td.details-column span{
    font-size:22px;
}


.the_table_type_5 tbody td {
    padding:13px;
    font-size:21px;
    color:#B2B2B2;
    line-height:30px;
    text-align:center;
    border-top:1px solid transparent;
    border-bottom:1px solid transparent;
}



.en .the_table_type_5 tbody tr td:first-child {
    border-left:1px solid transparent
}

.en .the_table_type_5 tbody tr td:last-child {
    border-right:1px solid transparent;
}
.he .the_table_type_5 tbody tr td:first-child {
    border-right:1px solid transparent
}

.he .the_table_type_5 tbody tr td:last-child {
    border-left:1px solid transparent;
}

.en,
.he {

    .main-reports-page {

        .the_table_type_5 tbody tr:hover td,
        .the_table_type_5 tbody tr:hover td{
            border-color:#CCC;
            background-color: transparent !important;
        }
    }
}

.the_table_type_5 tbody td[colspan] {
    text-align:right;
}


.the_table_type_5 b {
    font-weight:bold;
    font-size:23px;
    color:#575757;
}

.tbl_type_5_controls button:focus {
    border: rgb(87, 87, 87);
}

.tbl_type_5_controls button {
    outline: none;
}

.tbl_type_5_controls button:focus,
.tbl_type_5_controls .dt-collection-button:focus
{
    border: 1px solid rgb(87, 87, 87);
    border-radius: 3px;
}
.tbl_type_5_controls .dt-collection-button-wrapper{
    display: inline-block;
}
.tbl_type_5_controls button {
    margin: 0 5px 0 0;
    border-radius: 3px;
    border-width: 0;
    background: #B2B2B2 url(../../img/sprite_tbl_5_controls.png);
    width: 34px;
    height: 34px;
    display: inline;
    vertical-align: middle;
}

.customers-book {
    .title_client_details {
        font-size: 24px;
        margin: 0 27px 0 0;
        float: right;
        position: relative;
    }

    .std_block_2_header h2 {
        word-wrap: break-word;
    }
    .std_block_2_header h2 + .title_client_details:before {
        content:'|';
        display:block;
        position:absolute;
        top:0;
        right:-18px;
        font-size:30px;
        font-weight:bold;
        color:#333;
    }

    .title_client_details li {
        float: right;
        margin: 0 0 0 18px;
        position: relative;
        font-size: 22px;
        word-wrap: break-word;

    }
    .title_client_details li span {
        font-size: 22px;
    }
    .title_client_details li:after {
        content: '|';
        position: absolute;
        top: 0;
        left: -10px;
    }


    .title_client_details li:last-child:after {
        display: none;
    }

    .title_client_details .adress, .title_client_details .phone {
        display: inline-block;
        padding: 0 23px 0 0;
        position: relative;
    }

    .title_client_details .adress:before, .title_client_details .phone:before {
        content: '';
        display: block;
        position: absolute;
        top: 11px;
        right: 0;
        width: 19px;
        height: 17px;
        background-image: url(../../img/sprite_title_client_details.png);
    }

    .title_client_details .adress:before {
        background-position: 0 0;
    }

    .title_client_details .phone:before {
        background-position: 0 -50px;
        height: 20px;
        top: 9px;
    }
}
.orders-book-table{
    >thead>tr>td{
        font-size: 22px;
    }
}
.income-for-accounting-report-page{
    .incomes_total{
        width: 50%;
        padding: 5px;

        .income_total_cell{
            text-align: right;
            font-size: 18px;
            input{
                font-size: 18px;
            }
        }
  }
.detailsRow {

    .title {
        font-size: 22px;
    }
}
}
.orders-book-page {
  .detailsRow {
    .paragraph-row {
      width: 100%;
    }
  }
}
.taxes_box label {
    float:right;
    text-align:left;
    width:50%;
    padding:0 0 0 15px;
    height:44px;
    margin:0 0 15px 0;
    line-height:45px;
}

.taxes_box .row {
    float:right;
    direction: ltr;
    height:44px;
    margin:0 0 15px 0;
    font-size:30px;
    font-weight:bold;
    color:#B2B2B2;
    text-align:center;
    border:1px solid #DADADA;
    background:#F6F6F6;
    width:215px;
}
.report-page{
    .negative-amount{
        color: red; direction: ltr; unicode-bidi: embed
    }
}