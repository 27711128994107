
.your_link_box {
    padding:27px 143px 28px 45px;
    font-size:32px;
    line-height:36px;
    min-height:129px;
    position:relative;
}

.your_link_box:before {
    content:'';
    display:block;
    position:absolute;
    top:11px;
    right:17px;
    width:105px;
    height:105px;
    background:url(../../img/ico_your_link.png);
    border-radius:105px;
}

.your_link_box:after {
    content:'';
    display:table;
    clear:both;
}

.your_link {
    clear:both;
    margin:0 0 9px 0;
    line-height: normal;
    word-wrap: break-word;
    font-size: 22px;

    span {
        padding: 0 5px 0 0;
        display: block;
        overflow: hidden;
    }

    h4 {
        float: right;
    }
}

.your_link h4 {
    display:inline;
    color:#000;
    font-size: 22px;
}

.your_link a {
    font-size: 22px;
    color:#000;
    display:inline-block;
    transform:scaleX(0.88);
    -moz-transform:scaleX(0.88);
    -webkit-transform:scaleX(0.88);

    transform-origin:right;
    -moz-transform-origin:right;
    -webkit-transform-origin:right;
}


.your_link_share_box {
    float:left;
    clear:both;

    .share_menu_inner{
        .share_menu_element{
            float: left;
            width: 40px;
            text-align: center;
        }
    }

    .yl_copy:before,
    .yl_share:before
    {
        content:'';
        display:block;
        width:31px;
        height:30px;
        position:absolute;
        top:-1px;
        right:0;
        background:url(../../img/sprite_my_link_share.png) 0 0 no-repeat;
    }

    .yl_copy:before {
        background-position:0 0;
    }

    .yl_share:before {
        background-position:0 -50px;
    }


    .yl_copy:before {
        background-position: 0 0;
    }

    a:hover {
        opacity:0.6;
    }

    .yl_copy,
    .yl_share{

        cursor: pointer;
        display:inline-block;
        float:right;
        font-size:25px;
        line-height:30px;
        position:relative;
        margin:0 22px 0 0;
        padding:0 41px 0 0;

        &:focus {
            text-decoration: none;
        }
    }
}
