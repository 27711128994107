//
// Buttons
// --------------------------------------------------


// Base styles
// --------------------------------------------------

.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@padding-base-vertical - 1; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
  .user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      .tab-focus();
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: @btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: @cursor-disabled;
    .opacity(.65);
    .box-shadow(none);
  }

  a& {
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}


// Alternate buttons
// --------------------------------------------------

.btn-default {
  .button-variant(@btn-default-color; @btn-default-bg; darken(@btn-default-border, 20%));
}
.btn-primary {
  .button-variant(@btn-primary-color; @btn-primary-bg; darken(@btn-primary-border, 20%));
}
// Success appears as green
.btn-success {
  .button-variant(@btn-success-color; @btn-success-bg; darken(@btn-success-border, 20%));
}
// Info appears as blue-green
.btn-info {
  .button-variant(@btn-info-color; @btn-info-bg; darken(@btn-info-border, 20%));
}
// Warning appears as orange
.btn-warning {
  .button-variant(@btn-warning-color; @btn-warning-bg; darken(@btn-warning-border, 20%));
}
// Danger and error appear as red
.btn-danger {
  .button-variant(@btn-danger-color; @btn-danger-bg; darken(@btn-danger-border, 20%));
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @link-color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size((@padding-large-vertical - 1); (@padding-large-horizontal - 1); (@font-size-large + 4); (@font-size-large + 4); @btn-border-radius-base);
  border: 2px solid #659C17;
  i {
    font-size: @font-size-large - 6;
    position: relative;
    top: -3px;
    margin-left: 10px;
    .opacity(.7);
  }
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size((@padding-small-vertical - 3); @padding-small-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
  i {
    margin-left: 10px;
  }
}
.btn-xs {
  padding: 2px 7px 1px;
  font-size: (@font-size-small - 2);
  line-height: 27px;
  border-radius: @btn-border-radius-small;
  // .button-size(@padding-xs-vertical; @padding-xs-horizontal; (@font-size-small - 2); @line-height-small; @btn-border-radius-small); // why??? why using mixin for a button...
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}

// plus button
.btn-plus {
    padding: 1px 15px 0;
    border-color: @green-border;
    line-height: 21px;

    i {
      opacity: 0.6;
    }
}

.smb_links_help {
    display:block;
    width:28px;
    height:28px;
    border-radius:3px;
    background:#0D83DD url(../../img/ico_help.png) center no-repeat;
    text-indent:-9999px;
    margin:1px 0 0 0;
    cursor:pointer;
}


.modal-header .btn.ui-select-toggle{
    padding: 4px 12px;
    height: 42px;
}
.button_add.btn-xs{
    padding: 7px 25px 7px 7px;
}

.the_button,
.the_button_2{
    cursor: pointer;
}

.the_button_2,
.the_button_2.disabled,
.the_button_2[disabled]
{
    display:inline-block;
    border:2px solid #666666;
    border-radius:3px;
    background-color:#888888;
    color:#FFF;
    padding:0 8px 0 8px;
    font-weight:bold;
    text-align:center;
}

.btn.the_button_2{
    font-size: 16px;
    > span{
        font-size: 16px;
    }
}

.the_button_2:hover,
.the_button_2.disabled:hover,
.the_button_2[disabled]:hover {
    background-color:#666666;
    text-decoration:none;
    border:2px solid #666666;
}

/* Buttons */
.the_button_2.delete:before {display: none;}
.the_button_2.edit:before {display: none;}
.the_button_2.delete:after {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url(../../img/sprite_iconset_1.png) 0 -50px;
    position: relative;
    margin: -7px 0 -3px 5px;
    top: -2px;
}
.the_button_2.edit:after {
    content: '';
    display: inline-block;
    width: 18px;
    height: 30px;
    background: url(../../img/sprite_iconset_1.png) 0 0;
    position: relative;
    margin: -7px 0 -3px 5px;
    top: 5px;
}
body > .dt-button-collection.top{
    margin-top: -72px;
    width: 1080px;
    text-align: right;
    direction: rtl;

}
.dt-buttons > .dt-button-collection.top{
    position: absolute;
    margin-top: -72px;
    width: 1080px;
    direction: rtl;
    margin-left: -16px;
    text-align: right;
    top: 39px!important;
    left: 0px!important;
}


.print {
    display: inline-block;
    width: 25px;
    height: 20px;
    background-image: url(../../img/sprites.png);
    background-repeat: no-repeat;
    background-position: -2px -68px;
    vertical-align: middle;
}

.clone {
    display: inline-block;
    width: 26px;
    height: 20px;
    background-image: url(../../img/sprites.png);
    background-repeat: no-repeat;
    background-position: -2px -95px;
    vertical-align: middle;
    margin-left: 3px;
}

.cog {
    display: inline-block;
    width: 26px;
    height: 28px;
    background-image: url(../../img/sprites.png);
    background-repeat: no-repeat;
    background-position: -2px -324px;
    vertical-align: middle;
}

.mobile {
    display: inline-block;
    width: 27px;
    height: 30px;
    background-image: url(../../img/sprites.png);
    background-repeat: no-repeat;
    background-position: -1px -223px;
    vertical-align: top;
}


.newspaper-o {
    display: inline-block;
    width: 22px;
    height: 30px;
    background-image: url(../../img/sprites.png);
    background-repeat: no-repeat;
    background-position: -4px -259px;
    vertical-align: middle;
}

.new-document-o {
    display: inline-block;
    width: 22px;
    height: 30px;
    background: url(../../img/sprites.png) no-repeat;
    background-position: -3px -411px;
    vertical-align: middle;
}

.cloud-download {
    display: inline-block;
    width: 18px;
    height: 30px;
    background-image: url(../../img/sprites.png);
    background-repeat: no-repeat;
    background-position: -7px -163px;
    vertical-align: middle;
}

.cloud-upload {
    display: inline-block;
    width: 18px;
    height: 30px;
    background-image: url(../../img/sprites.png);
    background-repeat: no-repeat;
    background-position: -7px -291px;
    vertical-align: middle;
}

.envelope {
    display: inline-block;
    width: 30px;
    height: 25px;
    background-image: url(../../img/sprites.png);
    background-repeat: no-repeat;
    background-position: 0px -196px;
    vertical-align: middle;
}