// For Debug
.en .subcription_status_line{
    direction: ltr;
}
.he .subcription_status_line{
    direction: rtl;
}

.subcription_status_line {
  padding:27px 31px 32px 10px;
  font-size:25.67px;
  line-height:36px;
}

.subcription_status_line:after {
  content:'';
  display:table;
  clear:both;
}

.subcription_status_line h4 {
  float:right;
  margin:0 0 0 16px;
  font-weight:normal;
}

.subscr_status_box {
  display:block;
  height:36px;
  width:45%;
  background:#ddedfd;
  float:right;
  margin:0 0 0 17px;
}

.subscr_status_box > div {
  height:36px;
  line-height:28px;
  font-size:28px;
  font-weight:bold;
  color:#FFF;
  background:#0d83dd;
  text-align:center;
  display:table;
  padding:6px 2px 0 4px;
}

.subcription_status_line a {
  font-size:21.57px;
  color:#575757;
  text-decoration:underline;
}

.subcription_status_line a:hover {
  text-decoration:none;
  color:#0D83DD;
}

.subscr_status_counter {

  // FML
  strong, span {
    font-size: 21.57px;
    float: right;
    margin-left: 8px;
      white-space: nowrap;
  }

}