.input-label-center{
	position: relative;
    top: 8px;
}
.withholding-table{
    margin-right: 35px;
}
.withholding-table .total-table{
	margin-top: 20px;

}
.payment-table table.payment-credit-card-table td.expiry .transparent-input{
	width: 33px;
}
.payment-table table.payment-credit-card-table th.voucher {
    width: 130px;
}
.payment-table table.payment-credit-card-table th.amount{
	width: 140px;
}
.payment-table table.payment-credit-card-table th.expiry {
    width: 137px;
}
.withholding-input{
	width: 150px;
}
.payment-table{
	position: relative;
    table-layout: fixed;
    width: 100%;
}
.payment-table-label{
	position: absolute;
	top: 13px;
}
.coins{
	background-color: #f6f6f6;
    background-image: url(../../img/coins.png);
    background-position: center center;
    background-repeat: no-repeat;
    height: 44px;
    border: 2px solid #2f95e2;
    border-radius: 50%;
    width: 44px;
    padding: 3px;
    display: inline-block;
    background-size: 70%;
    vertical-align: middle;
	margin: 5px;
}
.payment-summary-head{
	color:#2f95e2;
	font-weight: bold;
}
.payment-table {

    input,
    select {
        font-size: 14px;
        min-height: 34px;
    }

    .ui-select-choices-group {
        font-size: inherit;
    }
}

.dropdown-menu {
    font-size: 16px;
}

.payment-table th{
    font-size: 18px;
    font-weight: bold;
}
.payment-table .nav>li>a{
        padding: 8px 15px;
}
.payment-table .btn-success.btn-plus{
    padding: 1px 27px 0;
}

.transparent-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size: 14px;
}
.transparent-input::-moz-placeholder { /* Firefox 19+ */
    font-size: 14px;
}
.transparent-input:-ms-input-placeholder { /* IE 10+ */
    font-size: 14px;
}
.transparent-input:-moz-placeholder { /* Firefox 18- */
    font-size: 14px;
}
.branch-input{
    width: 100%;
    min-width: 50px!important;
    max-width: 50px!important;
}
th.branchColumn{
    word-wrap: break-word;
}
.dateInput{
    min-width: 76px;
    max-width: 76px;
    width: 100%;
}
.bank_column.cheque_bank{
    width: 120px;
}
.payment-table .money-worth-textarea{
    max-width: 100%!important;
    width: 100% !important;
    height: 27px;
    resize: none!important;
}