
/*#dtCurrenciesTable {
    margin-top: 30px;
}*/

/*.dataTables_wrapper .row {
    display: none;
    visibility: hidden;
}*/

/*.gridster-grid > li {
    background-color: blue;
}*/


// markup dashboard

.edit_layout_toggle_box {
	float:left;
	height:50px;
	margin:69px 0 0 0;
	position:relative;
}

.edit_layout_toggle_box li {
	float:right;
	position:relative;
}

.edit_layout_toggle_box li + li {
	margin:0 5px 0 0;
}

.edit_layout_toggle_box li + li:before {
	content:'';
	display:block;
	position:absolute;
	top:17px;
	right:-6px;
	width:7px;
	height:16px;
	border-top:1px solid #CCC;
	border-bottom:1px solid #CCC;
	z-index:10;
	background:#FFF;
}

.edit_layout_toggle_box a {
	display:block;
	border:1px solid #CCC;
	border-radius:5px;
	background:#FFF;
	float:right;
	padding:5px 15px 5px 15px;
	font-weight:bold;
	color:#000;
	text-decoration:none;
	height:50px;
	line-height:42px;
	font-size:33px;
	position:relative;
}

.edit_layout_toggle_box .edit_layout_refresh {
	padding-left:12px;
	padding-right:12px;
}

.edit_layout_refresh:before {
	content:'';
	display:block;
	width:38px;
	height:38px;
	background:#6EB5EB url(../../img/ico_refresh.png) center no-repeat;
	border-radius:5px;
}

.edit_layout_toggle_box .edit_layout_toggle.active {
	height:60px;
	border-bottom-color:#FFF;
	border-radius:5px 5px 0 0;
}

.edit_layout_toggle.active b {
	display:block;
}

.edit_layout_toggle b {
	display:none;
	position:absolute;
	height:8px;
	bottom:-1px;
	background:#FFF;
	width:100%;
	left:-8px;
	overflow:hidden;
	padding:0 8px 0 8px;

	box-sizing:content-box;
	-moz-box-sizing:content-box;
	-webkit-box-sizing:content-box;
}

.chrome .edit_layout_toggle b {
	left:-9px;
}

.edit_layout_toggle b:before {
	content:'';
	display:block;
	position:absolute;
	width:16px;
	height:16px;
	border:1px solid #cccccc;
	border-radius:16px;
	left:-8px;
	bottom:0;
	float:left;
}

.edit_layout_toggle b:after {
	content:'';
	display:block;
	position:absolute;
	width:16px;
	height:16px;
	border:1px solid #cccccc;
	border-radius:16px;
	float:right;
	bottom:0;
	right:-8px;
}

.edit_layout_container {
	margin:0 0 37px 0;
	clear:both;
}

.edit_layout_box {
	display:block;
	clear:both;
	background:#FFF;
	border:1px solid #CCC;
	border-radius:9px;
	padding:19px 34px 23px 32px;
	position:relative;
	z-index:10;
}

.edit_layout_box:after {
	content:'';
	display:table;
	clear:both;
}

.edit_layout_box .std_block_shadow {
	height:16px;
}

.edit_layout_tabs {
	float:right;
	display:block;
}

.edit_layout_tabs li {
	float:right;
	margin:0 0 0 1px;
}

.edit_layout_tabs a {
	display:block;
	height:43px;
	line-height:43px;
	min-width:126px;
	padding:0 10px 0 10px;
	text-align:center;
	color:#0D83DD;
}

.edit_layout_tabs a:hover:not(.active) {
	background:#F6F6F6;
	text-decoration:none;
	border-bottom:1px solid #FFF;
}

.edit_layout_tabs .active {
	background:#F6F6F6;
	text-decoration:none;
	color:#575757;
}

.edit_layout_content {
	width:100%;
	background:#F6F6F6;
	clear:both;
	direction:ltr;
	position:relative;
	height:149px;
}

.edit_layout_tab_box {
	padding:25px 57px 32px 12px;
	position:relative;
	height:149px;
	width:100%;
	width:1012px;
	display:block;
}

.edit_layout_tab_box .bx-wrapper, .edit_layout_tab_box .bx-viewport {
	width:100%;
	height:100%;
	min-height:100%;
}

.edit_layout_tab_box:first-of-type {
	display:block;
}

.edit_layout_content:after {
	content:'';
	display:table;
	clear:both;
}

.edit_layout_content li {
	float:right;
	/*width:25%;*/
	width:235.75px;
	min-height:92px;
	text-align:right;
}

.widgets_slider_content_box {
	// width:235px;
	// height:92px;
	cursor:move;
	color:#000;
}

.dnd_widget_description {
	display:none;
}

.edit_layout_content .dnd_widget_description {
	display:block;
}

.edit_layout_content .std_block_header,
.edit_layout_content .std_block_content,
.edit_layout_content .std_block_close,
.edit_layout_content .std_block_shadow {
	display:none;
}

.edit_layout_content  .std_block {
	border:none;
	border-radius:0;
	margin:0;
	background:none;
}

.widget_img_box {
	float:right;
	width:106px;
	height:90px;
	background:#CCC;
	margin:0 0 0 5px;
}

.edit_layout_content h3 {
	font-size:25px;
	line-height:28px;
	font-weight:normal;
	padding:0 122px 0 0;
	float:right;
	width:100%;
	margin:0 -111px 0 0;
	position:relative;
}

.edit_layout_content h3:before {
	content:'';
	display:inline-block;
	position:absolute;
	top:3px;
	right:111px;

	width:0;
	height:0;
	border:9px solid transparent;
	border-right:5px solid #000;
}

.edit_layout_content p {
	font-size:19px;
	line-height:26px;
	padding:0 122px 0 0;
	float:right;
	width:100%;
	margin:0 -111px 0 0;
}

.edit_layout_content li > a {
	color:#000;
	text-decoration:none;
}

.edit_layout_content li > a:hover {
	cursor:move;
	color:#0d83dd;
}

.edit_layout_content .bx-controls-direction {
	position:absolute;
	left:0;
	top:-39px;
}

.edit_layout_content .bx-controls-direction a {
	display:block;
	float:right;
	width:24px;
	height:24px;
	background:#868686;
	text-indent:-9999px;
	font-size:0;
	position:relative;
}

.edit_layout_content .bx-controls-direction a:before {
	content:'';
	display:block;
	position:absolute;
	width:0;
	height:0;
	border:9px solid transparent;
}

.edit_layout_content .bx-controls-direction a + a {
	margin:0 5px 0 0;
}

.edit_layout_content .bx-controls-direction .bx-next:before {
	border-right:13px solid #EDEDED;
	top:3px;
	right:7px;
}

.edit_layout_content .bx-controls-direction .bx-prev:before {
	border-left:13px solid #EDEDED;
	top:3px;
	left:7px;
}

.edit_layout_content .bx-pager {
	display:block;
	position:absolute;
	top:-39px;
	left:65px;
}


.edit_layout_content .bx-pager-item {
	float:right;
	width:22px;
	height:22px;
	margin:1px 9px 1px 0;
}

.edit_layout_content .bx-pager-item a {
	display:block;
	width:22px;
	height:22px;
	background:#DADADA;
	text-indent:-9999px;
	font-size:0;
	border-radius:22px;
}

.edit_layout_content .bx-pager-item a:hover {
	background:#868686;
}

.edit_layout_content .bx-pager-item .active {
	background:#868686;
}

.std_block_shadow {
	top:100%;
	left:10px;
	right:10px;
	position:absolute;
	height:10px;
	// overflow:hidden;
}

.std_block_shadow:before, .std_block_shadow:after {
	content:'';
	display:block;
	float:left;
	width:50%;
	height:100%;
	background:url(../../img/bgr_std_block_shadow.png) no-repeat;
}

.std_block_shadow:before {
	background-position:0 bottom;
}
.std_block_shadow:after {
	background-position:right bottom;
}

/* Content ------------------------------------------------------------------------------*/

#body {
	width:100%;
}

#body:after {
	content:'';
	display:table;
	clear:both;
}

#content:only-child {
	width:100%;
}

#content.full_width {
	width:100%;
}

#sidebar_right {
	float:right;
	width:296px;
	margin:0 0 41px 30px;
}

#sidebar_right ~ #content {
	float:left;
	width:720px;
}

/* Footer ------------------------------------------------------------------------------*/

#footer {
	width:100%;
	clear:both;
	border-top:2px solid #CCC;
	padding:20px 0 68px 0;

	color:#999999;
}

.footer_logo {
	float:right;
	width:227px;
	height:82px;
	background:url(../../img/footer_logo.png) center no-repeat;
	margin:6px 0 14px 71px;
}

.footer_menu {
	float:right;
	margin:0 0 7px 0;
}

.footer_menu:after {
	content:'';
	display:table;
	clear:both;
}

.footer_menu > li {
	float:right;
	font-size:17px;
	line-height:25px;
	margin:0 0 0 92px;
	color:#999999;
}

.footer_menu > li h5 {
	font-size:24px;
	font-weight:bold;
	margin:0 0 4px 0;
}

.footer_menu > li a {
	color:#999999;
}

.footer_menu > li a:hover {
	color:#0D83DD;
}

.footer_fb_button {
	display:block;
	float:left;
	background:#0D82DC url(../../img/ico_footer_facebook.png) center no-repeat;
	width:154px;
	height:54px;
	border-radius:4px;
	position:relative;
	margin:25px 0 0 0;
}

.footer_fb_button:before {
	content:'';
	display:block;
	position:absolute;
	top:5px;
	left:6px;
	right:6px;
	bottom:5px;
	border:2px solid #FFF;
}

.copyrights {
	clear:both;
	border-top:1px solid #CCC;
	text-align:left;
	padding:5px 0 0 0;
}


/* Content Parts ------------------------------------------------------------------------------*/

.std_block {
	display:block;
	clear:both;
	background:#FFF;
	border:1px solid #CCC;
	border-radius:9px;
	position:relative;
	min-height:100px;
	margin-bottom: 41px;
}

.std_block:after {
	content:'';
	display:table;
	clear:both;
}

.std_block.ui-sortable-handle.ui-sortable-helper {
	box-shadow:0 0 100px 0 rgba(0, 0, 0, 0.3);
}

.std_block_header {
	background:#F6F6F6;
	line-height:36px;
	font-size:22px;
	padding:13px 8px 7px 8px;
	border-radius:8px 8px 0 0;
	min-height:56px;
}

.std_block_header:after {
	content:'';
	display:table;
	clear:both;
}

.std_block_header h2 {
	font-size:33px;
	font-weight:bold;
	float:right;
	color:#000;
}

.std_block_header h2 small {
	font-weight:normal;
}

.std_block_close {
	display:block;
	position:absolute;
	left:6px;
	top:6px;
	width:20px;
	height:20px;
	background:url(../../img/ico_std_block_close@2x.png) 0 0;

	background-size:100% auto;
	-moz-background-size:100% auto;
	-webkit-background-size:100% auto;
}

.oldie .std_block_close {
	background:url(../../img/ico_std_block_close.png) 0 0;
}

.std_block_close:hover {
	background-position:0 -25px;
}

.std_block_content {
	position:relative;
}

.currencies_tbl {
	width:100%;
	border-radius:0 0 9px 9px;
	overflow:hidden;
}

.currencies_tbl table {
	width:100%;
	color:#575756;
}

.currencies_tbl td {
	border-right:2px solid #FFF;
	border-top:2px solid #FFF;
	text-align:center;
	padding:17px 0 14px 0;
}

.currencies_tbl tbody td {
	background:#F6F6F6;
}

.currencies_tbl td:first-child {
	border-right:14px solid #F6F6F6;
}

.currencies_tbl td:last-child {
	border-left:14px solid #F6F6F6;
}

.currencies_tbl thead td {
	font-weight:bold;
	color:#1D1D1D;
}

.currencies_tbl thead td:first-child {
	border-right:14px solid #FFF;
}

.currencies_tbl thead td:last-child {
	border-left:14px solid #FFF;
}

.currencies_tbl tbody tr:last-child td {
	border-bottom:20px solid #F6F6F6;
}

.the_button.load_more_action {
	float:left;
	padding:0 8px 0 8px;
	line-height:34px;
	height:36px;
	font-size:19px;
}

.the_button.load_more_action:before {
	content:'';
	display:inline-block;
	width:16px;
	height:16px;
	background:url(../../img/ico_plus_16x16.png);
	margin:0 0 0 7px;
	position:relative;
	top:2px;
}



table{
	/*direction: ltr;*/
}




.graph_widget_container {
    .amount{
        direction: ltr;
        text-align: right;
        white-space: nowrap;

    }
}

.graph_widget_container:after {
	content:'';
	display:table;
	clear:both;
}

.graph_widget_tabs {
	float:right;
	width:220px;
	display:table;
	height:100%;
}

.graph_widget_tabs a {
	display:table;
	width:100%;
	border-left:1px solid #CCC;
	color:#DADADA;
	font-size:25px;
	line-height:30px;
	min-height:139px;
	position:relative;
	z-index:10;
	height:50%;
}

.std_block_header ~ * .graph_widget_tabs a {
	border-top:1px solid #CCC;
}

.graph_widget_tabs a + a {
	border-top:1px solid #CCC;
}

.graph_widget_tabs a:first-child {
	border-radius:0 8px 0 0;
}

.std_block_header ~ * .graph_widget_tabs a:first-child {
	border-radius:0;
}

.graph_widget_tabs a:last-child {
	border-radius:0 0 8px 0;
}

.graph_widget_tabs_valign {
	display:table-cell;
	width:100%;
	height:100%;
	vertical-align:middle;
	padding:10px 69px 0 20px;
    word-wrap: break-word;
}

.graph_widget_tabs a span {
	position:relative;
	z-index:10;
	display:block;
}

.graph_widget_tabs a strong {
	display:block;
	font-size:30px;
	line-height:37px;
	font-weight:normal;

	position:relative;
	z-index:10;
}

.graph_widget_tabs a:hover {
	color:#0a81dd;
	text-decoration:none;
	background:#e7f4fe;
}

.graph_widget_tabs a[class*="graph_tab_icon_"]:before {
	content:'';
	display:block;
	position:absolute;
	z-index:0;
}

.graph_tab_icon_1:before {
	top:6px;
	right:12px;
	width:56px;
	height:64px;
	background:url(../../img/sprite_income_graph_tabs.png) 0 0 no-repeat;
}

.graph_tab_icon_1:hover:before, .graph_tab_icon_1.active:before {
	background-position:-100px 0;
}

.graph_tab_icon_2:before {
	content:'';
	display:block;
	position:absolute;
	top:9px;
	right:7px;
	width:69px;
	height:66px;
	background:url(../../img/sprite_income_graph_tabs.png) 0 -100px no-repeat;
	z-index:0;
}

.graph_tab_icon_2:hover:before, .graph_tab_icon_2.active:before {
	background-position:-100px -100px;
}



.graph_widget_tabs .active {
	color:#0a81dd;
	background:#e7f4fe;
	font-weight:bold;
}

.graph_widget_tabs .active:after {
	content:'';
	display:block;
	position:absolute;
	width:12px;
	height:37px;
	right:100%;
	top:50%;
	background:url(../../img/pic_income_tab_active.png);
	margin:-18px 0 0 0;
}

.mac.firefox .graph_widget_tabs .active:after {
	margin-right:-1px;
}

.graph_widget_tabs .active strong {
	font-weight:bold;
}



.graph_widget_graph_box {
	padding:33px 243px 22px 20px;
	min-height:278px;
	position:relative;
	float:left;
	direction:ltr;
	width:90%;
	margin:0 -220px 0 0;
}

.income_graph {
	width:100%;
	height:223px;
	position:relative;
}


.std_block.banners_container {
	border:none;
	border-radius:0px;
}

.std_block.banners_container .std_block_shadow {
	display:none;
}

.banner_box {
	float:right;
	border:1px solid #CCC;
}

.banner_box:empty {
	display:none;
}

.affiliate_banner {
	text-align:center;
	min-height:150px;
	width:427px;
	padding:16px 0 0 0;

	vertical-align:bottom;
}



.aff_bnnr_text {
	padding:10px 0 0 0;
	font-size:21px;
	line-height:24px;
	clear:both;
	text-align:center;
}

.aff_bnnr_text strong {
	font-size:31px;
	font-weight:bold;
	color:#0D83DD;
}

.aff_bnnr_text > * {
	position:relative;
}

.aff_bnnr_text > * + * {
	margin:0 10px 0 0;
}

.aff_bnnr_text > * + *:before {
	content:'|';
	font-size:31px;
	position:absolute;
	display:block;
	top:-3px;
	right:-10px;
}

.banner2 {
	float:left;
}

.graph_2 .graph_widget_tabs a {
	height:160px;
}

.graph_2 .graph_widget_graph_box {
	padding-top:60px;
}

.graph_tab_icon_3:before {
  content: '';
  display: block;
  position: absolute;
  top: 9px;
  right: 7px;
  width: 69px;
  height: 66px;
  background: url(../../img/sprite_income_graph_tabs.png) 0 -200px no-repeat;
  z-index: 0;
}

.graph_tab_icon_3:hover:before, .graph_tab_icon_3.active:before {
	background-position:-100px -200px;
}

.users_comissions_graph {
	width:100%;
	height:237px;
}


.my_users_block {

    table.table-striped.table {
        td {
            font-size: 13px;
            * {
                font-size: inherit;
            }
        }
        th {
            *{
                font-size: inherit;
            }
            font-size: 13px;
            white-space: nowrap;
        }
    }

    table td.name{
        text-decoration: underline;
    }

    table td.status-column{
        white-space: nowrap;
    }

    table td.name-column{
        white-space: normal;
        word-wrap: break-word;
    }
}



.my_users_block {
	border-radius:0;
	border:none;

}

.my_users_block .std_block_header {
	padding:24px 21px 23px 21px;
	border-radius:0;
}

.my_users_tabs_box {
	margin:19px 0 0 0;
}

.my_users_tabs_box:after {
	content:'';
	display:table;
	clear:both;
}

.my_users_tabs {
	float:right;
    .nav-tabs{
        background: none;
        margin: 0;
    }
    .tab-pane{
        margin-top:0;

        table{
            table-layout: auto
        }
    }
    td, th{
        text-align: center;
        padding: 5px;
    }
    .filter_date_range .hasDatepicker{
        width:39%;
    }
}

.my_users_tabs {
    width: 100%;
    float: right;
}

.my_users_tabs .nav-tabs > li {



    display: block;
    float: right;
    height: 45px;
    line-height: 48px;
    margin: 0 0 -1px 5px;
    padding: 0;
    background: #6eb5eb;
    &.active a, a{
        margin-right: 0;
            color: #FFF;
        padding: 6px 15px;
        background: none;
    }
    border: 1px solid #0D83DD;
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    white-space: nowrap;
    border-bottom: 1px solid #0d83dd;
}

.my_users_tabs .nav-tabs > li:hover {
    text-decoration: none;
    background: #0d83dd;
}

.my_users_tabs .nav-tabs > li.active {
    background: #0d83dd;
    font-weight: bold;
    border: 1px solid #CCC;
    border-bottom: 1px solid #0d83dd;
    height: 45px;
    margin-bottom: -1px;
}

.my_users_tabs_box {
    margin: 19px 0 0 0;
}

.my_users_tabs_box:after {
    content: '';
    display: table;
    clear: both;
}

.the_button_2.my_users_send_msg {
    float: left;
    width: 154px;
    height: 36px;
    line-height: 34px;
    font-size: 20px;

}

.the_button_2.my_users_send_msg:before {
	content:'';
	display:inline-block;
	position:relative;
	width:25px;
	height:17px;
	background:url(../../img/ico_mu_send_message.png);
	margin:0 0 0 5px;
	top:3px;
}

.my_users_tabs_content_box {
	border:1px solid #CCC;
	border-radius:0 0 9px 9px;
	overflow:hidden;
}




.the_table_type_1 {
	width:100%;
	color:#000000;
	clear:both;
	border:1px solid #CCC;
}

.oldie .the_table_type_1 {
	/*table-layout:fixed;*/
	float:right;
	width:100%;
	clear:both;
}

.the_table_type_1 tr > * {
	text-align:center;
	padding:10px;
	vertical-align: top;
	white-space:nowrap;
}

.the_table_type_1 td {
	border:1px solid #CCC;
}

.the_table_type_1 thead th {
	background:#0d83dd;
	color:#FFF;
	border-right:1px solid transparent;
	border-left:1px solid transparent;
}

.the_table_type_1 thead th:first-child {
	border-right:1px solid #CCC;
}

.the_table_type_1 thead th:last-child {
	border-left:1px solid #CCC;
}

.the_table_type_1 thead td {
	background-color:#ededed;
}


.the_table_type_1 thead input[type="text"], .the_table_type_1 thead select {
	display:block;
	width:100%;
	padding:0 5px;
	text-align:center;
	border:2px inset #e5e5e5;
	height:32px;
	background-color:#F6F6F6;
}

.the_table_type_1 tr > * > input[type="checkbox"] {
	display:block;
	padding:0;
	margin:0 auto;
}

.the_table_type_1 thead select, .the_table_type_1 thead .custom_select_type_1 {
	min-width:60px;
}

.the_table_type_1 thead input[type="text"]:focus, .the_table_type_1 thead select:focus {
	background-color:#FFF;
}

.the_table_type_1 .custom_select_type_1 {
	display:block;
}

.the_table_type_1 tbody tr:nth-of-type(even) td {
	background:#f6f6f6;
}

.the_table_type_1 small {
	font-size:16px;
}

.controllable_cell {
	padding-left:60px;
	padding-right:60px;
}

.controllable_cell:hover {
	background:#6EB5EB;
	border-left-style:double;
    border-left-color:#CCC;
	border-right-style:double;
    border-right-color:#CCC;
}

.delete_colunm {
	display:block;
	float:right;
	width:0;
	height:0;
	position:relative;

	display:none;
	overflow:hidden;
}

.delete_colunm b {
	display:block;
	position:absolute;
	left:-2px;
	bottom:-2px;
	width:24px;
	height:24px;
	background:#706F6F url(../../img/sprite_tbl_icons.png) 0 -850px no-repeat;
	border-radius:24px;
}

.delete_colunm:hover b {
	background-color:#000;
}

.controllable_cell:hover > .delete_colunm {
	display:block;
	overflow:visible;
}

.sort_buttons {
	float:left;
	width:0;
	height:22px;
	font-size:0;
	direction:ltr;

	visibility:hidden;
	overflow:hidden;

	transition:width 0.2s ease;
	-moz-transition:width 0.2s ease;
	-webkit-transition:width 0.2s ease;
}

.controllable_cell:hover > .sort_buttons {
	visibility:visible;
	overflow:visible;
	width:49px;
}

.sort_buttons a {
	display:inline-block;
	background:#9CDDFF;
	width:22px;
	height:22px;
	border-radius:2px;
	margin-top:0px;
}

.sort_buttons a:before {
	content:'';
	display:block;
	width:0;
	height:0;
	margin:auto;
	border:8px solid transparent;
}

.sort_buttons a:hover {
	background:#0D83DD;
}

.sort_buttons .direct:before {
	border-top-color:#FFF;
	margin-top:8px;
}

.sort_buttons .inverse:before {
	border-bottom-color:#FFF;
	margin-top:-2px;
}

.sort_buttons a + a {
	margin-left:5px;
}



.tbl_time_range {
	width:137px;
	margin:auto;
	position:relative;
}

.tbl_time_range > div {
	width:50%;
	float:right;
	padding:0 0 0 8px;
}

.tbl_time_range > div input {
	width:100%;
	text-align:center;
}

.tbl_time_range > div ~ div {
	float:left;
	padding:0 8px 0 0;
}

.tbl_time_range span {
	float:right;
	width:16px;
	margin:0 -8px 0 -8px;
	font-size:20px;
	line-height:32px;
	text-align:center;
	position:relative;
}


.tbl_search_find_button {
	display:block;
	width:22px;
	height:28px;
	background:url(../../img/ico_users_tbl_search.png);
	margin:auto;
}

.the_table_type_1 tbody tr:hover td {
	background:#e7f4fe;
	text-decoration:none;
}


.the_table_type_1 tr:hover .nc_buttons {
	display:block;
}





.the_table_type_1.small_tbl {
	font-size:18px;
}

.the_table_type_1.small_tbl tr > * {
	padding:7px;
}

.the_table_type_1.small_tbl thead input[type="text"], .the_table_type_1.small_tbl thead select {
	height:18px;
	font-size:14px;
	border-width:1px;

}

.the_table_type_1.small_tbl thead select {
	padding-left:22px;
	background-position:3px 5px;

	background-size:16px auto;
	-moz-background-size:16px auto;
	-webkit-background-size:16px auto;
}

.small_tbl .tbl_time_range span {
	line-height:18px;
	font-size:18px;
}


.tbl_1_checkbox_container {
	width:40px;
	margin:auto;
}

td.name_cell {
	width:100%;
	text-align:right;
}

.name_cell > span {
	float:right;
	line-height:30px;
}

.nc_buttons {
	float:left;
	display:none;
}

.selected .nc_buttons {
	display:block;
}

.nc_buttons .the_button_2 {
	display:block;
	float:right;
	margin:0 4px 0 0;
	width:30px;
	height:30px;
	border-width:1px;
	padding:0;
}

.name_cell .the_button_2.export {
	background-image:url(../../img/sprite_tbl_icons.png);
	background-position:2px 2px;
}

.name_cell .the_button_2.print {
	background-image:url(../../img/sprite_tbl_icons.png);
	background-position:2px -48px;
}

.name_cell .the_button_2.print:before {
	display:none;
}

.my_users_tab {
	min-height:150px;
}

.my_users_tab .the_table_type_1 {
	border:none;
}

.my_users_tab .the_table_type_1 tr > *:first-child {
	border-right:none;
}

.my_users_tab .the_table_type_1 tr > *:last-child {
	border-left:none;
}

.my_users_tab .the_table_type_1 tbody tr:last-child > * {
	border-bottom:none;
}




.view_all_requests {
	float:left;
	margin:0 0 0 13px;
	font-size:22px;
	line-height:28px;
	text-decoration:underline;
}

.view_all_requests:hover {
	text-decoration:none;
}


.recent_actions_refresh {
	float:left;
	width:26px;
	height:25px;
	background:url(../../img/ico_refresh_gray.png);
	margin:2px 0 0 6px;
}


.detailed_graph_right_part {
	float:right;
	width:240px;
	border-left:1px solid #CCC;
	background:#f6f6f6;
	padding:82px 27px 78px 30px;
	position:relative;
	z-index:10;
	border-radius:0 8px 8px 0;
}

.detailed_graph_right_part h4 {
	font-size:26px;
	line-height:24px;
	color:#000;
	border-bottom:1px solid #CCC;
}

.detailed_graph_right_part a {
	color:#0D83DD;
}

.detailed_graph_table {

    td {

        &:first-child {
            a {
                font-size: 14px;
                position: relative;
                padding: 0 5px;
                display: inline-block;
                vertical-align: top;

                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: -2px;
                    transform: translateY(-50%);
                    width: 3px;
                    height: 3px;
                    border-radius: 50%;
                    background: #0D83DD;
                    margin: 0 2px;

                }
            }
        }

    }
}

.he .detailed_graph_table {

    td {

        &:first-child {
            a {

                &:before {
                    left: auto;
                    right: -2px;

                }
            }
        }
    }
}

.detailed_graph_right_part section {
	border-bottom:1px solid #CCC;
	font-size:25px;
	margin:0 0 13px 0;
	padding:0 4px 0 0;
}


.detailed_graph_table {
	width:100%;
	font-size:16px;
}

.detailed_graph_table td {
	text-align:left;
	border-right:1px solid #CCC;
	height:28px;
    padding: 4px;
}
.detailed_graph_table td.ltr{
    border-left:1px solid #CCC;
}
.detailed_graph_table td:first-child {
	text-align:right;
	border-right:none;
}

.detailed_graph_table tr:first-child td {
	padding-top:8px;
}

.detailed_graph_table tr:last-child td {
	padding-bottom:8px;
}

.graph_widget_graph_box.detailed {
	margin-right:-240px;
	padding:0 240px 0 0;
}

.graph_widget_graph_box h3 {
	background:#f6f6f6;
	border-radius:8px 0 0 0;
	color:#9D9D9C;
	padding:9px 23px 2px 0;
	font-size:39px;
	line-height:45px;
}

.graph_widget_graph_box.detailed .users_comissions_graph {
	height:346px;
	padding:35px 75px 0 55px;
}


.table_controls_type_1 {
	border:2px dashed #CCC;
	float:right;
	margin: 0px 153px 29px 0;
	clear:both;
	padding:18px 16px 18px 16px;
	position:relative;
}

.table_controls_type_1:before {
	content:'';
	display:block;
	position:absolute;
	top:42px;
	left:100%;
	border-top:2px dashed #CCCCCC;
	border-right:2px dashed #CCCCCC;
	width:124px;
	height:51px;
}

.table_controls_type_1:after {
	content:'';
	display:block;
	position:absolute;
	width:0;
	height:0;
	right:-133px;
	top:95px;
	border:10px solid transparent;
	border-top:17px solid #CCC;
}

.table_controls_type_1 .the_button_2 {
	float:right;
	font-size:30px;
	height:48px;
	line-height:46px;
	padding:0 28px;
}

.table_controls_type_1 .the_button_2.export:before {
	content:'';
	display:inline-block;
	width:20px;
	height:27px;
	margin:0 0 0 8px;
	position:relative;
	top:3px;
	background:url(../../img/sprite_tbl_icons.png) 0 -100px no-repeat;
}

.the_button_2.print:before {
	content:'';
	display:inline-block;
	width:35px;
	height:27px;
	margin:0 0 0 8px;
	position:relative;
	top:5px;
	background:url(../../img/sprite_tbl_icons.png) 0 -150px no-repeat;
}
.the_button_2.print-grey:before {
	content:'';
	display:inline-block;
	position:relative;
	top:5px;
	background-position: 0 -100px;
    width: 22px;
    height: 16px;
    margin: -3px 0 3px 5px;
}

.table_controls_type_1 .the_button_2 + .the_button_2 {
	margin:0 12px 0 0;
}


.smile_man_block.type_2 {
	border-radius:9px;
	border:1px solid #CCC;
	margin-top:67px;
	background:#FFF;
}

.smile_man_block.type_2 .smb_right {
	background:#EDEDED;
	padding:38px 51px 21px 19px;
	margin:0;
	border-radius:0 9px 9px 0;
}

.smile_man_block.type_2 .smb_left {
	padding:15px 227px 15px 17px;
}


.right_side_menu {
	display:block;
	border:1px solid #CCC;
	border-radius:9px;
}

.right_side_menu a {
	display:block;
	font-size:25px;
	line-height:30px;
	padding:14px 20px 12px 15px;
	position:relative;
}

.right_side_menu > ul > li > a {
	padding-right:62px;
}

.right_side_menu li:first-child > a {
	border-radius:9px 9px 0 0;
}

.right_side_menu li:last-child > a {
	border-radius:0 0 9px 9px;
}

.right_side_menu > ul > li > a:before {
	content:'';
	display:block;
	width:40px;
	height:40px;
	border-radius:40px;
	background:#0D83DD url(../../img/sprite_right_sb_icons.png);
	position:absolute;
	top:50%;
	margin:-20px 0 0 0;
	right:14px;
}

.right_side_menu > ul > li > a:after {
	content:'';
	display:none;
	position:absolute;
	width:6px;
	height:12px;
	background:url(../../img/sprite_right_sb_icons.png) 0 0;
	top:50%;
	left:9px;
	margin:-6px 0 0 0;
}

.right_side_menu a:hover {
	background:#E7F4FE;
	text-decoration:none;
}

.right_side_menu a:hover:after {
	display:block;
}

.right_side_menu li:hover > a {
	background:#E7F4FE;
}

.right_side_menu li:hover a:after {
	display:block;
}


.right_side_menu a.active {
	background:#E7F4FE;
}

.right_side_menu a.active:after {
	display:block;
}



.right_side_menu li {
	display:block;
	position:relative;
}

.right_side_menu ul ul {
	display:block;
	position:absolute;
	top:-9999px;
	right:100%;
	width:100%;
	text-align:center;
	border:1px solid #CCC;
	border-radius:9px;
	background:#FFF;
	margin:0 4px 0 0;

	z-index:1000;

	opacity:0;

	transition:opacity 0.2s ease;
	-moz-transition:opacity 0.2s ease;
	-webkit-transition:opacity 0.2s ease;
}

.right_side_menu ul ul:before {
	content:'';
	display:block;
	position:absolute;
	left:100%;
	top:0;
	bottom:0;
	width:10px;
	background:none;
}

.right_side_menu li:hover ul {
	opacity:1;
	top:0;
}

.right_side_menu ul ul + a:after {
	background:none;
	display:block;
	width:0;
	height:0;
	border:9px solid transparent;
	border-right-color:#00F;
	left:-2px;
	margin:-9px 0 0 0;
}


.right_side_menu .rsb_icon_1:before {
	background-position:0 -100px;
}

.right_side_menu .rsb_icon_2:before {
	background-position:0 -200px;
}

.right_side_menu .rsb_icon_3:before {
	background-position:0 -300px;
}

.right_side_menu .rsb_icon_4:before {
	background-position:0 -400px;
}

.right_side_menu .rsb_icon_5:before {
	background-position:0 -500px;
}

.right_side_menu .rsb_icon_6:before {
	background-position:0 -600px;
}

.right_side_menu .rsb_icon_7:before {
	background-position:0 -700px;
}

.right_side_menu .rsb_icon_8:before {
	background-position:0 -800px;
}

.right_side_menu .rsb_icon_9:before {
	background-position:0 -900px;
}


.right_side_menu h4 {
	display:block;
	background:#F6F6F6;
	font-size:30px;
	line-height:34px;
	color:#1C1C1C;
	border-radius:8px 8px 0 0;
	padding:13px 22px 7px 22px;
	border-bottom:1px solid #CCC;
}



.fees_report_tbl_1 {
	border:none;
	border-radius:0;


    .std_block_header {
        padding:24px 21px 23px 21px;
        border-radius:0;
        margin:0 0 24px 0;
    }

    .commissions-table > thead > tr > td {
        padding:8px 10px 5px 10px;
        border:1px solid #CCC;
    }

    .commissions-table > tbody > tr > td {
        padding:10px;
        border:1px solid #CCC;
    }


}




.table_controls_type_2_box {
	position:relative;
	overflow:hidden;
	padding:0 197px 18px 0;
}

.table_controls_type_2_box:before {
	content:'';
	display:block;
	position:absolute;
	top:29px;
	left:0;
	right: 28px;
	bottom:2px;
	border-top:2px dashed #CCCCCC;
	border-right:2px dashed #CCCCCC;
}

.table_controls_type_2_box:after {
	content:'';
	display:block;
	position:absolute;
	width:0;
	height:0;
	right: 19px;
	bottom:-10px;
	border:10px solid transparent;
	border-top:17px solid #CCC;
}

.table_controls_type_2 {
	border:2px dashed #CCC;
	float:right;
	clear:both;
	background:#FFF;
	padding:12px 10px 12px 10px;
	position:relative;
}

.table_controls_type_2:after {
	content:'';
	display:block;
	position:absolute;
	right:100%;
	top:-2px;
	bottom:-2px;
	margin:0 2px 0 0;
	width:9999px;
	background:#FFF;
}

.buttons_on_the_left .table_controls_type_2 {
	float:left;
}


.table_controls_type_2 .the_button_2 {
	float:right;
	font-size:20px;
	height:32px;
	line-height:30px;
	padding:0 20px;
}

.table_controls_type_2 .the_button_2.export:before {
	content:'';
	display:inline-block;
	vertical-align:top;
	width:23px;
	height:23px;
	margin:0 0 0 8px;
	position:relative;
	top:2px;
	background:url(../../img/sprite_tbl_icons.png) 0 0 no-repeat;
}

.table_controls_type_2 .the_button_2.print:before {
	content:'';
	display:inline-block;
	vertical-align:top;
	width:23px;
	height:23px;
	margin:0 0 0 8px;
	position:relative;
	top:2px;
	background:url(../../img/sprite_tbl_icons.png) 0 -50px no-repeat;
}


.table_controls_type_2 .the_button_2 + .the_button_2 {
	margin:0 12px 0 0;
}

.comission_blue_box {
	height:352px;
	margin:33px 219px 35px 51px;
	padding:92px 204px 0 57px;
	background:#0D83DD;
	color:#FFF;
	border-radius:8px 0 0 8px;
	position:relative;
	text-align:left;
	font-size:40px;
	font-weight:bold;
	line-height:44px;

    .ui-select-match {
        font-size: 14px;
    }
}

.comission_blue_box:before {
	content:'';
	display:block;
	position:absolute;
	width:394px;
	height:363px;
	background:url(../../img/img_blue_box_img_1.png);
	right:-211px;
	top:-1px;
	z-index:1;
}

.comission_blue_box > div {
	position:relative;
	z-index:10;
}

.the_button.press_to_update {
	float:left;
	height:46px;
	width:202px;
	line-height:46px;
	font-size:31px;
	border-radius:3px;
	margin:36px 0 0 0;
}

.cb_select_1, .custom_select_type_1.cb_select_1 {
	width:265px;
}



.ie .cb_select_1 {
	background:#F6F6F6;
}

/*
html.win:not(.chrome):not(.safari):not(.opera):not(.firefox) .cb_select_1 {
	background:#F6F6F6;
	padding:0;
}
*/

.to_general_settings_link {
	float:left;
	font-size:23px;
	line-height:40px;
	margin:-24px 0 88px 42px;
	position:relative;
	padding:0 47px 0 0;
}

.to_general_settings_link:before {
	content:'';
	display:block;
	width:38px;
	height:40px;
	position:absolute;
	top:0;
	right:0;
	background:url(../../img/ico_gen_settings_1.png);
}

#content .std_block_2:first-child {
	margin-top:67px;
}

.std_block_2 {
	display:block;
	clear:both;
	background:#FFF;
	position:relative;
	margin:0 0 41px 0;
}

.std_block_2:after {
	content:'';
	display:table;
	clear:both;
}

.std_block_2_header {
	background:#F6F6F6;
	line-height:42px;
	font-size:35px;
	padding:24px 21px 23px 21px;
	min-height:56px;
}

.std_block_2_header:after {
	content:'';
	display:table;
	clear:both;
}
.std_block_2_header h2 {
	font-size:33px;
	font-weight:bold;
	float:right;
	color:#000;
}

.std_block_2_header h2 small {
	font-weight:normal;
}

.std_block_2_ttl_btns_box {
	float:left;
}

.std_block_2_ttl_btns_box .the_button_2 {
	float:right;
	font-size:26px;
	height:38px;
	line-height:36px;
	font-weight:normal;
}

.std_block_2_ttl_btns_box .the_button_2 + .the_button_2 {
	margin:0 9px 0 0;
}


.the_button_2.edit:before {
	content:'';
	display:inline-block;
	width:18px;
	height:30px;
	background:url(../../img/sprite_iconset_1.png) 0 0;
	position:relative;
	margin:-7px 0 -3px 5px;
	top:5px;
}

.the_button_2.delete:before {
	content:'';
	display:inline-block;
	width:18px;
	height:18px;
	background:url(../../img/sprite_iconset_1.png) 0 -50px;
	position:relative;
	margin:-7px 0 -3px 5px;
	top:-2px;
}

.std_block_2_content {
	position:relative;
}
.the_blue_popup_1 {
	//background:#FFF;
	//border:19px solid #ddecfc;
	//border-radius:12px;
	//padding:24px 151px 20px 28px;
	//box-shadow:3px 3px 0 0 rgba(0, 0, 0, 0.5);
	//margin: 0 auto;
	width: 700px;
	text-align: left;
	left: -137px;
	position: relative;
	//height: 55%;
}

.the_blue_popup_1 > div {
	background:#0d83dd;
	border-radius:7px 0 0 7px;
	padding:62px 170px 27px 42px;
	color:#FFF;
	font-size:32px;
	font-weight:bold;
	line-height:36px;
	height:294px;
	position:relative;
}

.the_blue_popup_1 > div:before {
	content:'';
	display:block;
	position:absolute;
	width:297px;
	height:296px;
	background:url(../../img/img_blue_box_img_2.png);
	top:-1px;
	right:-133px;
}

.the_blue_popup_1 h3 {
	font-size:32px;
}

.the_blue_popup_1 p {
	margin:0 0 50px 0;
}

.the_button.back_to_affiliate {
	height:47px;
	padding:0 16px 0 16px;
	font-size:31px;
	line-height:46px;
	border-radius:3px;
}

.canvasjs-chart-credit {
	display:block;
	height:0;
	width:0;
	overflow:hidden;
}

.the_button_2.tbl_send_btn {
	width:70px;
	height:29px;
	border-width:1px;
	line-height:28px;
	font-size:13px;
    direction: rtl;
}

.the_button_2.tbl_send_btn:before {
	content:'';
	display:inline-block;
	width:17px;
	height:21px;
	background:url(../../img/sprite_tbl_icons.png) 0 -200px;
	border-radius:2px;
	margin:-4px 0 -7px -10px;
}

.the_button_3 {
	display:inline-block;
	background:#6EB5EB;
	border:1px solid #0D83DD;
	color:#FFF;
	border-radius:4px;
	font-size:22px;
	height:38px;
	line-height:40px;
	padding:0 9px 0 9px;
}

.the_button_3:hover {
	background:#0D83DD;
	text-decoration:none;
}


.table_controls_type_3 {
	width:100%;
	clear:both;
	position:relative;
	float:left;
}

.table_controls_type_3_box {
	clear:both;
	position:relative;
	margin:0 72px 32px 0;
}

.table_controls_type_3_box:after {
	content:'';
	display:table;
	clear:both;
}

.table_controls_type_3:before {
	content:'';
	display:block;
	position:absolute;
	top:37%;
    left: 97.5%;
	margin:-1px 0 0 2px;
	border-top:2px dashed #CCCCCC;
	border-right:2px dashed #CCCCCC;
	width:40px;
	height:22px;
}

.table_controls_type_3:after {
	content:'';
	display:block;
	position:absolute;
	width:0;
	height:0;
	right:-51px;
	top:52px;
	border:10px solid transparent;
	border-top:17px solid #CCC;
}

.table_controls_type_3 .the_button_3 {
	float:right;
	margin:0 0 0 6px;
	min-width:105px;
	text-align:center;
}

.the_button_3.ctrl_1:before {
	content:'';
	display:inline-block;
	width:27px;
	height:27px;
	margin:-2px 0 -6px 5px;
	background:url(../../img/sprite_tbl_icons.png);
}

.the_button_3.ctrl_1.sms:before {
	background-position:0 -250px;
}

.the_button_3.ctrl_1.email:before {
	width:27px;
	height:17px;
	margin:-2px 0 -2px 5px;
	background-position:0 -300px;
}

.the_button_3.ctrl_1.info_bill:before {
	width:20px;
	height:19px;
	margin:-2px 0 -3px 5px;
	background-position:0 -350px;
}

.the_button_3.ctrl_1.another_invite:before {
	width:18px;
	height:18px;
	margin:-2px 0 -2px 5px;
	background-position:0 -400px;
}

.top_user_menu_box {
	background:#DDEDFD;
	clear:both;
}

.top_user_menu_box:before, .top_user_menu_box:after {
	content:'';
	display:table;
	clear:both;
}

.top_user_menu {
	float:right;

}

.top_user_menu li {
	float:right;
	position:relative;
	margin:0 0 0 -1px;
}

.top_user_menu li:after {
	content:'';
	display:block;
	position:absolute;
	top:7px;
	bottom:7px;
	left:0px;
	width:1px;
	background:#6EB5EB;
}

.top_user_menu li:last-child:after {
	display:none;
}

.top_user_menu a {
	display:block;
	height:57px;
	padding:0 0 0 0;
	line-height:59px;
	color:#0D83DD;
	min-width:115px;
	padding:0 20px 0 20px;
	text-align:center;
}

.top_user_menu a:hover {
	background:#6EB5EB;
	color:#FFF;
	text-decoration:none;
}

.top_user_menu .active {
	background:#6EB5EB;
	color:#FFF;
}

.top_user_menu_select, .custom_select_type_1.top_user_menu_select {
	float:right;
	margin:13px 10px 10px 0;
	width:181px;
}

/*
html.win:not(.chrome):not(.safari):not(.opera):not(.firefox) .top_user_menu_select {
	background:#FFFFFF;
}
*/

.tct_3_select_box, .custom_select_type_2.tct_3_select_box {
	float:right;
	margin:0 0 0 6px;
	width:153px;
}

.tct_3_select_box select {
	background:#6EB5EB url(../../img/ico_blue_dropdown_arrow.png) 10px 14px no-repeat;
	border:1px solid #0D83DD;
	color:#FFF;
	border-radius:4px;
	font-size:22px;
	height:38px;
	line-height:40px;
	padding:0 9px 0 35px;
	min-width:150px;

	appearance:none;
	-o-appearance:none;
	-ms-appearance:none;
	-moz-appearance:none;
	-webkit-appearance:none;
}

.ie .tct_3_select_box select {
	background:#6EB5EB;
	padding:0;
}

/*
html.win:not(.chrome):not(.safari):not(.opera):not(.firefox) .tct_3_select_box select {
	background:#6EB5EB;
	padding:0;
}
*/

.the_button_3.ctrl_1.export:before {
	width:17px;
	height:24px;
	margin:-2px 0 -4px 5px;
	background-position:0 -450px;
}

.the_button_3.ctrl_1.import:before {
	width:17px;
	height:22px;
	margin:-2px 0 -4px 5px;
	background-position:0 -500px;
}

.the_button_3.ctrl_1.print:before {
	width:26px;
	height:19px;
	margin:-2px 0 -2px 5px;
	background-position:0 -550px;
}


.the_button_2.edit_columns {
	float:left;
	border-width:1px;
	font-size:26px;
	height:38px;
	line-height:38px;
}

.the_button_2.edit_columns:before {
	content:'';
	display:inline-block;
	width:23px;
	height:24px;
	background:url(../../img/sprite_tbl_icons.png) 0 -600px no-repeat;
	margin:0 0 -4px 3px;
}

.the_button_2.chart {
	vertical-align:middle;
	line-height:28px;
}

.the_button_2.chart:before {
	content:'';
	display:inline-block;
	width:22px;
	height:15px;
	background:url(../../img/sprite_tbl_icons.png) 0 -650px no-repeat;
	vertical-align:middle;
}

.the_button_2.icon_1 {
	vertical-align:middle;
	line-height:28px;
}

.the_button_2.icon_1:before {
	content:'';
	display:inline-block;
	width:18px;
	height:20px;
	background:url(../../img/sprite_tbl_icons.png) 0 -700px no-repeat;
	vertical-align:middle;
	position:relative;
	left:1px;
}

.status_marker_1_green {
	display:block;
	width:25px;
	height:17px;
	background:url(../../img/sprite_tbl_icons.png) 0 -750px no-repeat;
	margin:auto;
}

.status_marker_1_gray {
	display:block;
	width:25px;
	height:28px;
	background:url(../../img/sprite_tbl_icons.png) 0 -800px no-repeat;
	margin:auto;
}


.the_button_3.ctrl_1.duplicate:before {
	width:38px;
	height:24px;
	background-position:0 -900px;
	margin-bottom:-5px;
}

.name_cell .the_button_2.print_2 {
	width:37px;
}

.name_cell .the_button_2.print_2:before {
	content:'';
	display:block;
	width:26px;
	height:19px;
	background:url(../../img/sprite_tbl_icons.png) 0 -550px no-repeat;
	margin:4px auto 0 auto;
}



.name_cell .the_button_2.duplicate {
	width:37px;
}

.name_cell .the_button_2.duplicate:before {
	content:'';
	display:block;
	width:27px;
	height:17px;
	background:url(../../img/sprite_tbl_icons.png) 0 -950px no-repeat;
	margin:5px auto 0 auto;
}

.page_title_type_1 {
	border-bottom:1px solid #CCC;
	padding:0 0 17px 0;
	margin:0 0 40px 0;
}

.page_title_type_1:after {
	content:'';
	display:table;
	clear:both;
}

.page_title_type_1 h2 {
	font-size:35px;
	line-height:35px;

	float:right;
	font-weight:normal;
	margin:17px 0 -11px 0;
}

.page_title_type_1 strong {
	font-size:40px;
	font-weight:bold;
	color:#1C1C1C;
}

.page_title_type_1 h2 * {
	float:right;
	position:relative;
}

.page_title_type_1 h2 * + * {
	margin:0 32px 0 0;
}

.page_title_type_1 h2 * + *:before {
	content:'|';
	display:block;
	position:absolute;
	top:0;
	right:-18px;
}

.the_button_2.export_data {
	float:left;
	height:52px;
	line-height:40px;
	font-size:26px;
	padding:0 13px 0 13px;
}

.the_button_2.export_data:before {
	content:'';
	display:inline-block;
	width:20px;
	height:27px;
	background:url(../../img/sprite_tbl_icons.png) 0 -100px no-repeat;
	margin:3px 0 -5px 5px;
}


.the_button_2.email:before {
	content:'';
	display:inline-block;
	width:27px;
	height:17px;
	background:url(../../img/sprite_tbl_icons.png) 0 -300px no-repeat;
	margin:0 0 -2px 8px;
}

.the_button_2.download:before {
	content:'';
	display:inline-block;
	width:28px;
	height:18px;
	background:url(../../img/sprite_tbl_icons.png) 0 -1000px no-repeat;
	margin:0 0 -2px 8px;
}

.business_activity .graph_widget_graph_box {
	padding:0 220px 0 0;
}

.business_activity .graph_widget_tabs a {
	min-height:175px;
}


.business_activity .gw_graph {
	width:100%;
}

.brief_info_box {
	float:left;
	width:230px;
	border-right:1px solid #CCC;
	margin:12px 0 12px 0;
	height:100%;
	padding:55px 13px 66px 13px;
	direction:rtl;
}

.brief_info_box h4 {
	font-size:26px;
	line-height:24px;
	color:#000;
	border-bottom:1px solid #CCC;
}

.brief_info_box section {
	border-bottom:1px solid #CCC;
	font-size:25px;
	margin:0 0 13px 0;
	padding:0 4px 0 0;
}

.brief_info_box section:last-child {
	margin:0;
}

.brief_info_box a {
	color:#0D83DD;
}

.business_activity_graph {
	float:right;
	width:100%;
	width:628px;
	height:280px;
	/*
	margin:0 0 0 -230px;
	padding:30px 20px 0 250px;
	*/
	padding:30px 20px 0 20px;
}

.nc_name {
	float:right;
}

.nc_name a {
	color:#000;
	text-decoration:underline;
}

.nc_name a:hover {
	color:#00F;
}

.right_side_menu .rsb_icon_10:before {
	background-position:0 -1000px;
}

.right_side_menu .rsb_icon_11:before {
	background-position:0 -1100px;
}

.right_side_menu .rsb_icon_12:before {
	background-position:0 -1200px;
}

.right_side_menu .rsb_icon_13:before {
	background-position:0 -1300px;
}

.right_side_menu .rsb_icon_14:before {
	background-position:0 -1400px;
}

.right_side_menu .rsb_icon_15:before {
	background-position:0 -1500px;
}







.custom_checkbox_1 {
	display:inline-block;
	position:relative;
	min-height:19px;
	min-width:19px;
	vertical-align:middle;
	padding:0 19px 0 0;
	cursor:pointer;
}

.custom_checkbox_1 input {
	display:block;
	margin:0;
	padding:0;
	position:absolute;
	display:block;
	width:19px;
	height:19px;
	z-index:10;
	right:0;
	top:50%;
	opacity:0;
	margin:-10px 0 0 0;
}



.custom_checkbox_1 b {
	display:block;
	position:absolute;
	width:19px;
	height:19px;
	border:1px solid #B2B2B2;
	background:#FFF;
	float:right;
	right:0;
	top:50%;
	margin:-10px 0 0 0;
	z-index:1;
}

.custom_checkbox_1 b:before {
	content:'';
	display:block;
	width:19px;
	height:19px;
	top:-1px;
	left:-1px;
	position:absolute;
	background:url(../../img/ico_checkbox_1.png) 0 19px no-repeat;
}

.custom_checkbox_1 input:checked + b:before {
	background-position:0 0;
}

.custom_checkbox_1 span {
	float:right;
	margin:0 5px 0 0;
}



.custom_radio_1 {
	display:inline-block;
	position:relative;
	min-height:19px;
	min-width:19px;
	vertical-align:middle;
	padding:0 19px 0 0;
	cursor:pointer;
}
.custom_radio_1 input {
	margin:0;
	padding:0;
	position:absolute;
	display:none;
	width:16px;
	height:16px;
	z-index:10;
	right:0;
	top:50%;
	opacity:0;
	margin:-10px 0 0 0;
}

.custom_radio_1 b {
	display:none;
}

.custom_radio_1 b {
	display:block;
	position:absolute;
	width:16px;
	height:16px;
	border-radius:16px;
	border:1px solid #B2B2B2;
	background:#FFF;
	float:right;
	right:0;
	top:50%;
	margin:-10px 0 0 0;
	z-index:1;
    tab-index: 0;
}

.custom_radio_1 b:focus{
    border: 1px solid black;
}
.custom_radio_1 b:before {
	content:'';
	display:block;
	width:10px;
	height:10px;
	border-radius:14px;
	top:2px;
	left:2px;
	position:absolute;
	background:#FFF;
}

.custom_radio_1 input:checked + b:before {
	background:#000;
}

.custom_radio_1 span {
	float:right;
	margin:0 5px 0 0;
}




#content .std_block_3:first-child {
	margin-top:67px;
}

.std_block_3 {
	border:1px solid #CCC;
	border-radius:9px;
	margin:0 0 41px 0;
	position:relative;
	min-height:100px;
}

.std_block_3_header {
	border-bottom:1px solid #CCC;
	background:#FFFF99;
	border-radius:8px 8px 0 0;
	padding:18px 22px 18px 22px;
}

.std_block_3_header h2 {
	font-size:35px;
	line-height:49px;
	color:#000;
}

.std_block_3_content {
	min-height:100px;
	background:#D9FFB2;
	font-size:20px;
	color:#3A3A3A;
}

.std_block_3_content:before, .std_block_3_content:after {
	content:'';
	display:table;
	clear:both;
}

.std_block_3_footer {
	border-radius:0 0 8px 8px;
	border-top:1px solid #E0DD89;
	background:#FFFF99;
	padding:20px 25px 17px 16px;
	font-size:20px;
	color:#3C3C3B;
}

.std_block_3_footer:after {
	content:'';
	display:table;
	clear:both;
}

.std_block_3_footer_note {
	float:right;
	line-height:36px;
}

.std_block_3_buttons_box {
	float:left;
}

.std_block_3_buttons_box [class*="the_button"] {
	height:36px;
	font-size:26px;
	line-height:36px;
	border-width:1px;
}

.save_changes {
	float:right;
}

.std_block_3_buttons_box .save_or_cancel {
	float:right;
	line-height:24px;
	margin:7px 15px 0 5px;
}

.std_block_3_buttons_box .cancel {
	width:auto;
	height:auto;
	border:none;
	background:none;
	font-weight:normal;
	color:#00F;
	padding:0;
	margin:7px 0 0 0;
	float:right;
	font-size:20px;
	line-height:24px;
}

.std_block_3_buttons_box .cancel:hover {
	text-decoration:underline;
}

.change_pwd_form_box {
	margin:30px auto 51px auto;
	width:336px;
	color:#3A3A3A;
}

.change_pwd_form_box:after {
	content:'';
	display:table;
	clear:both;
}

.change_pwd_form_box input[type="password"] {
	display:block;
	width:100%;
	margin:0 0 17px 0;
	height:39px;
}

.change_pwd_form_box label {
	display:block;
	line-height:24px;
}

.change_pwd_form_box label b {
	font-weight:normal;
	color:#FF0000;
}

.business_details_box {
	padding:21px 45px 33px 32px;
}

.business_details_box:after {
	content:'';
	display:table;
	clear:both;
}

.business_details_box h3 {
	font-size:24px;
	font-weight:normal;
	padding: 0 0 17px 0;
}

.the_button.bd_add_fields {
	float:left;
	min-width:144px;
	height:38px;
	line-height:38px;
	font-size:22px;
}

.the_button.bd_add_fields:before {
	content:'';
	display:inline-block;
	width:13px;
	height:13px;
	background:url(../../img/ico_plus_16x16.png) center no-repeat;
	margin:0 0 0 12px;

	background-size:contain;
	-moz-background-size:contain;
	-webkit-background-size:contain;
}

.business_details_box .para {
	padding:11px 0 0 0;
	border-top:1px solid #CCC;
	margin:0 0 25px 0;
}

.business_details_box .para:last-child {
	margin-bottom:0;
}

.business_details_box .para_title {
	margin:0 0 35px 0;
}

.business_details_box .para_title:after {
	content:'';
	display:table;
	clear:both;
}

.business_details_box h4 {
	float:right;
	font-size:24px;
	font-weight:normal;
	line-height:36px;
}

.business_details_box .row {
	display:block;
	width:auto;
	margin:0 -6px 10px -6px;
	position:relative;
}

.business_details_box .row:last-child {
	margin-bottom:0;
}

.business_details_box .row:before, .business_details_box .row:after {
	content:'';
	display:table;
	clear:both;
}

.business_details_box .row .col {
	float:right;
	width:25%;
	margin:0 0 12px 0;
	padding:0 6px 0 6px;
}

.row_icon_box {
	position:absolute;
	top:20px;
	left:100%;
	margin:0 0 0 1px;
}

.row_icon_box .home {
	display:block;
	width:27px;
	height:25px;
	background:url(../../img/sprite_edit_profile_icons.png) 0 0 no-repeat;
	margin:0 0 0 0;
}

.row_icon_box .phone {
	display:block;
	width:22px;
	height:33px;
	background:url(../../img/sprite_edit_profile_icons.png) 0 -50px no-repeat;
	margin:0 0 0 0;
}



.row_ctrls_box {
	position:absolute;
	right:100%;
	top:20px;
	direction:ltr;
	margin:0 1px 0 0;
}

.row_ctrls_box .delete {
	display:block;
	width:15px;
	height:15px;
	background:url(../../img/ico_delete_2.png);
	margin:10px 0 0 0;
}

.row_ctrls_box .delete:hover {
	opacity:0.8;
}

.business_details_box .col input, .business_details_box .col select {
	width:100%;
	border:1px solid #CCC;
	border-radius:3px;
	height:35px;
	font-size:20px;
}

.business_details_box .col .custom_select_type_3 {
	width:100%;
	display:block;
}

.business_details_box .col label {
	display:block;
	line-height:20px;
}

.business_details_box .col [lang="en"] {
	direction:ltr;
	font-size:18px;
}



.lang_options_box {
	padding:20px 42px 32px 30px;
}

.lang_options_box h3 {
	font-size:24px;
	line-height:35px;
	font-weight:normal;
	margin:0 0 20px 0;
}

.lang_options_box h3 > span {
	display:block;
}

.lang_options_box h3 b {
	font-weight:bold;
}

.lang_options_box .row {
	width:100%;
	position:relative;
	clear:both;
	margin:0 0 10px 0;
}

.lang_options_box .row:after {
	content:'';
	display:table;
	clear:both;
}

.lang_options_box .row .col {
	float:right;
	width:50%;
}

.lang_options_box .row .col:first-child, .lang_options_flag_box + .col {
	padding:0 0 0 6px;
}

.lang_options_box .row .col + .col {
	padding:0 6px 0 0;
}

.lang_options_box .col label {
	display:block;
	line-height:20px;
}

.lang_options_box .col [lang="en"] {
	direction:ltr;
	font-size:18px;
}

.controls_list_1 {
	display:block;
	margin:0 0 25px 0;
}

.controls_list_1:after {
	content:'';
	display:table;
	clear:both;
}

.controls_list_1 > li {
	float:right;
	min-width:118px;
}

.lang_options_box .col input, .lang_options_box .col select {
	width:100%;
	border:1px solid #CCC;
	border-radius:3px;
	height:35px;
	font-size:20px;
}

.lang_options_flag_box {
	position:absolute;
	top:20px;
	left:100%;
	margin:0 0 0 10px;
}

.lang_options_flag_box .hebrew {
	display:block;
	width:23px;
	height:21px;
	margin:6px 0 0 0;
	background:url(../../img/sprite_lang_flags.png) 0 0 no-repeat;
}

.lang_options_flag_box .english {
	display:block;
	width:23px;
	height:21px;
	margin:6px 0 0 0;
	background:url(../../img/sprite_lang_flags.png) 0 -50px no-repeat;
}

.documents_list_box {
	padding:20px 0 20px 0;
}

.documents_list_box li {
	display:block;
	padding:5px 65px 5px 30px;
}

.documents_list_box li:hover {
	background:#FFF;
}

.documents_list_box h5 {
	font-weight:normal;
	float:right;
	font-size:23px;
	line-height:28px;
	width:230px;
	padding:3px 0 1px 0;
	position:relative;
}

.documents_list_box h5:before {
	content:'';
	display:none;
	position:absolute;
	width:5px;
	height:11px;
	right:-20px;
	top:50%;
	margin:-7px 0 0 0;
	background:url(../../img/ico_documets_list_item.png);
}

.documents_list_box input[type] {
	display:block;
	border:1px solid #D9FFB2;
	border-radius:3px;
	width:113px;
	height:32px;
	background:#D9FFB2;
	text-align:center;
	font-size:23px;
}

.documents_list_box input[type]:focus {
	border-color:#706F6F;
	background:#FFF8D5;
}

.documents_list_box li:hover h5:before {
	display:block;
}

.documents_list_box li:hover input[type] {
	border-color:#706F6F;
	background:#FFF8D5;
}


.external_software, .custom_select_type_1.external_software {
	width:188px;
	display:block;
	/*
	height:27px;
	font-size:20px;
	margin:3px 0 3px 0;
	*/
}

.custom_radio_1.with_note span {
	position:relative;
}

.custom_radio_1.with_note small {
	display:block;
	position:absolute;
	bottom:-12px;
	right:0;
	font-size:14px;
	white-space:nowrap;
	line-height:16px;
}

.settings_input_1 {
	width:132px;
}


.document_design_format_box {
	padding:20px 32px 32px 18px;
}

.document_design_format_box .para {
	border-bottom:1px solid #CCCCCC;
	padding:0 0 18px 0;
	margin:0 0 18px 0;
}

.document_design_format_box .para:last-child {
	margin:0;
	border:none;
}

.document_design_format_box h3 {
	font-size:26px;
	float:right;
	clear:right;
}

.text_description {
	clear:right;
	float:right;
	width:400px;
	font-size:17px;
	line-height:24px;
	margin:0 0 20px 0;
}

.document_design_format_box section {
	display:block;
	clear:both;
	width:auto;
	margin:0 -13px 0 0;
	background:#FFF;
	border-radius:4px;
	padding:5px 0 5px 0;
	font-size:22px;
}

.general_view {
	display:block;
	text-align:center;
	font-size:0;
}

.general_view li {
	display:inline-block;
	text-align:right;
	font-weight:normal;
	width:119px;
	padding:10px 0 10px 0;
}

.general_view li + li {
	margin:0 77px 0 0;
}

.general_view li h4 {
	font-weight:normal;
	font-size:16px;
}

.genereal_view_img_box {
	width:119px;
	height:72px;
	background:#EDEDED;
	margin:0 0 10px 0;
}

.genereal_view_img_box img {
	max-width:100%;
	max-height:100%;
	display:block;
	margin:auto;
}

.general_view li .the_button_2 {
	display:block;
	margin:auto;
	font-size:14px;
	font-weight:normal;
	height:24px;
	line-height:22px;
	width:93px;
}



.the_button.add_comment {
	float:left;
	margin:0 0 0 18px;
	font-size:22px;
	width:130px;
	height:38px;
	line-height:36px;
}

.the_button.add_comment:before {
	content:'';
	display:inline-block;
	width:15px;
	height:15px;
	background:url(../../img/ico_plus_16x16.png) center no-repeat;
	margin:0px 0px -1px 8px;

	background-size:contain;
	-moz-background-size:contain;
	-webkit-background-size:contain;
}

.document_design_format_box section table {
	width:100%;
}

.document_design_format_box section td {
	border-bottom:1px solid #CCC;
	height:41px;
	vertical-align:middle;
	padding:0 41px 0 0;
}

.document_design_format_box section tbody tr:last-child td {
	border:none;
}

.document_design_format_box section thead td {
	font-weight:bold;
}

.document_design_format_box section tbody tr:nth-of-type(odd) td {
	background:#EDEDED;
}

.doc_design_gallery_box {
	padding:0 10px 0 31px;
}

.doc_design_gallery_box:after {
	content:'';
	display:table;
	clear:both;
}

.doc_design_gallery_right {
	margin:45px 0 0 0;
	float:right;
	width:286px;
	height:396px;
	overflow-x:hidden;
	overflow-y:auto;
	direction:ltr;
}

.doc_design_previews_list {
	display:block;
	float:left;
	width:270px;
}

.doc_design_previews_list li {
	float:right;
	width:111px;
	height:140px;
	margin:0 24px 20px 0;
	background:#EDEDED;
}
/*
.doc_design_previews_list li:nth-of-type(even) {
	margin-right:24px;
}
*/
.doc_design_previews_list li > a {
	display:table;
	border:1px solid #CCC;
	width:111px;
	height:140px;
	overflow:hidden;
}

.doc_design_previews_list li a:hover {
	border-color:#999;
}

.doc_design_previews_list li > a span {
	display:table-cell;
	width:100%;
	height:100%;
	vertical-align:middle;
}

.doc_design_previews_list li img {
	display:block;
	max-width:109px;
	max-height:138px;
	height:auto;
	margin:auto;
	position:relative;
	/*
	top:50%;

	transform:translateY(-50%);
	-moz-transform:translateY(-50%);
	-webkit-transform:translateY(-50%);
	*/
}

.doc_design_gallery_left {
	width:310px;
	float:left;
	text-align:center;
}

.doc_design_gallery_left .the_button {
	height:32px;
	width:119px;
	font-size:17px;
	font-weight:normal;
	line-height:30px;
	margin:0 3px 13px 3px;
}

.doc_design_gallery_left .the_button.delete {
	background:#F90D0D;
	border-color:#D10909;
}

.doc_design_gallery_left .the_button.delete:before {
	content:'';
	display:inline-block;
	width:18px;
	height:18px;
	background:url(../../img/sprite_iconset_1.png) 0 -50px no-repeat;
	margin:-3px 0 -3px 5px;
}

.doc_design_gallery_left .the_button.delete:hover {
	background:#D10909;
}

.doc_design_gallery_img_box {
	width:310px;
	height:378px;
	border:1px solid #CCC;
	background:#FFF;
	position:relative;
	overflow:hidden;
}

.doc_design_gallery_img_box > div {
	display:table;
	width:100%;
	height:376px;
	position:relative;
}

.doc_design_gallery_img_box > div div {
	display:table-cell;
	width:100%;
	height:100%;
	vertical-align:middle;
}

.doc_design_gallery_img_box img {
	max-width:284px;
	display:block;
	margin:auto;
	position:relative;

	/*
	top:50%;

	transform:translateY(-50%);
	-moz-transform:translateY(-50%);
	-webkit-transform:translateY(-50%);
	*/
}



.the_blue_popup_2 {
	background:#FFF;
	border:8px solid #ddecfc;
	border-left-width:6px;
	border-radius:12px;
	padding:15px 32px 0px 41px;
	box-shadow:3px 3px 0 0 rgba(0, 0, 0, 0.5);
	margin:20px;
	width:616px;
	min-height:185px;
	text-align:left;
	position:relative;
}

.the_blue_popup_2:before {
	content:'';
	display:block;
	position:absolute;
	width:0;
	height:0;
	border:30px solid transparent;
	border-top-color:#DDEDFD;
	border-right-color:#DDEDFD;
	top:0;
	right:0;
}

.the_blue_popup_close {
	display:block;
	position:absolute;
	top:3px;
	right:2px;
	width:22px;
	height:22px;
	border-radius:22px;
	background:#6EB5EB url(../../img/ico_popup_close.png) 0 0 no-repeat;
}

.the_blue_popup_close:hover {
	background-position:0 -50px;
}

.popup_save_changes_box {
    position: relative;
    padding: 42px 192px 0 41px;
    min-height: 136px;
    background: url(../../img/img_blue_box_img_3.png) 97% top no-repeat;
}

.popup_save_changes_box h4 {
	font-size:36px;
	line-height:44px;
	color:#0D83DD;
	margin:0 0 18px 0;
}


.save_changes_buttons_box {
	font-size:20px;
	float:left;
	color:#3A3A3A;
}

.save_changes_buttons_box span {
	float:right;
	margin:0 7px 0 7px;
}

.save_changes_buttons_box .the_button, .save_changes_buttons_box .the_button_2 {
	font-size:23px;
	line-height:28px;
	height:29px;
	font-weight:bold;
	float:right;
}

.save_changes_buttons_box .cancel {
	width:auto;
	height:auto;
	border:none;
	background:none;
	font-weight:normal;
	color:#00F;
	padding:0;
	font-size:20px;
	line-height:29px;
}

.save_changes_buttons_box .cancel:hover {
	text-decoration:underline;
}

.export_settings_box {
	padding:20px 17px 20px 20px;
}

.export_settings_box:after {
	content:'';
	display:table;
	clear:both;
}

.export_sett_tabs_note {
	font-size:17px;
	color:#F90D0D;
	float:right;
	margin:10px 0 0 0;
}

.tabs_type_2_tabs_box {
	border:1px solid #CCC;
	border-radius:5px 0 5px 5px;
	min-height:100px;
	background:#FFF;
	padding:40px 33px 11px 20px;
}

.export_settings h3 {
	float:right;
	width:280px;
	font-size:22px;
	font-weight:bold;
}

.export_settings h3 + h3 {
	width:113px;
	text-align:center;
}

.export_sett_list {
	padding:20px 0 20px 0;
}

.export_sett_list:before {
	content:'';
	display:table;
	clear:both;
}

.export_sett_list + .export_sett_list li:first-child {
	border-top:1px solid #CCC;
}

.export_sett_list:last-child li:last-child {
	border-bottom:none;
}

.export_sett_list li {
	display:block;
	padding:8px 0 8px 0;
	border-bottom:1px solid #CCCCCC;
}

.export_sett_list li:hover {
	background:#FFF;
}

.export_sett_list h4 {
	font-weight:normal;
	float:right;
	font-size:22px;
	line-height:31px;
	width:280px;
	padding:3px 0 1px 0;
	position:relative;
}

.export_sett_list h4 b {
	color:#F90D0D;
}

.export_sett_list input[type] {
	display:block;
	border:1px solid #706F6F;
	border-radius:3px;
	width:113px;
	height:31px;
	background:#F6F6F6;
	text-align:center;
	font-size:23px;
}

.export_sett_list input[type]:hover, .export_sett_list input[type]:focus {
	border-color:#706F6F;
	background:#FFF8D5;
}




.custom_select_type_1 {
	height:33px;
	width:auto;
	max-width:130px;
	min-width:130px;
	display:inline-block;
}

.custom_select_type_1 .selectboxit-container {
	width:100%;
}

.custom_select_type_1 .selectboxit-container .selectboxit {
	display:block;
	border:2px inset #e5e5e5;
	border-radius:0;
	background-color:#F6F6F6;
	width:100%;
	padding:0 7px 0 40px;
	height:33px;
	font:24px/1.4 'fahrenheit', Arial, Helvetica, sans-serif;
  	color:#575757;
}

.custom_select_type_1 .selectboxit-container .selectboxit.selectboxit-focus {
	background:#FFF;
}

.custom_select_type_1 .selectboxit .selectboxit-option-icon-container {
	display:none;
}

.custom_select_type_1 .selectboxit-text {
	text-indent:0;
	height:100%;
	width:100%;
	float:right;

	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
	text-align:right;
	line-height:33px;
}

.custom_select_type_1 .selectboxit-arrow-container {
	display:block;
	position:absolute;
	top:0;
	left:0;
	right:auto;
	height:100%;
	width:36px;
}

.custom_select_type_1 .selectboxit .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow {
	width:0;
	height:0;
	border:9px solid transparent;
	border-top:9px solid #dadada;
	margin:-4px auto 0 auto;
	top:50%;
}

.custom_select_type_1 .selectboxit-container .selectboxit-options {
	border:2px solid #CCCCCC;
	text-align:right;
	color:#575757;
	font-size:24px;
	line-height:33px;
	font-weight:normal;
}

.custom_select_type_1 .selectboxit-option {
	text-indent:0;
}

.custom_select_type_1 .selectboxit-list .selectboxit-option-anchor {
	color:#575757;
	padding-left:5px;
	padding-right:5px;
}

.custom_select_type_1 .selectboxit-list .selectboxit-focus .selectboxit-option-anchor,
.custom_select_type_1 .selectboxit-list .selectboxit-selected .selectboxit-option-anchor {
	color:#ffffff;
	background:#0d83dd;
}




.small_tbl .custom_select_type_1 {
	height:18px;
}

.small_tbl .custom_select_type_1 .selectboxit-container .selectboxit {
	border-width:1px;
	font-size:14px;
	height:18px;
	padding:0 5px 0 22px;
}

.small_tbl .custom_select_type_1 .selectboxit-text {
	line-height:19px;
}

.small_tbl .custom_select_type_1 .selectboxit-arrow-container {
	width:22px;
}

.small_tbl .custom_select_type_1 .selectboxit .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow {
	border-width:7px;
	margin-top:-3px;
}

.small_tbl .custom_select_type_1 .selectboxit-container .selectboxit-options {
	border-width:1px;
	font-size:14px;
	line-height:20px;
}

.small_tbl .custom_select_type_1 .selectboxit-container .selectboxit-options a {
	height:26px;
	line-height:26px;
}




.custom_select_type_2 {
	height:38px;
	width:auto;
	max-width:300px;
	min-width:100px;
	display:inline-block;
}

.custom_select_type_2 .selectboxit-container {
	width:100%;
}

.custom_select_type_2 .selectboxit-container .selectboxit {
	display:block;
	border:1px solid #0D83DD;
	border-radius:4px;
	background:#6EB5EB;
	width:100%;
	padding:0 7px 0 40px;
	height:38px;
	font:22px/40px 'fahrenheit', Arial, Helvetica, sans-serif;
  	color:#FFFFFF;
}

.custom_select_type_2 .selectboxit-container .selectboxit.selectboxit-focus {
	background:#0D83DD;
}

.custom_select_type_2 .selectboxit-container .selectboxit.selectboxit-open {
	border-radius:4px 4px 0 0;
}

.custom_select_type_2 .selectboxit .selectboxit-option-icon-container {
	display:none;
}

.custom_select_type_2 .selectboxit-text {
	text-indent:0;
	height:100%;
	width:100%;
	float:right;

	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
	text-align:right;
	line-height:40px;
}

.custom_select_type_2 .selectboxit-arrow-container {
	display:block;
	position:absolute;
	top:0;
	left:0;
	right:auto;
	height:100%;
	width:36px;
}

.custom_select_type_2 .selectboxit .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow {
	width:0;
	height:0;
	border:9px solid transparent;
	border-top:9px solid #0969b0;
	margin:15px auto 0 auto;
	top:0;
}

.custom_select_type_2 .selectboxit.selectboxit-focus .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow {
	border-top-color:#FFF;
}

.custom_select_type_2 .selectboxit-container .selectboxit-options {
	border:1px solid #0D83DD;
	text-align:right;
	color:#FFFFFF;
	background:#6EB5EB;
	font-size:24px;
	line-height:33px;
	border-radius:0 0 4px 4px;
}

.custom_select_type_2 .selectboxit-option {
	text-indent:0;
}

.custom_select_type_2 .selectboxit-list .selectboxit-option-anchor {
	color:#FFFFFF;
	padding-left:5px;
	padding-right:5px;
}

.custom_select_type_2 .selectboxit-list .selectboxit-focus .selectboxit-option-anchor {
	color:#ffffff;
	background:#0d83dd;
}



.custom_select_type_3 {
	height:35px;
	width:auto;
	max-width:300px;
	min-width:100px;
	display:inline-block;
}

.custom_select_type_3 .selectboxit-container {
	width:100%;
}

.custom_select_type_3 .selectboxit-container .selectboxit {
	display:block;
	border:1px solid #CCCCCC;
	border-radius:2px;
	background:#FFFFFF;
	width:100%;
	padding:0 7px 0 40px;
	height:35px;
	font:20px/37px 'fahrenheit', Arial, Helvetica, sans-serif;
  	color:#575757;
}

.custom_select_type_3 .selectboxit-container span {
	height:35px;
	line-height:37px;
}

.custom_select_type_3 .selectboxit-container .selectboxit.selectboxit-focus {
	background:#FFF8D5;
	border-color:#FFB600;
}

.custom_select_type_3 .selectboxit-container .selectboxit.selectboxit-open {

}

.custom_select_type_3 .selectboxit .selectboxit-option-icon-container {
	display:none;
}

.custom_select_type_3 .selectboxit-text {
	text-indent:0;
	height:100%;
	width:100%;
	float:right;

	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
	text-align:right;
	line-height:36px;
}

.custom_select_type_3 .selectboxit-arrow-container {
	display:block;
	position:absolute;
	top:0;
	left:0;
	right:auto;
	height:100%;
	width:36px;
}

.custom_select_type_3 .selectboxit .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow {
	width:0;
	height:0;
	border:6px solid transparent;
	border-top:6px solid #DADADA;
	margin:15px auto 0 auto;
	top:0;
}

.custom_select_type_3 .selectboxit.selectboxit-focus .selectboxit-arrow-container .selectboxit-arrow.selectboxit-default-arrow {
	border-top-color:#FFB600;
}

.custom_select_type_3 .selectboxit-container .selectboxit-options {
	border:1px solid #CCCCCC;
	text-align:right;
	color:#575757;
	background:#FFFFFF;
	font-size:20px;
	line-height:33px;
	border-radius:0 0 4px 4px;
	right:100%;
	margin-top:-35px;
}

.custom_select_type_3 .selectboxit-container.selectboxit-bottom-top .selectboxit-options {
	margin-top:35px;
}

.custom_select_type_3 .selectboxit-option {
	text-indent:0;
}

.custom_select_type_3 .selectboxit-list .selectboxit-option-anchor {
	color:#575757;
	padding-left:5px;
	padding-right:5px;
}

.custom_select_type_3 .selectboxit-list .selectboxit-focus .selectboxit-option-anchor {
	color:#ffffff;
	background:#0d83dd;
}

.custom_select_type_3[lang="en"] .selectboxit-container .selectboxit {
	font:18px/40px 'Myriad Pro', Arial, Helvetica, sans-serif;
	padding:0 40px 0 7px;
}

.custom_select_type_3[lang="en"] .selectboxit-text {
	text-align:left;
}

.custom_select_type_3[lang="en"] .selectboxit-arrow-container {
	left:auto;
	right:0;
}

.custom_select_type_3[lang="en"] .selectboxit-container .selectboxit-options {
	left:100%;
	right:auto;
	font-size:18px;
	text-align:left;
}

.custom_select_type_3 .selectboxit-list .selectboxit-focus .selectboxit-option-anchor .add_more {
	color:#575757;
}

.selectboxit-options .add_more {
	display:block;
	background:#EDEDED;
	padding:0 23px 0 5px;
	margin:0 -5px 0 -5px;
	position:relative;
	font-weight:bold;
	height:30px;
	line-height:32px;
}

.selectboxit-options .add_more:before {
	content:'';
	display:block;
	position:absolute;
	width:14px;
	height:14px;
	border:1px solid #659C17;
	background:#85C51F url(../../img/ico_dd_plus.png) center no-repeat;
	border-radius:14px;
	right:5px;
	top:50%;
	margin-top:-7px;
}

.selectboxit-options *:hover > .add_more, .selectboxit-focus .selectboxit-options .add_more {
	color:#0000FF;
}


.custom_select_type_3.business_details_email .selectboxit-container .selectboxit {
	font-size:16px;
	font-family:'Myriad Pro', Arial, Helvetica, sans-serif;
}

.custom_select_type_3.business_details_email .selectboxit-options {
	font-size:16px;
	font-family:'Myriad Pro', Arial, Helvetica, sans-serif;
}



.choose_plan_tbl td, .choose_plan_tbl th {
	border:1px solid #CCC;
}

.choose_plan_tbl td:hover {
	/*background:#F7FBFF;*/
}

.choose_plan_tbl thead th {
	text-align:center;
	color:#FFF;
	background:#0D83DD;
	padding:16px 17px 27px 17px;
	font-size:27px;
	font-weight:normal;
}

.choose_plan_tbl_title {
	white-space:nowrap;
	display:block;
	margin:10px -6px 0 0;
	font-weight:bold;
	font-size:24px;
}

.choose_plan_tbl th > small {
	font-size:20px;
	line-height:24px;
	display:block;
	margin:0 0 2px 0;
}

.plan_name_box {
	border:2px solid #0969b0;
	border-radius:4px;
	background:#f7faff;
	height:59px;
	width:174px;
	margin:0 0 5px 0;
	overflow:hidden;
}

.choose_plan_tbl th strong {
	font-size:50px;
	line-height:50px;
	display:block;
	margin:-2px 0 0 0;
}

.choose_plan_tbl th strong small {
	font-size:25px;
	line-height:25px;
}

.choose_plan_tbl th span {
	line-height:27px;
	display:block;
}

.the_button.choose {
	display:block;
	width:100px;
	height:36px;
	margin:auto;
	font-size:28px;
	line-height:39px;
	border:none;
}

.choose_plan_tbl tbody th {
	text-align:right;
	padding:14px;
	vertical-align:middle;
	font-size:21px;
	line-height:22px;
	height:72px;
}

.choose_plan_tbl tbody td {
	text-align:center;
	padding:14px 31px 14px 31px;
	font-size:23px;
	line-height:22px;
	vertical-align:middle;
}

.choose_plan_tbl tbody .text_right {
	text-align:right;
}

.plan_check_marker {
	display:block;
	width:33px;
	height:27px;
	background:url(../../img/ico_check_ok.png);
	margin:auto;
}

.plan_check_marker + small {
	padding:5px 0 0 0;
	margin:0 0 -5px 0;
}

.choose_plan_tbl tbody td small {
	display:inline-block;
	font-size:15px;
	line-height:15px;
	margin:0 -20px 0 -20px;
	display:block;
	white-space:nowrap;
}

.choose_plan_tbl tfoot td {
	padding:19px;
}

.payment_info_box {
	padding:23px 17px 22px 17px;
	font-size:23px;

    .row_content {
        color: #9d9d9b;

        input {
            padding: 0 5px;
            color: inherit;
        }

        .custom_select_type_1 {
            color: inherit;
        }
    }
}

.tabs_type_2_tabs.payment_info {
	text-align:center;
	font-size:0;
}

.tabs_type_2_tabs_box.payment_info:after, .tabs_type_2_tabs_box.payment_info > div:after {
	content:'';
	display:table;
	clear:both;
}

.tabs_type_2_tabs.payment_info a {
	display:inline-block;
	float:none;
}

.tabs_type_2_tabs_box.payment_info {
	border-radius:5px;
	padding:45px 24px 34px 36px;
}

.payment_setting_right {
	float:right;
    width: 352px;
}

.payment_info .row {
	margin:0 0 15px 0;
}

.payment_info .row:after {
	content:'';
	display:table;
	clear:both;
}

.payment_info .row_title {
	float:right;
	width:93px;
}

.payment_info .row_content {
	float:left;
	width:260px;
}

.payment_info .row input[type="text"], .payment_info .row select {
	display:block;
	width:100%;
	height:36px;
	border:1px solid #CCC;
	background-color:#F6F6F6;
}

.payment_info .row select {
	width:164px;
}

.payment_info .row .custom_select_type_1 .selectboxit-container .selectboxit {
	border:1px solid #CCC;
	background-color:#F6F6F6;
	height:36px;
}

.payment_info .row .custom_select_type_1 .selectboxit-text {
	 line-height:36px;
}

.payment_info .row .custom_select_type_1 .selectboxit-container .selectboxit-options {
	border-width:1px;
}

.payment_info .row .card_info select {
	float:right;
	width:69px;
	margin:0 0 0 9px;
}

.payment_info .row .card_info select:first-child {
	margin:0 0 0 7px;
}

.payment_info .row .card_info .custom_select_type_1 {
	float:right;
	width:60px;
	min-width:60px;
	margin:0 0 0 14px;
}

.payment_info .row .card_info .custom_select_type_1 .selectboxit-container .selectboxit {
	font-size:23px;
	padding:0 3px 0 18px;
}

.payment_info .row .card_info .custom_select_type_1 .selectboxit-arrow-container {
	width:23px;
}


.payment_info .row .card_info input {
	float:left;
	width:61px;
}

.payment_info .row .card_info > span {
	float:right;
	line-height:36px;
}

.payment_setting_left {
	border:1px solid #CCC;
	background:#F6F6F6;
	float:left;
	width:238px;
	padding:11px 17px 12px 17px;
	font-size:20px;
}

.payment_setting_left table {
	width:100%;
}

.payment_setting_left table th {
	text-align:right;
	color:#000000;
	height:41px;
	vertical-align:middle;
    font-size: 17px;
}

.payment_setting_left table td {
	text-align:left;
	color:#0D83DD;
	font-weight:bold;
	height:41px;
	vertical-align:middle;
    white-space: nowrap;
    font-size: 17px;
}


.sort_link {
	float:left;
	clear:both;
	margin:3px 0 0 8px;
	text-decoration:underline;
	font-size:23px;
}

.sort_link:hover {
	text-decoration:none;
}

.genereate_report_container {
	padding:17px 20px 15px 20px;
}

.tabs_type_3_tabs:after {
	content:'';
	display:table;
	clear:both;
}

.tabs_type_3_tabs a {
	display:block;
	float:right;
	height:43px;
	line-height:45px;
	font-size:28px;
	min-width:144px;
	text-align:center;
	padding:0 20px 0 20px;
}

.tabs_type_3_tabs .active {
	background:#F6F6F6;
	color:#575756;
	font-weight:bold;
}

.tabs_type_3_tabs a:hover {
	background:#F6F6F6;
	text-decoration:none;
}

.tabs_type_3_tabs_box {
	background:#F6F6F6;
	padding:46px 28px 19px 30px;
}

.generate_report_form .row {
	margin:0 0 28px 0;
}

.generate_report_form .row:after {
	content:'';
	display:table;
	clear:both;
}

.generate_report_form .row_title {
	float:right;
	width:91px;
}

.generate_report_form .row_content {
	float:left;
	width:100%;
	padding:0 91px 0 0;
	margin:0 -91px 0 0;
}

.generate_report_form select {
	float:right;
	margin:0 0 0 28px;
	width:275px;
}

.generate_report_form .custom_select_type_1, .generate_report_form .custom_select_type_4_box {
	float:right;
	margin:0 0 0 28px;
	width:275px;
}


/*
.selectize-control {
	position:relative;
	border:2px inset #e5e5e5;
	height:33px;
	width:100%;
}

.custom_select_type_4 .selectize-input,
.custom_select_type_4.selectize-control.multi .selectize-input.has-items {
	display:block;
	border-radius:0;
	padding:0;
	border:0;
	position:absolute;
	top:0;
	left:0;
	background:none;
	border-left:40px solid transparent;
	border-right:7px solid transparent;
	height:29px;
	overflow:hidden;
	text-overflow:ellipsis;
	font:24px/29px 'fahrenheit', Arial, Helvetica, sans-serif;
	color:#575757;
	white-space:nowrap;
	box-shadow:none;
}

.custom_select_type_4.selectize-control.multi .selectize-input > div[data-value] {
	display:inline-block;
	padding:0;
	margin:0 0 0 5px;
	background:none;
	border:none;
	color:#575757;
	text-shadow:none;
	border-radius:0;
	box-shadow:none;
	vertical-align:middle;
	position:relative;
	z-index:-1;
}

.selectize-control.custom_select_type_4:before {
	content:'';
	display:block;
	position:absolute;
	width:0;
	height:0;
	border:9px solid transparent;
	border-top:9px solid #dadada;
	margin:-4px 9px 0 9px;
	top:50%;
	left:0;
	z-index:10;
}

.custom_select_type_4 .items:before, .custom_select_type_4 .items:after {
	display:none;
}

.custom_select_type_4.selectize-dropdown {
	top:100%;
	width:100%;

	right:100%;
	border:1px solid #CCCCCC;
	margin-top:-31px;
	margin-right:2px;
	min-width:150px;
	color:#575757;
	font-size:24px;
	line-height:33px;
	font-weight:normal;
	box-shadow:none;
}

.custom_select_type_4.selectize-dropdown .option.selected {
	color:#ffffff;
	background:#0d83dd;
}
*/



.generate_report_form .custom_select_type_1 .selectboxit-container .selectboxit {
	background-color:#FFF;
}

.generate_report_form .custom_select_type_1 .selectboxit-container .selectboxit-options {
	right:100%;
	border-width:1px;
	margin-top:-33px;
	min-width:150px;
}

.generate_report_form .custom_select_type_1 .selectboxit-container.selectboxit-bottom-top .selectboxit-options {
	margin-top:33px;

}

.generate_report_form .tbl_time_range {
	width:225px;
	float:left;
}

.generate_report_form .tbl_time_range input {
	display:block;
	font-size:18px;
	text-align:center;
}

.generate_report_form .the_button[type="submit"] {
	display:block;
	width:250px;
	height:40px;
	line-height:40px;
	font-size:28px;
	border-width:1px;
	margin:46px auto 0 auto;
}

.std_block_4 {
	border:1px solid #CCC;
	background:#F6F6F6;
	margin:0 0 30px 0;
	padding:16px 15px 16px 15px;
}

.std_block_4:after {
	content:'';
	display:table;
	clear:both;
}


.std_block_4_title {
	margin:0;
}

.std_block_4_title:after {
	content:'';
	display:table;
	clear:both;
}

.std_block_4_title h2 {
	float:right;
	font-size:35px;
	line-height:40px;
	color:#9D9D9C;
}


.std_block_4_buttons_box {
	float:left;
}

.std_block_4_buttons_box .the_button_2 {
	height:30px;
	line-height:30px;
	min-width:78px;
	float:right;
	font-size:19px;
	margin:0 6px 0 0;
}

.std_block_4_buttons_box .the_button_2:before {
	background-image:url(../../img/sprite_iconset_2.png);
}

.std_block_4_buttons_box .the_button_2.email:before {
	background-position:0 0;
	width:22px;
	height:14px;
	margin:0 0 0 5px;
}

.std_block_4_buttons_box .the_button_2.export_data:before {
	background-position:0 -50px;
	width:13px;
	height:18px;
	margin:-3px 0 -2px 5px;
}

.std_block_4_buttons_box .the_button_2.print:before {
	background-position:0 -100px;
	width:22px;
	height:16px;
	margin:-3px 0 3px 5px;
}



.the_table_type_3 {
	font-size:16px;
	width:100%;
}

.oldie .the_table_type_3 {
	float:right;
	width:100%;
	clear:both;
}

.the_table_type_3 thead td {
	font-size:15px;
	font-weight:bold;
	height:39px;
	background:#EDEDED;
	text-align:center;
	vertical-align:middle;
}

.the_table_type_3 thead td {
	border-right:1px solid #FFF;
}

.the_table_type_3 thead td:first-child {
	border:none;
}

.the_table_type_3 tbody td {
	text-align:center;
	height:50px;
	vertical-align:middle;
	padding:0 10px 0 10px;
	background:#FFF;
}

.the_table_type_3 tbody td:empty {
	height:0;
}

.the_table_type_3 tbody tr:nth-of-type(6n-2) td, .the_table_type_3 tbody tr:nth-of-type(6n-1) td, .the_table_type_3 tbody tr:nth-of-type(6n) td {
	background:#F6F6F6;
}

.tbl_summary_title {
    text-align: right;
	font-size:14.29px;
}

.tbl_summary {
	margin:0 11px 0 0;
	float:right;
	font-size:16.33px;
}

.tbl_summary li {
	float:right;
	margin:0 0 0 22px;
}

.tbl_summary li b {
	font-weight:bold;
}

.incomes_tbl_box {
	margin:0 -15px -16px -15px;
}



.the_table_type_4 {
	font-size:13.46px;
	width:100%;
}

.oldie .the_table_type_4 {
	float:right;
	width:100%;
	clear:both;
}

.the_table_type_4 thead td {
	font-size:15px;
	font-weight:bold;
	text-align:center;
	background:#EDEDED;
	border-right:1px solid #DADADA;
	height:25px;
	vertical-align:middle;
}

.the_table_type_4 thead td:first-child {
	border:none;
}

.the_table_type_4 tbody td {
	height:32px;
	vertical-align:middle;
	text-align:center;
	background:#FFF;
    font-size: 13px;
	border-right:1px solid transparent;
}

.the_table_type_4 tbody tr:nth-of-type(even) td {
	background:#F6F6F6;
}



.table_controls_type_3_box.inspections {
	margin-right:92px;
}

.table_controls_type_3_box.inspections .table_controls_type_3:before {
	width:81px;
	height:48px;
}

.table_controls_type_3_box.inspections .table_controls_type_3:after {
	right: -77px;
}

.table_controls_type_3_box.inspections .table_controls_type_3 > span {
	font-size:21px;
	line-height:33px;
	float:right;
	padding:0 22px 0 15px;
}



.inspections_table {
	margin:0 -15px -16px -15px;
}

.inspections_table .tbl_1_checkbox_container {
	width:27px;
}

.inspections_table .custom_checkbox_1 {
	min-height:12px;
	min-width:12px;
	padding:0 12px 0 0;
}

.inspections_table .custom_checkbox_1 input {
	width:12px;
	height:12px;
	margin:-6px 0 0 0;
}


.inspections_table .custom_checkbox_1 b {
	width:12px;
	height:12px;
	border-color:#3C3C3B;
	margin:-6px 0 0 0;
}

.inspections_table .custom_checkbox_1 b:before {
	width:12px;
	height:12px;
	background-size:contain;
	-moz-background-size:contain;
	-webkit-background-size:contain;
}


.tbl_type_5_controls {
	float:left;
	opacity:0;
	visibility:hidden;
	transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	-webkit-transition:all 0.3s ease;
}

tr:hover .tbl_type_5_controls {
	opacity:1;
	visibility:visible;
}

.tbl_type_5_controls a {
	display:block;
	float:right;
	margin:0 5px 0 0;
	border-radius:3px;
	background:#B2B2B2 url(../../img/sprite_tbl_5_controls.png);
	width:34px;
	height:34px;
}

.tbl_type_5_controls a:first-child {
	margin:0;
}

.tbl_type_5_controls a:hover {
	background-color:#CCC;
}

.tbl_type_5_controls .view {background-position:0 0;}

.tbl_type_5_controls .print {background-position:0 -50px;}

.tbl_type_5_controls .export {background-position:0 -100px;}

.tbl_type_5_controls .email {background-position:0 -150px;}

.text_description_1 {
	line-height:33px;
	font-size:21px;
	color:#C6C6C6;
}



.smb_links_help + .big_help_popup {
	position:absolute;
	top:30px;
	right:-226px;
}

.big_help_popup {
	border-radius: 8px;
    border: 6px solid #CCC;
    background: #FFF;
    box-shadow: 3px 3px 0 rgba(0,0,0,.5);
    padding: 33px 28px 21px 28px;
    margin: 53px auto 20px auto;
    position: absolute;
    right: -236px;
    //width: 719px;
    max-width: 500px;
    min-width: 360px;
    top: 26px;
    z-index: 1000;
    text-indent: 0;
    font-size: 16px;

    a {
        color: inherit;
    }
}

.big_help_popup:before {
	content:'';
	display:block;
	position:absolute;
	left:0;
	top:0;
	border:32px solid transparent;
	border-top-color:#CCC;
	border-left-color:#CCC;
	z-index:10;
}

.big_help_popup:after {
	content:'';
	display:block;
	position:absolute;
	right: 214px;
	top:-44px;
	width:62px;
	height:44px;
	background:url(../../img/pic_help_popup_point.png);
	z-index:10;
}

.big_help_popup_content {
	background:#0d83dd;
	padding:35px 30px 30px 30px;
	border-radius:0px 81px 5px 5px;
	color:#FFF;
	position:relative;
	z-index:5;
}

.big_help_popup_content:before {
	content:'';
	display:block;
	position:absolute;
	left:0;
	top:0;
	border:30px solid transparent;
	border-top-color:#FFF;
	border-left-color:#FFF;
}

.big_help_popup_close {
	display:block;
	width:23px;
	height:23px;
	position:absolute;
	left:6px;
	top:6px;
	background:#b3b3b3 url(../../img/ico_help_popup_close.png) 0 0 no-repeat;
	border-radius:23px;
	z-index:20;
}

.big_help_popup_close:hover {
	background-position:0 bottom;
}

.big_help_popup h2 {
	font-size:31px;
	line-height:32px;
	font-weight:bold;
	margin:0 132px 23px 0;
	border-bottom:1px solid #FFF;
	padding:0 0 35px 0;
	min-height:120px;
}

.big_help_popup_title_img {
	display:block;
	position:absolute;
	top:6px;
	right:6px;
	width:147px;
	height:143px;
	background:url(../../img/img_help_popup_title_img.png) right 0 no-repeat;
}

.hp_items_list {
	display:block;
	margin:0 0 -29px 0;
}

.hp_items_list:after {
	content:'';
	display:table;
	clear:both;
}

.hp_items_list li {
	display:block;
	float:right;
	width:50%;
	font-size:17px;
	line-height:17px;
	padding:0 44px 15px 0;
	position:relative;
}

.hp_items_list li:before {
	content:'';
	display:block;
	position:absolute;
	top:0;
	right:0;
	width:36px;
	height:36px;
	border:1px solid #FFF;
	border-radius:36px;
	background:url(../../img/sprite_help_icons.png);
}

.hp_items_list .hp_icon_1:before {
	background-position:0 0;
}

.hp_items_list .hp_icon_2:before {
	background-position:0 -50px;
}

.hp_items_list .hp_icon_3:before {
	background-position:0 -100px;
}

.hp_items_list .hp_icon_4:before {
	background-position:0 -150px;
}

.hp_items_list .hp_icon_5:before {
	background-position:0 -200px;
}

.hp_items_list .hp_icon_6:before {
	background-position:0 -250px;
}

.hp_items_list .hp_icon_7:before {
	background-position:0 -300px;
}

.hp_items_list .hp_icon_8:before {
	background-position:0 -350px;
}

.hp_items_list .hp_icon_9:before {
	background-position:0 -400px;
}


.hp_items_list li h3 {
	font-size:19px;
	line-height:23px;
	margin:0 0 3px 0;
}

.dont_show_help {
	display:block;
	top:-27px;
	left:61px;
	position:absolute;
	font-size:21px;
	line-height:24px;
	color:#575757;
	padding-right:12px;
}

.dont_show_help b {
	width:12px;
	height:12px;
	border-color:#3C3C3C;
	margin:-7px 0 0 0;
}

.dont_show_help input {
	width:12px;
	height:12px;
	border-color:#3C3C3C;
	margin:-7px 0 0 0;
}

.dont_show_help b:before {
	width:12px;
	height:12px;

	background-size:contain;
	-moz-background-size:contain;
	-webkit-background-size:contain;
}


.client_search_box {
	font-size:18px;
	margin-bottom:-31px;
}

.client_search_box > input[type="text"] {
	float:right;
	background:#FFF;
	border:none;
	width:203px;
	height:32px;
	margin:0 0 0 5px;
	font-size:20px;
}

.client_search_box > input[type="submit"] {
	display:block;
	float:right;
	width:32px;
	height:32px;
	border:none;
	background:#9D9D9C url(../../img/ico_search_submit.png);
	text-indent:-9999px;
}

.clients_count {
	clear:both;
	padding:12px 26px 0 0;
	line-height:26px;
	font-size:18px;
	color:#C6C6C6
}

.clients_count b {
	color:#3C3C3C;
	font-weight:bold;
}

.clients_count input {
	float:right;
	width:38px;
	height:26px;
	border:1px solid #CCC;
	font-size:18px;
	text-align:center;
	font-weight:bold;
}

.clients_count span {
	margin:0 8px 0 0;
}

.clients_count_less, .clients_count_more {
	display:block;
	float:right;
	height:26px;
	width:7px;
	margin:0 2px 0 2px;
	overflow:hidden;
}

.clients_count_less:before, .clients_count_more:before {
	content:'';
	display:block;
	width:0;
	height:0;
	border:10px solid transparent;
	margin-top:3px;
}

.clients_count_less:before {
	border-left:7px solid #CCC;
	margin-right:-10px;
}
.clients_count_more:before {
	border-right:7px solid #CCC;
}


.the_table_type_6_controls {
	float:left;
	clear:both;
	padding:34px 0 16px 0;
}

.the_table_type_6_controls .the_button_2 {
	float:right;
	height:30px;
	line-height:28px;
	font-size:19px;
	margin:0 6px 0 0;
}


.the_table_type_6_controls .the_button_2.email:before {
	content:'';
	display:inline-block;
	width:27px;
	height:17px;
	margin:0 0 0 8px;
	position:relative;
	top:3px;
	background:url(../../img/sprite_tbl_icons.png) 0 -300px no-repeat;
}


.the_table_type_6_controls .the_button_2.export:before {
	content:'';
	display:inline-block;
	width:14px;
	height:20px;
	margin:-1px 0 0 8px;
	position:relative;
	top:3px;
	background:url(../../img/sprite_tbl_icons.png) -5px -1px no-repeat;
}

.the_table_type_6_controls .the_button_2.print:before {
	content:'';
	display:inline-block;
	width:26px;
	height:19px;
	margin:-2px 0 0 8px;
	position:relative;
	top:5px;
	background:url(../../img/sprite_tbl_icons.png) 0 -550px no-repeat;
}





.the_table_type_6_container {
	margin:0 0 41px 0;
	clear:both;
}

.the_table_type_6_container:after {
	content:'';
	display:table;
	clear:both;
}

.the_table_type_6_container h2 {
	float:right;
	border-left:1px solid #CCC;
	background:#F6F6F6;
	width:50%;
	color:#F90D0D;
	font-size:30px;
	line-height:47px;
	padding:0 8px 0 0;
}

.the_table_type_6_container h2 + h2 {
	border-right:1px solid #CCC;
	border-left:none;
	color:#85C51F;
}

.the_table_type_6_box {
	clear:both;

	direction:ltr;
	width:740px;
	/*overflow:auto;*/
	height:339px;

	margin:0 -20px 0 0;

	box-sizing:content-box;
	-moz-box-sizing:content-box;
	-webkit-box-sizing:content-box;
}

.the_table_type_6 {
	width:720px;
	float:left;
}

.the_table_type_6_box:after {
	content:'';
	display:table;
	clear:both;
}

.the_table_type_6_col {
	float:right;
	width:50%;

}

.the_table_type_6_col + .the_table_type_6_col {
	border-left:none;
}

.the_table_type_6_box table {
	width:100%;
	font-size:12.5px;
	border:1px solid #CCC;
	direction:rtl;
}

.the_table_type_6_box table tr > * {
	text-align:center;
	height:26px;
	vertical-align:middle;
	border:1px solid #CCC;
}

.the_table_type_6_box table thead tr th {
	font-size:13px;
	background:#0D83DD;
	border:none;
	color:#FFF;
}

.the_table_type_6_box .gray_bg > * {
	background:#F6F6F6;
}


.display_type {
	line-height:39px;
}

.display_type a {
	float:left;
	width:40px;
	height:40px;
	background:#0D83DD;
	margin:0 9px 0 0;
	position:relative;
    cursor: pointer;

}

.display_type a.active {
	background:#6db5eb;
    border-bottom: 1px solid #ccc;
}

.list:before {
	content:'';
	display:block;
	position:absolute;
	height:6px;
	width:32px;
	left:4px;
	top:7px;
	background:#FFF;
}

.list:after {
	content:'';
	display:block;
	position:absolute;
	height:6px;
	width:32px;
	left:4px;
	top:17px;
	border-top:6px solid #FFF;
	border-bottom:6px solid #FFF;
	height:16px;
}


.items:before {
	content:'';
	display:block;
	position:absolute;
	left:4px;
	top:16px;
	height:8px;
	width:32px;
	border-left:8px solid #FFF;
	border-right:8px solid #FFF;
}

.items:after {
	content:'';
	display:block;
	position:absolute;
	width:8px;
	height:8px;
	background:#FFF;
	top:16px;
	left:16px;
}


.smb_buttons_box .client_search_box {
	margin:5px 0 0 0;
}

.right_side_menu.type_2 > ul > li > a {
	padding-right:28px;
}

.right_side_menu.type_2 > ul > li > a:before {
	display:none;
}

.right_side_menu.type_2 > ul > li > a:after {
	display:block;
	left:auto;
	right:20px;
}

.the_button_2.back_button {
	width:107px;
	height:41px;
	line-height:40px;
	font-size:30px;
	float:left;
	clear:both;
	margin:10px 0 15px 0;
}

.the_button_2.back_button:before {
	content:'';
	display:inline-block;
	width:24px;
	height:26px;
	background:url(../../img/ico_back.png);
	margin:0 0 0 6px;
	position:relative;
	top:3px;
}

.std_block.popular_themes_block .std_block_header {
	background:#FFF;
	border-bottom:1px solid #CCC;
	margin:0 26px 0 327px;
}

.popular_topics {
	margin:20px 26px 30px 0;
	font-size:21px;
	width:408px;
	float:right;
}

.popular_topics > li > a {
	display:inline-block;
	padding:0 19px 0 0;
	text-decoration:underline;
	position:relative;
}

.popular_topics > li > a:before {
	content:'';
	display:block;
	width:0;
	height:0;
	border:7px solid transparent;
	border-right:10px solid #9D9D9C;
	position:absolute;
	top:6px;
	right:0;
}

.popular_topics > li > a.active:before {
	border:7px solid transparent;
	border-top:10px solid #9D9D9C;
}

.popular_topics > li > div {
	padding:0 19px 15px 0;
	display:none;
}



.pop_themes_img_1 {
	display:block;
	float:left;
	width:195px;
	height:177px;

	background:url(../../img/img_pop_topics_1.png);
	margin:-32px 0 30px 32px;
}

.pop_themes_img_2 {
	display:block;
	float:left;

	width:251px;
	height:278px;

	background:url(../../img/img_pop_topics_2.png);
	margin:-32px 0 30px 32px;
}

.topics_video_box {
	clear:both;
	overflow:hidden;
	width:100%;
	margin:0 0 41px 0;
}

.topics_video_box ul {
	display:block;
	width:741px;
}

.topics_video_box li {
	float:right;
	width:226px;
	height:140px;
	background:#F6F6F6;
	margin:0 0 33px 21px;
}

.topics_video_box li a {
	display:block;
	width:226px;
	height:140px;
	border:1px solid #CCC;
	border-radius:2px;
	background:#F6F6F6;
	position:relative;
}

.topics_video_box li img {
	display:block;
	width:224px;
	height:138px;
	position:relative;
	z-index:1;
}

.topics_video_box li a:before {
	content:'';
	display:block;
	position:absolute;
	z-index:100;
	width:80px;
	height:80px;
	background:#DADADA url(../../img/ico_play.png);
	border-radius:80px;
	top:50%;
	left:50%;
	margin:-40px 0 0 -40px;
}

.custom_scroll_type_1 .mCSB_inside > .mCSB_container {
	margin-right:20px;
}

.custom_scroll_type_1 .mCSB_scrollTools .mCSB_buttonUp,
.custom_scroll_type_1 .mCSB_scrollTools .mCSB_buttonDown {
	width:14px;
	height:14px;
	background:#EDEDED url(../../img/ico_custom_scroller_arrows.png);
	opacity:1;
}

.custom_scroll_type_1 .mCSB_scrollTools .mCSB_buttonUp {
	background-position:0 0;
}

.custom_scroll_type_1 .mCSB_scrollTools .mCSB_buttonDown {
	background-position:0 -14px;
}

.custom_scroll_type_1 .mCSB_scrollTools a + .mCSB_draggerContainer {
	margin:14px 0 14px 0;
	width:14px;
	background:#EDEDED;
}

.custom_scroll_type_1 .mCSB_scrollTools .mCSB_draggerRail {
	margin:0;
	width:14px;
	background:#EDEDED;
}

.custom_scroll_type_1 .mCSB_scrollTools .mCSB_dragger {
	width:100%;
}

.custom_scroll_type_1 .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	width:100%;
	border-radius:0;
	background:#878787;
}

.custom_scroll_type_1 .mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
	background:#878787;
}

.highcharts-container svg > text:last-child {
	display:none;
}
[lang="en"] .dataTables_wrapper{
    font-family:'Myriad Pro', Arial, Helvetica, sans-serif;
}
.dataTables_info, .dataTables_length{
    padding: 0.25em 0 0.25em 0.25em;
}
.dataTables_wrapper .dataTables_paginate{
    padding-bottom: 0.25em;
}
.dataTables_wrapper .dataTables_filter{
    padding-right: 0.25em;
    padding-top: 0.25em;
}
/*
 * Namespace DTCR - "DataTables ColReorder" plug-in
 */

table.DTCR_clonedTable {
	background-color: rgba(255, 255, 255, 0.7);
	z-index: 202;
}

div.DTCR_pointer {
	width: 1px;
	background-color: #0259C4;
	z-index: 201;
}

.std_block_2_header {
	direction:ltr;
}

.cl_filter_table_slider {
	direction:ltr;
	display:block;
    clear: both;
}

.cl_filter_table_slider:before, .cl_filter_table_slider:after {
	content:'';
	display:table;
	clear:both;
}

.cl_filter_table_slider li {
	float:left;
	width:740px;
}

.the_table_type_6_container .bx-wrapper {
	width:740px;
	height:339px;
	float:left;
	clear:both;
}

.dashboard_widjets .left_column {
	float:left;
	width:361px;
}

.dashboard_widjets .left_column .std_block , .dashboard_widjets .right_column .std_block {
	cursor: move;
}

.dashboard_widjets .right_column {
	float:right;
	width:689px;
}

.containerVertical:not(.wide_model_view) {
	min-height: 300px;
}

.dashboard_widjets {
	overflow: hidden;
}

.dashboard_widjets .income_graph {
	height: 0;
}

.dashboard_widjets {

	// model view
	.wide_model_view {
		width: 100%!important;
	}

	// view_toggle_box
	.view_toggle_box {
		float:right;
		padding:11px;
		background:#F6F6F6;
		margin:55px 0 12px 0;
	}

	.view_toggle_box span {
		float:right;
		margin:0 0 0 9px;
		line-height:39px;
	}

	.view_toggle_box a {
		display:block;
		margin-right: 10px;
		float:right;
		width:36px;
		height:39px;
		background:#0d83dd;
		position:relative;
	}

	.view_toggle_box a:hover {
		background:#005ed5;
	}

	.view_toggle_box a:before {
		content:'';
		display:block;
		position:absolute;
		width:100%;
		height:3px;
		left:0;
		top:12px;
		background:#F6F6F6;
	}

	.view_toggle_box .with_sb:after {
		content:'';
		display:block;
		position:absolute;
		width:3px;
		left:10px;
		top:12px;
		bottom:0px;
		background:#F6F6F6;
	}

	// 	main widgets block
	.nav-tabs {
		background: none;
		float: right;
		display: block;
		margin: 0;
		li {
			float: right;
			margin: 0 0 0 1px;
			a {
				margin: 0;
			    height: 43px;
			    line-height: 43px;
			    min-width: 126px;
			    padding: 0 10px 0 10px;
			    text-align: center;
			    color: #0D83DD;
			    text-decoration: none;
			}
		}
		li.active {
			a {
				background: #F6F6F6;
				text-decoration: none;
				color: #575757;
			}
		}
	}

	.widgets_slider_content_box  {
		// overflow: hidden;
		min-height: 92px;
		.std_block_shadow {
			display: block;
		}
		.dnd_widget_description {
			display: block;
		}

		.std_block {
			width: 25%;
			height:110px;
			float: right;
			clear: none;
			padding-left: 15px;
            text-align: right;
		}
		.slick-slider {
			direction: rtl;
		}
		.slick-prev {
			right: inherit;
			left: 30px;
			top: -50px;
			display: block;
		    width: 24px;
		    height: 24px;
		    background: #868686;
		    font-size: 0;
		}
		.slick-prev:after {
			border-right: 13px solid #EDEDED;
			top: 3px;
			right: 7px;
		}
		.slick-next {
			right: inherit;
			left: 0;
			top: -50px;
			display: block;
		    width: 24px;
		    height: 24px;
		    background: #868686;
		    font-size: 0;
		}
		.slick-next:after {
			border-right: 13px solid #EDEDED;
			top: 3px;
			right: 7px;
		}
		.slick-dots {
			top: -60px;
			left: 60px;
			width: 58%;
			.slick-active {
				background:#868686;
			}
			bottom: inherit;
			li {
				float: left;
			    min-height: 0;
			    text-align: center;
			    display:block;
			    width:22px;
			    height:22px;
			    background:#DADADA;
			    font-size:0;
			    border-radius:22px;
			    button {
			    	opacity: 0;
			    }
			}
		}
		.slick-dots li:hover {
			background:#868686;
		}

	}

	// widgets column
	.left_column .std_block,
	.right_column .std_block {
		width: 100%!important;
	}
}

// dashboard
.dashboard_widjets {
	.slick-track {
        width: 100%;
		min-height: 92px;
	}

}

.widgets_slider_content_box .std_block_shadow {
	display: none!important;
}
.language_section {
    position: fixed;
    right: 10px;
    z-index: 999999;
    top: 10px;
    background: rgba(255,255,255,.6);
    padding: 5px;

}

// Distributors_page

.distributors_page {
	.smile-man {
		.big-btn {
			font: 28px fahrenheit,Arial,Helvetica,sans-serif!important
		}
		a.help-title {
			font-size: 31px;
		}
	}

	// Table
	.my_users_tab {
		min-height: 0;
	}
	.table>thead>tr>th {
		background: #0d83dd;
		text-align: center;
		color: #fff;
	}
	.search-table:after {
		display: block;
		position: static;
		width: 33px;
		color: #878787;
		margin: auto;
	}
	.select-checkbox {
		width: 40px !important;
	}
}

.graph_info {
	// Title
	.title {
		position: absolute;
		left: 0;
		top: 0;
		background: #F6F6F6;
		right: 14%;
		text-align: right;
		border-radius: 8px 0 0 0;
		color: #9D9D9C;
		padding: 9px 23px 2px 0;
		font-size: 39px;
		line-height: 45px;
	}
	// Tabs
	.graph_widget_tabs {
		width: 23%;
		a {
			strong {
				font-size: 22px;
			}
		}
	}
	.graph_widget_tabs_valign {
		padding: 15px;
		.content {
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
			padding: 10px;
			text-align: left;
			padding-left: 30px;
			span {font-size: 22px;}
		}
		table {
			border-top: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
			tr {
				td {
					padding: 10px;
				}
				td:first-child {
					border-left: 1px solid #ccc;
					text-align: left;
				}
			}
		}
	}
}


// add_invitation_poppup
.add_invitation_poppup {

	// Table pagination
	.table_pagination {
		text-align: left;
		li {
			cursor: pointer;
			text-decoration: underline;
			color: #095895;
			display: inline-block;
			padding: 10px 15px;
			border-radius: 10px 10px 0 0;
			font-size: 15px;
			font-weight: 700;
			text-align: center;
			vertical-align: middle;
		}
		li.active {
			background-color: #EDEDED;
			color: #000;
			text-decoration: none;
		}
	}
}


.the_button_2.view_all_comissions {
    display:block;
    line-height:32px;
    height:34px;
    left:12px;
    top:10px;
    position:absolute;
    padding:0 15px 0 15px;
}

.export-popup-header {
    padding: 0 22px;

    .modal-title {
        position: relative;
    }

    .export-link {

        font-weight: normal;
        font-size: 18px;
        text-decoration: underline;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #0000fe;
        padding-left: 35px;

        &:before {

            content: '';
            background-image: url("../../../assets/img/ico_gen_settings_1.png");
            background-size: cover;
            width: 27px;
            height: 28px;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
        }

        &:hover {
            text-decoration: none;
        }
    }
}

.export-popup-body {
    box-sizing: border-box;
    font-size: 18px;
    padding-top: 15px;

    .has-error .datepicker-input.form-control[readonly],
    .has-error .datepicker-input.form-control
    {
        border-color: #ed1c24;
    }
    .datepicker-input.form-control[readonly],
    .datepicker-input.form-control
    {
        background-color: #f6f6f6;
        color: #9d9d9b;
        border-color:#999999;
    }
    input {
        display: block;
        width: 100%;
        padding: 5px;
    }

    .form-col {
        padding: 0 8px;
    }

    .label-holder {
         text-align: left;

        label {
            line-height: 30px;
        }
     }

    .input-holder {

        .ui-select-match,
        .form-control {
            color: #9d9d9b;
            background: transparent;
            box-shadow: inset -1px 1px 0px 0px rgba(0, 0, 0, 0.25);

            .btn {
                color: inherit;
            }
        }

        .ui-select-toggle {
            padding-right: 6px;
        }
    }

    .btn-holder {
        padding: 15px 30px 0;
        text-align: left !important;
        box-sizing: border-box;

        .btn {
            font-size: 18px;
            padding: 4px 20px;
            display: inline-block;
            vertical-align: middle;
        }

        a {
            display: inline-block;
            vertical-align: middle;
            text-decoration: underline;
            color: #0000fe;

            &:hover {
                text-decoration: none;
            }
        }

        .separator {
            display: inline-block;
            vertical-align: middle;
            padding: 0 5px;
        }
    }
}

//.export-popup-body-form-wrap {
//    margin: 0 auto;
//    float: none;
//}