//
// Modals
// --------------------------------------------------

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and shit

// Kill the scroll on the body
.modal-open {
  overflow: hidden;
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal;
  -webkit-overflow-scrolling: touch;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    .translate(0, -25%);
    .transition-transform(~"0.3s ease-out");
  }
  &.in .modal-dialog { .translate(0, 0) }
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  z-index: 99999;
}

// Actual modal
.modal-content {
  position: relative;
  background-color: @modal-content-bg;
  border: 5px solid @modal-content-fallback-border-color; //old browsers fallback (ie8 etc)
  border: 5px solid @modal-content-border-color;
  border-radius: @border-radius-large + 6;
  position: relative;
  .box-shadow(3px 3px 0 0 rgba(0, 0, 0, 0.5)) !important;
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: 0;
}

// Modal background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal-background;
  background-color: @modal-backdrop-bg;
  // Fade for backdrop
  &.fade { .opacity(0); }
  &.in { .opacity(@modal-backdrop-opacity); }
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
    position: relative;
    padding: @modal-title-padding;
    border-bottom: 8px solid @modal-header-border-color;
    min-height: (@modal-title-padding + @modal-title-line-height);
}

// Title text within header
.modal-title {
  margin: 0;
  padding-right: 40px;
  line-height: @modal-title-line-height;
  font-weight: 700;
  color: #000;
  font-size: 35px;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  padding: 10px 10px 20px 10px;
}

// Footer (for actions)
.modal-footer {
  padding: @modal-inner-padding;
  text-align: right; // right align buttons
  border-top: 8px solid @modal-footer-border-color;
  &:extend(.clearfix all); // clear it in case folks use .pull-* classes on buttons

  // Properly space out buttons
  .btn + .btn {
    margin-right: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}
.btn-show{
  display: none!important;
}
// Measure scrollbar width for padding body during modal show/hide
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Scale up the modal
@media (min-width: @screen-sm-min) {
  // Automatically set modal's width for larger viewports
  .modal-dialog {
    width: @modal-md;
    margin: 30px auto;
  }
  .modal-content {
    .box-shadow(0 5px 15px rgba(0,0,0,.5));
  }

  // Modal sizes
  .modal-sm { width: @modal-sm; }
}

@media (min-width: @screen-md-min) {
  .modal-lg { width: 920px; }
}


.modal-header {
    padding: 20px 30px 10px 20px;
}

.modal-header .close {
    position: absolute;
    background: 0;
    outline: none;
    border: 23px solid transparent;
    border-top-color: #ddecfc;
    border-right-color: #ddecfc;
    top: 0;
    right: 0;
    i {
        position: absolute;
        top: -24px;
        right: -24px;
        font-size: 26px;
        color: @close-color;
        width: 25px;
        height: 27px;
        cursor: pointer;
        &:hover{
            background: white;
            border-radius: 100%;
        }
    }
    a& {
        cursor: default;
        padding: 0;
        -webkit-appearance: none;
    }
}


.modal-header .close:focus i {
    border: 1px solid;
    border-radius: 100%;
}


.modal.no-header-border.bootbox{

    .modal-header{
        border-bottom: none;
    }
    .modal-body{
        text-align: center;
    }
}
.modal.bootbox.centered-popup{
    top: 31%
}
.modal.bootbox.extra-large-popup{
    .modal-dialog{
        width: 1100px;
    }
}

.modal.bootbox .modal-body .bootbox-body .common-popup * {
    font-size: 22px;
    text-align: justify;
}

.modal.bootbox .modal-body .bootbox-body .common-popup{

}

.distibutorsPopWelcome {

    .bootbox-body {
        padding: 20px 40px 0 0;
        color: #000;

        a {
            text-decoration: underline;
        }

        .separator {
            display: inline-block;
            vertical-align: middle;
            padding: 0 5px;
        }
    }
}

.lightbox-nav {
    margin-top: -10px;
    margin-right: -10px;
    margin-bottom: 30px;
    margin-left: -10px;
}
.lightbox-nav .close{
    position: absolute;
    background: 0;
    outline: none;
    border: 23px solid transparent;
    border-top-color: #ddecfc;
    border-right-color: #ddecfc;
    top: 0;
    right: 0;
    i {
        position: absolute;
        top: -24px;
        right: -24px;
        font-size: 26px;
        color: @close-color;
        width: 25px;
        height: 27px;
        cursor: pointer;
        &:hover{
            background: white;
            border-radius: 100%;
        }
    }
    a& {
        cursor: default;
        padding: 0;
        -webkit-appearance: none;
    }
}