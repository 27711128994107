
.recent_actions_timeline {
    padding: 10px 17px 17px 11px;
    height: 100%;
    min-height: 350px;
    max-height: 350px;
    overflow-y: none;
}
.recent_actions_timeline.full {
    overflow-y: scroll;
}
.recent_actions_timeline:after {
  content:'';
  display:table;
  clear:both;
}

.recent_actions_timeline li:first-child {
  font-size:31.6px;
  line-height:36px;
  padding:0 14px 0 0;
  position:relative;
  display:inline-block;
  margin:0 0 7px 0;
  font-weight:normal;
  color:#000;
}

.recent_actions_timeline li:first-child:before {
  content:'';
  display:block;
  position:absolute;
  top:11px;
  right:-5px;
  width:11px;
  height:11px;
  background:#CCC;
  border-radius:11px;
}

.recent_actions_timeline li:first-child:after {
  content:'';
  display:block;
  position:absolute;
  top:12px;
  bottom:-7px;
  width:1px;
  right:0;
  border-left:1px dashed #CCC;
}

.recent_actions_timeline ul + li:first-child:after {
  top:0;
}


.recent_actions_timeline ul {
  display:block;
}

.recent_actions_timeline li {
  position:relative;
  padding:0 39px 0 0;
  display:block;
  line-height:26px;
  margin:0 0 9px 0;
}

.recent_actions_timeline li:before {
  content:'';
  display:block;
  position:absolute;
  top:0px;
  bottom:-7px;
  width:1px;
  right:0;
  border-left:1px dashed #CCC;
}

.recent_actions_timeline li:after {
  content:'';
  display:block;
  position:absolute;
  top:50%;
  right:0;
  width:30px;
  border-top:1px dashed #CCC;
}

.ra_timeline {
    margin: 0 0 9px 0;
    height: 280px;
    overflow-y: auto;
    padding-right: 7px;
    word-wrap: break-word;
}

.ra_timeline:after {
  content:'';
  display:table;
  clear:both;
}

.ra_timeline ul:last-child li:last-child:before {
  bottom:50%;
}
