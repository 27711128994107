
.search-result-popover{

    position: absolute;
    background-color: white;
    width: 100%;
    padding: 3px;
    border: 1px solid #0a69b1;
    z-index: 9999;


    .close-popover-button {
        position: absolute;
        top: 3px;
        z-index: 99999;
        left: 6px;
    }
}

.search-result-page {

    background: white;


    .search-result-row {
        .search-result-title {

            font-size: 19px;
            background: #f6f6f6;
            padding: 5px 30px 5px 5px;
            position: relative;
            font-weight: bold;
            color: #000;

            .ico-holder {
                position: absolute;
                right: 5px;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 21px;
                line-height: 1;

                img {
                    display: block;
                }
            }
        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
        }

        li {
            cursor: pointer;
            padding: 7px 0 7px 20px;
            position: relative;
            color: #c6c6c6;

            >span:after {
                content: '';
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
                transform: translateY(-50%);
                border-right: 7px solid #0d83dd;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
                margin-right: 4px;
            }

            b {
                font-weight: bold;
                color: #000;
            }
        }
    }
}
.logo-wrapper{
    margin-bottom: 50px;
    height:97px;
    .top-text{
        font-weight: bold;
        font-size: 19px;
        width: 300px;
        margin-right: 30px;
    }
    .top-text, .logo{
        display: block;
        float: right;
        height:100%;

    }

    #logo img {
        margin: 50px 0;
    }
    .logo img {

    }

}