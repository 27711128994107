.textLess {
    background: @brand-warning;
    color: white;
    padding: 10px;
    margin: 10px;
}

.form-control, .customer-form-style, .discount-form-style {
	background: #f6f6f6;
	border-radius: 2px !important;
}

.labels-style {
	font-weight: bold;
	color: #575756;
}

tbody .numb-column{
	text-align: right;
}

.customer-form-style {
	border: 1px solid #e1e1e1;
	padding-top: 15px;
	padding-left: 50px;
  margin-bottom: 40px;
	a{
		float: left;
	}
}

.or{
	display: inline-block;
	float: left;
	margin-left: 20px;
}

.discount-form-style {
	border: 1px solid #e1e1e1;
	padding: 10px !important;
}

textarea.customer-form-input{
  height: auto;
}

.customer-form-input {
	display: block;
    width: 100%;
    height: 30px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.1;
    color: #222;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px !important;
}

.customer-form-label {
	color: #3c3c3b;
	text-align: right;
}

.customer-form {
	margin-right: 0px;
}

.btn-success .fa-plus {
    opacity: .7;
    vertical-align: middle;
    font-size: 13px;
}

.input-field {
	width: 100%;
	line-height: 32px !important;
	//padding-left: 3px;
	//padding-right: 3px;
	border: 1px solid #e1e1e1;
	border-radius: 2px!important;
    min-height: 34px;
}

.table-labels {
	text-align: center;
	padding-left: 5px;
	padding-right: 5px;
	font-weight: bold;
}

.modal-footer {
    text-align: center;
}

.new_doc_footer {
    background: url('../../img/invoice_bottom_bgr.png') no-repeat no-repeat;
    min-height: 150px;
    padding-right: 230px;
    text-align: right;
    font-size: 18px;
    padding-top: 32px;
}

.status-document{
	width: 27px;
	height: 30px;
	display: inline-block;
	background: url(../../img/sprites.png)no-repeat 50% 11px;
	background-size: 100%;
	text-indent: -9999999px;
	overflow: hidden;
}

.status-document.selected{
	background: url(../../img/sprites.png) no-repeat -2px -28px;
}

td.tooltipTextarea textarea.has-error{
  border: 1px solid #ed1c24 !important;
}

td.tooltipTextarea textarea.has-error:focus{
  border: 1px solid #ed1c24 !important;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #f90b00;
}

.table_blue, .payment-table {
	.transparent-input.has-error {
		border: 1px solid red;
	}
}

.discount-label{
	cursor: pointer;
}

.item-filter-magnify-button{
	cursor: pointer;
}

.item-filter-input{

	display: none;
	position: relative;
}

.item-search-table{
	padding:30px;
}

td.item-search-table table tbody tr:hover td,
table tbody tr:hover td.item-search-table {
	background-color: white !important;
}

td.item-search-table table tbody tr.blue-head td{
	background-color: @brand-primary!important;
}


.new-document-modal {
	.btn.btn-xs.btn-info {
		font-size: 15px;
	}
}

.document-modal {
    .modal-header {
        height: 68px;
        .ui-select-match-text {
            margin-left: 16px;
        }
        .modal-title {
            padding-right: 23px
        }
    }
}

@payment-silver: #dadada;
.payment-table {

    .nav-tabs {
        float: left;
    }
  .nav-tabs > li {

  }

  .nav-tabs > li > a{
    font-size: 20px;
  }

  .nav-tabs > li.active > a {
    background-color: @payment-silver;
    color: #575757;
  }
  .nav > li > a:hover {
    background-color: #e8e8e8;
    color: #575757;
  }
  .tab-content > .tab-pane{
    margin-top: 0;
  }

  table.payment-credit-card-table{

    th.voucher{
      width: 130px;
    }
    th.date{
      width: 112px;
    }
    th.amount{
      width: 40px;
    }
    th.card,th.count,th.digits{
      width: 80px;
    }
    th.expiry{
      width: 110px;
    }
    td.expiry{
      direction: ltr;
        white-space: nowrap;
      .transparent-input{
        width: 33px;
        text-align: center!important;
          white-space: normal;
      }
    }
    .transparent-input{
      min-width:0;
    }
  }

  table{
    width: 100%;
    border-bottom: 1px solid @payment-silver;
    margin-bottom: 25px;

    tr.table-head {
      th {
        background-color: @payment-silver;
        border-left: 1px solid white;
      }
      th:last-child{
        border-left: 0;
      }
    }

    .transparent-input{
      min-width:85px;
      max-width: 170px;
      width: 100%;
    }
    .text-center{
      text-align: center!important;
    }

    .text-left{
      text-align: left!important;
    }

    .text-right{
      text-align: right!important;
    }

    th {
      vertical-align: middle;
      padding: 4px 15px;
      text-align: right;
    }
    td {
      padding: 10px 15px;
      text-align: right;
    }
  }
  .nav-tabs {
    background: white;
    margin: 0;
  }
}

.payment-summary-head{
  background-color: #e7f4fd;
  border-bottom: 1px solid @payment-silver;
  margin-bottom: 15px;

}

.payment-summary-body{

  padding: 0 25px 20px;

  .with-blue-arrow{
    text-align: left;
  }
  .with-blue-arrow:before{
    content: " ";
    border: 8px solid transparent;
    border-right: 8px solid #0d83dd;
    position: absolute;
    left: -10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  border-bottom: 1px solid @payment-silver;
  margin-bottom: 25px;

  ul.summary-group li{
    border-bottom: 1px solid @payment-silver;
    padding-top: 6px;
    padding-bottom: 6px;

    div:first-of-type{
      text-align: left;
    }
    div:last-of-type{
      text-align: right;
      padding-right: 0;
    }
  }
  .summary-group {
    display: table;
    li.row{
      min-width: 40px;
      div{
        font-size: 15px;
        text-align: right;
        display: table-cell;
        vertical-align: middle;
      }
    }

  }
  .total-table-inputs{

    padding: 5px 0 0;
    height: 50px;
  }

}

.payment-summary-body ul.summary-group li:last-child{
  border-bottom:none;
}

table tbody tr  td.item-search-table  table.item-filter-table,
table tbody tr:hover td.item-search-table  table.item-filter-table,
table.item-filter-table {

	thead {
		tr {
			td, td:hover {
				background-color: @brand-primary !important;
			}
		}
	}
	tfoot {
		tr {
			td, td:hover {
				background-color: #eee !important;
			}

			td :not(.filter_number_range) input {
				width: 100%;
			}
		}
	}

	tbody {

		tr:hover {
			td, td:hover {
				background-color: #e7f4fe !important;
			}
		}
		tr {

			td, td:hover {
				background-color: white !important;
			}
		}
	}
  
  .name{
    cursor: pointer;
  }
}

.smb_links {
  float:right;
  margin:0;
  font-size:0;
}

.smb_links > li {
  font-size:35px;
  line-height:38px;
  /*float:right;*/
  display:inline-block;
  margin:0 0 0 5px;
  position:relative;
  color:#575756;
  vertical-align:middle;
  min-height:38px;
}

.smb_links > li a {
  color:#575756;
}

.smb_links > li b {
  font-weight:bold;
  color:#000;
  font-size: 35px;
}

// .smb_links > li:after {
//   content:'|';
//   display:block;
//   position:absolute;
//   left:-18px;
//   top:0;
// }

.smb_links > li:first-child {
  margin:0 0 0 10px;
}

.smb_links > li:first-child:after {
  display:none;
}

.smb_links > li:last-child {
  margin:0;
}

.smb_links > li:last-child:after {
  display:none;
}

.smb_links select, .smb_links .custom_select_type_1 {
  display:block;
  margin:2px 4px 0 0;
  width:182px;
}


.smb_links li:nth-of-type(2) {
    margin-left: 5px;
}

.print-document-popup{
    span, b{
    font-size: 22px;
    }
}

// Label
.form-group-total {
  .discount-label {
      .text-part{

          float: right;
          margin-left: 5px;
      }

    .discount {
      color: #0D83DD;
      margin-left: 0;
    }
  }
  .comment_buttons {
    margin-top: 10px;
  }
}

// Textarea
.comment_block {
  textarea {
    height: 60px;
    resize: none;
  }
}



.paragraph_table.table_blue {

    tbody {
        td input, textarea{
            font-size: 13px;
        }
        td.amount input{
            padding-left: 0;
            padding-right: 0;
        }
    }
    .blue-head td {
        width: 74px;
        padding: 5px;
        word-wrap: break-word;
        white-space: normal;
    }
    .display-with-vat-select{
        font-size: 13px;
    }
    td.amount{
        width: 80px;
    }
    td.details {
        width: 175px;
    }
    td.vat {
        width: 66px;

    }
    td.add-button{
        width: 83px;
    }


}

.form-group-total {
    padding: 0px 30px 0 15px;
}

.email_input{
    float: left;
    width: 97%;
}

.has-error .customer-form-input{
    border-color: #ed1c24;
    
}

.has-error .customer-form-input:focus{
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #f90b00;
}

.details-input{

    border: 0;
    padding: 0;
    text-align: center;
    resize: none;
    height: 14px;
    background: none;
    width: 100%;
}

.details-input:focus{
    outline: none;
}

.details-input-wrapper{
    line-height: inherit !important;
    min-height: 34px;
    padding-bottom: 5px;
    padding-top: 9px;
    float: left;
    width: 100%;
}

.edit{

    .input-with-icon-container .customer-form-input.input-with-icon+.input-icon{
        top: 3px;
        right: 8px;
        width: 20px;
    }
}

.input-with-icon-container{
    position: relative;
    margin-bottom: 3px;

    .customer-form-input.input-with-icon,
    .details-input.input-with-icon,
    .form-control.input-with-icon,
    .transparent-input.input-with-icon{
        display: inline;
        padding-right: 33px;
    }

    .details-input.input-with-icon+.input-icon{
        top:-4px;
        right:3px;
    }


    .customer-form-input.input-with-icon+.input-icon{
        top:4px;
        right:6px;
    }

    .transparent-input.input-with-icon+.input-icon{
        top:7px;
    }
    .money-worth-textarea.transparent-input.input-with-icon+.input-icon{
        top:2px;
    }


    .input-with-icon+.input-icon{
        display: inline;
        position: absolute;
        top:6px;
        right:6px;
    }
}


.english-button-visible {

    .details-input-wrapper {
        width: 154px;
    }
}

.enable-english-title-icon-block,
.enable-english-occasional-customer-name-block
{
    height: 15px;
    display: inline-block;
    img{
        height: 100%;
        vertical-align: baseline;
    }
}
.enable-english-customer-name-icon-block{
    display: block;
    height: 32px;
    margin-left: 15px;
    padding-top: 6px;
    padding-bottom: 6px;

    img{
        height: 100%;
        vertical-align: baseline;
    }
}

.english-details-block{
    margin-top: 6px;
}

.total-blue-title{
    top: 7px;
    position: relative;
    display: inline-block;
}
.delete_row.customer_email{
    text-align: right!important;

    .delete-btn{
        position: relative;
        top:3px;
    }
}
.currency-calculator-block {

    .main {
        background-color: #F6F6F6;
        height: 60px;
        display: table;
        width: 100%;

        .input-column {

            input{
                text-align: center;
                font-weight: bold;
            }

            width: 13%;
            height: 100%;
            padding: 10px;
            display: table-cell;
            vertical-align: middle
        }
        .input-label-column {
            width: 29%;
            padding: 10px;
            height: 100%;
            display: table-cell;
            vertical-align: middle
        }
        .rate-column {
            border-left: 1px solid #888;
            display: table-cell;
            padding: 10px;
            height: 100%;
            vertical-align: middle
        }
        .rate-column-label {
            width: 20%;
            padding: 10px;
            background-color: #0D83DD;
            display: table-cell;
            color: white;
            height: 100%;
            vertical-align: middle;
        }
    }
}
.document-modal label,
.document-modal label .input-label-row *,
.document-modal label .input-label-row{
    font-size: 22px;
}
.document-modal .customer-conatainer .ui-select-container input[type="search"]{
    padding-right: 40px;
    background-image: url(../../img/ico_users_tbl_search.png);
    background-repeat: no-repeat;
    background-position: 95% center;
}
.he .document-modal .ui-select-choices-row-inner{
    text-align: right;
    direction: rtl;
}
.single-plus{
  padding:  1px 27px 0;
}
.btn.small-btn.xxs-button{
  padding: 2px 6px 1px;
  line-height: 21px;
}
.close-button{
    min-width: 120px;
    background: #FDD835;
    color: #0a81dd;
    font-size: 26px;
    padding: 12px 7px 10px;
    border: 2px solid #0a81dd;
    border-radius: 5px;
}
.close-button:hover{
    color:#0a81dd;
    opacity: 0.8;
}
.btn-docType{
    display: inline-block;
    position: relative;
    height:194px;
    width: 194px;
    border: none;
    font-size: 22px;
}
.btn-docType:focus{
}
.btn-docType.btn-taxInvoiceReceipt{
    background: url(../../img/tax_invoice_receipt.png);
}
.btn-docType.btn-taxInvoice{
    background: url(../../img/tax_invoice.png);
}
.btn-docType.btn-receipt{
    background: url(../../img/receipt.png);
}
.btn-docType.btn-receipt .button-text{
    top:103px;
}
.btn-docType.btn-order{
    background: url(../../img/order.png);
}
.btn-docType.btn-order .button-text{
    top:102px;
}
.btn-docType.btn-deliveryNote{
    background: url(../../img/delivery_note.png);
}
.btn-docType.btn-deliveryNote .button-text{
    top:102px;
}
.btn-docType.btn-performa{
    background: url(../../img/perfoma.png);
}
.btn-docType.btn-performa .button-text{
    top:103px;
}
.btn-docType.btn-priceQuote{
    background: url(../../img/price_quote.png);
}
.btn-docType.btn-receiptCredit{
    background: url(../../img/credit_invoice.png);
}
.btn-docType.btn-receiptInvoice{
    background: url(../../img/receipt_credit.png);
}
.btn-docType.btn-paymentRequest{
    background: url(../../img/payment_request.png);
}
.btn-docType.btn-returnNote{
    background: url(../../img/return_note.png);
}
.btn-docType.btn-balance{
    background: url(../../img/balance.png);
}
.btn-docType .button-text{
    position: absolute;
    top: 107px;
    border-radius: 9px;
    left: 26px;
    width: 142px;
    height: 45px;
    color: #fff;
    background: 0 0;
    border: none;
    display: inline-block;
    line-height: 45px;
    font-size: 16px;
}
.bigDocTypeButton{
    position: relative;
    background: url("../../img/credit_docs_group.png");
    width:440px;
    height: 194px;
    display: inline-block;
    vertical-align: top;
}
.bigDocTypeButton button{
    border: none;
    width: 208px;
    height: 38px;
    position: absolute;
    left:38px;
    top:22px;
    background: none;
    color:#135289;
    font-size: 18px;
}
.bigDocTypeButton button:nth-child(2){
    top:76px;
}
.bigDocTypeButton button:nth-child(3){
    top:128px;
}
.button-title{
    position: absolute;
    right: 32px;
    bottom: 40px;
    width: 140px;
    height: 44px;
    line-height: 46px;
    color: #fff;
    font-size: 22px;
}
.search-items-col{
    text-align: right!important;
}
.payment_delete_col{
    width: 15px;
}
.payment_card_col{
    width:150px!important;
}

.bank_column{
    width: 150px;
}
.balance-block{
    text-align: left!important;
    background: #FFEB3B;
    padding: 0 5px;
}
.modal-title.document-title{
    max-width:435px;
}
.hasRelatedDocsFinger{
    display: inline-block;
    vertical-align: middle;
    background: url("../../img/ico_your_link.png");
    height: 105px;
    width: 105px;
}

.add_document_poppup .smb_links.small-text>li{
    font-size: 24px;
}
.add_document_poppup .smb_links.small-text >li b{
    font-size: 24px;
}
.transparent-input.small-input{
    max-width: 50px!important;
    min-width: 50px!important;
}
label.input-label-row{
    position: relative;
    font-size: 22px;
    font-weight: bold;
}
label.input-label-row:before{
    content: " ";
    border: 8px solid transparent;
    border-right: 8px solid #0d83dd;
    position: absolute;
    left: -24px;
    top: 50%;
    margin-top: -9px;
    cursor: pointer;
}
label.input-label-row .relatedCheckbox{
    top:2px;
}
label.input-label-row.active:before{
    left: -24px!important;
    margin-top: -5px;
    transform: rotate(-90deg)!important;
}
.word-break{
    word-wrap: break-word;
    display: inline-block;
    vertical-align: top;

    &.word-break-block {
        display: block;
    }
}
.saveChangesPopup .modal-body{
    padding:25px 0 0 0;
}
.popup_save_changes_box{

}
.saveChangesPopup .popup_save_changes_box:before{
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border: 30px solid transparent;
    border-top-color: #DDEDFD;
    border-right-color: #DDEDFD;
    top: -25px;
    right: 0;
}
.saveChangesPopup .bootbox-close-button{
    position: absolute;
    top: 0;
    /* right: -24px; */
    font-size: 26px;
    color: #6EB5EB;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 99999;
}
.saveChangesPopup .the_button{
    padding: 0 8px 0 8px;
}
.fz20{
    font-size:20px;
}
.warning-message{
    direction: rtl;
    font-size: 20px;
    text-align:right;
}

.relatedToDocs{
    text-align: right!important;

    ul,li{
        display: inline;
    }
}
.discount-form-style{
    .btn-box{
        .btn.button_add{
            padding-right: 22px;
        }
    }
}
.filter_column.filter_number_range.form-control{
    background: none;
}

.table_blue .delete_row {
    float: right;
    width: 30%;
}

/* Poppups */
/* add document */
.document-modal .modal-header {
    height: 90px;
}

.document-modal .modal-header .modal-title {
    padding-right: 0;
}
.comment_block .comment_wrap {
    clear: both;
    overflow: hidden;
    margin: 0;
    position: relative;
}
.comment_block .chart_count {
    position: absolute;
    bottom: 0;
    left: 5px;
    font-size: 11px;
    color: #ccc;
}
.document-modal .modal-header .ui-select-match-text{
    margin-left: 0;
    text-align: right;
    width: 100%;
}
.add_document_poppup .select_wrap {
    width: 110px;
    float: left;
    padding: 0;
    margin-left: 10px;
}
.add_document_poppup .select_wrap.first{
    margin-left: 20px;
}

.add_document_poppup .comment_button {
    float: left;
}
.add_document_poppup .ui-select-match .btn {
    padding-left: 40px;
    background: #fff;
}

.add_document_poppup .smb_links>li, .add_document_poppup .smb_links>li b {
    font-size: 32px;
    color: #575755;
}