/*Sidebar account menu*/
.list-group-item:first-child,
.list-group-item:first-child:hover,
.list-group-item:first-child.active {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.list-group-item.header,
.list-group-item.header:hover {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.list-group-item.active {
    font-weight: normal;
    border-radius: 0;
    margin-bottom: 0;
    position: relative;
}

a.list-group-item.active:before {
    content: '';
    display: block;
    position: absolute;
    width: 6px;
    height: 12px;
    background: url(../../img/sprite_right_sb_icons.png) 0 0;
    top: 50%;
    left: 9px;
    margin: -6px 0 0 0;
}

.list-group {
    position: relative;;
}

.dropdown-item {
    cursor: pointer;
    z-index: 999;
    position: relative;
    display: block;
}

.drowdown-item:after {
    content: '';
    background: url(../../img/dropdown-arrow.png);
    position: absolute;
    display: block;
    left: 10px;
}

.dropdown-list-group a.list-group-item {
    background: #fff;
    border: none;
    min-height: 61px;
    padding: 0 15px;
    line-height: 61px;
}

.dropdown-list-group a.list-group-item:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.dropdown-list-group a.list-group-item:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.list-group-item:last-child:hover,
.list-group-item:last-child.active {
    background: #e7f4fe;
}

.dropdown-list-group a.list-group-item:hover {
    background: #e7f4fe;
}

.dropdown-list-group .list-group {
    background: #fff;
}

a.list-group-item:hover {
    background: #e7f4fe;
}

.list-group-item {
    font-size: 24px;
    border-radius: 0;
    position: relative;
    direction: ltr;
    text-align: right;
    background: none;
}

.list-group-item.header {
    font-weight: bold;
}

.list-group-item > img {
    margin-left: 10px;
}

.dropdown-list-group {
    display: none;
    min-width: 280px;
}

.list-group:after {
    content: "";
    width: 157px;
    height: 8px;
    position: absolute;
    bottom: -8px;
    right: 15px;
    background: url(../../img/shadow_right.png);
}

.list-group:before {
    content: "";
    width: 157px;
    height: 8px;
    position: absolute;
    bottom: -8px;
    left: 15px;
    background: url(../../img/shadow_left.png);
}

.dropdown-list-group .list-group:before,
.dropdown-list-group .list-group:after {
    background: none;
}

.dropdown-list-group.active {
    position: absolute;
    right: 100%;
    top: 0px;
    display: block;
    padding-right: 5px;
}

.dropdown-item:before {
    content: "";
    width: 8px;
    height: 16px;
    background-repeat: no-repeat;
    background: url(../../img/dropdown-arrow.png);
    background-size: 100%;
    display: block;
    position: absolute;
    left: 10px;
    z-index: 3;
    top: calc(~"50% - 8px");
    background-position: left center;
}

.sidebar-column {
    margin-bottom: 200px;
    padding-left: 70px;
}

.ui-select-bootstrap > .ui-select-match > .btn {
    overflow: hidden;
}

/*END sidebar account menu*/

textarea.internal-comment-input {
    resize: none;
    height: 100px;
}

.profile-inpul-label {
    text-align: right !important;
}

.profile-holder {
    padding-top: 35px;
    margin-bottom: 40px;
}

.business-info-holder group-holder .form-group.contact-box:last-child {
    margin-bottom: 40px;
}

.he .ui-select-bootstrap .ui-select-choices-row > a {
    direction: rtl;
    text-align: right;
}

.email_settings_box {
    padding: 20px 23px 32px 23px;
}

.email_settings_box hr {
    display: block;
    margin: 0 0 15px 0;
    border: none;
    border-top: 1px solid #CCC;
}

.email_settings_box .row {
    margin: 0 0 15px 0;
}

.email_settings_box .row:after {
    content: '';
    display: table;
    clear: both;
}

.email_settings_box .row_content {
    float: right;
    line-height: 35px;
    width: 440px;
}

.email_settings_box .controls_list_1 {
    margin: 0;
    display: inline-block;
}

.email_settings_form {
    float: right;
}

.email_settings_form .add_email_input {
    width: 262px;
    height: 35px;
    padding-top: 4px;
    margin: 0 0 0 12px;
    font-weight: bold;
    color: #575756;
}

.email_settings_box input[type="text"],
.email_settings_box .add_email_input {

    height: 35px;
    width: 262px;

}

.email_settings_form button.the_button[type="submit"] {
    border-width: 1px;
    height: 35px;
    line-height: 35px;
    width: 167px;
    padding: 0 5px 0 5px;
}

.email_settings_form button.the_button[type="submit"]:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 0 0 7px;
    background: url(../../img/ico_plus_16x16.png) center no-repeat;
}

.email_settings_box {
    .email_address_label {
        margin-left: 7px;
    }
    .email_settings_form_table {
        .row_title {
            float: none;
        }
        width: 100%;
        td {
            vertical-align: top;
            white-space: nowrap;
        }
        td.second-column {
            width: 1%;
            padding-left: 6px;
        }
        tr:hover td {
            background: none !important;
        }
    }
    .add_email_button {
        font-size: 19px;
        LETTER-SPACING: 1px;
        /* width: 150px; */
        text-align: left;
        padding-top: 4px;
        padding-right: 22px;
        padding-bottom: 0;

    }
    .add_email_input {
        font-size: 19px;
        LETTER-SPACING: 1px;
        font-weight: bold;
        margin-right: 3px;
        margin-left: 4px;
        display: inline-block;
    }

    select.external-option-input {
        width: 150px;
    }
    .line {
        border: 1px solid silver;
        margin-bottom: 22px;
        margin-top: 18px;
    }
    .row_title,
    .custom_radio_1 span {
        font-size: 21px;
        LETTER-SPACING: 1px
    }
    .note {
        color: rgb(87, 87, 87);;
        line-height: 22px;
        margin-bottom: 10px;
        font-size: 16px;
        white-space: normal;
    }

    .controls_list_1 .element {
        min-width: 0;
        margin: 0 0 0 20px;
    }

    .row_title {
        float: right;
        margin-left: 21px;
    }

    .report-settings-block {

        .emails-form-block {
            .row_title {
                margin-left: 5px;
            }

            .emails_block {
                text-align: right;
                margin-right: 3px;
                margin-left: 4px;
            }
        }
        .select-filetype-block {

            td {
                padding-top: 22px
            }
            .row_title {
                margin-left: 30px;
            }
            .element {
                margin-left: 15px;
            }
            .element:last-of-type {
                margin-left: 0;
            }
        }
        .section-title {
        }

        .select-frequency-block {
            margin-bottom: 30px;

            .element {
                margin-left: 25px;
            }
        }

        padding-bottom: 15px;
    }
    .document-settings-block {
        padding-top: 4px;
        .row_title {
            margin-left: 37px;
        }
        .section-title {

        }
        .element {
            margin-left: 11px;
        }
    }

    .section-title {
        font-size: 28px;
        line-height: 36px;
        margin: 0 0 2px 0;
        letter-spacing: -0.4px;
    }

}

.bookmanagement-page {

    .error-frame .has-error {
        color: #ed1c24;
    }
    .email_settings_box {
        padding: 24px 21px 29px 22px;;
        .row_title {
            line-height: 35px
        }
        .row {
            margin: 0 2px 6px 0;
        }
        .row_content {
            font-size: 21px;
            letter-spacing: 1px;
            line-height: 35px;
        }
        .select-income-reporting-block {
            margin-bottom: -6px;
            margin-top: 0;
            margin-right: 0;
            margin-left: 0;
            .row_title {
                margin-left: 21px;
            }
        }
    }
    .bookkeeping-data-section {
        .row_title {
            font-size: 21px;
            letter-spacing: 1px;
            font-weight: bold;
            margin-left: 5px;
        }

        .settings_input_1 {
            width: 134px;
        }

    }

    .section-title {
        font-size: 28px;
        line-height: 36px;
        margin: 0 0 7px 0;
        letter-spacing: 0;
    }
    .controls_list_1 > li {
        min-width: 106px;
    }
    .approval-section {
        padding-top: 23px;

        .fileupload-column {
            text-align: center;
        }

    }

    .accountant_audit_tbl {
        background: #FFF;
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 8px;
        border-radius: 5px;
        padding-top: 6px;
    }

    .accountant_audit_tbl table {
        width: 100%;
    }

    .accountant_audit_tbl thead td {
        font-weight: bold;
        font-size: 21px;
        letter-spacing: 1px;
    }

    .accountant_audit_tbl td {
        border-bottom: 1px solid #CCC;
        vertical-align: middle;
        /* height: 41px; */
        font-size: 21px;
        letter-spacing: 1px;
        padding-top: 10px;
        padding-bottom: 2px;
        padding-right: 13px;
    }

    .accountant_audit_tbl tbody tr:last-child td {
        border: none;
    }

    .accountant_audit_tbl .the_button_2 {
        width: 94px;
        height: 25px;
        padding: 0;
        line-height: 24px;
        font-size: 14px;
        font-weight: 400;
        border-width: 1px;
    }

    .accountant_audit_tbl .the_button_2.export:before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 13px;
        background: url(../../img/sprite_tbl_icons.png) 0 -1050px no-repeat;
        margin: 0 0 -2px 5px;
    }
    button.export-button {
        font-size: 21px;
        letter-spacing: 1px;
    }

}

.business-definitions-page {

    .business-type-column.has-error,
    .business-name-column.has-error {
        color: #ed1c24;
    }

    .panel-body {
        padding-right: 10px;
        padding-left: 20px;
        padding-bottom: 16px;
    }

    .flag-column {
        width: 23px;
        float: right;
        margin-right: 28px;
        margin-left: 6px;
        padding-top: 5px;
    }

    .business-language-row {
        margin-bottom: 17px;
        input.business {
            font-size: 21px;
            letter-spacing: 1px;
        }
    }

    .en-row {
        .business-type-column,
        .business-name-column {
            text-align: left;
            input {
                text-align: left;
            }
        }
    }

    .business-type-column,
    .business-name-column {
        width: 310px;
        float: left;
        padding-right: 2px;
        padding-left: 12px;
        font-size: 21px;
        letter-spacing: 1px;
    }

    .language-checkbox {
        padding-right: 4%;
        margin-bottom: 35px;
        margin-right: 2px !important;
        font-size: 21px;
        letter-spacing: 1px;
    }

    .business-definition-input-group {

        padding-left: 12px;
        padding-right: 4%;
        width: 100%;
        margin-bottom: 4px;
        .input-column {
            width: 50%;
            float: left;
            font-size: 21px;
            letter-spacing: 1px;
            select, input {
                font-size: 21px;
                letter-spacing: 1px;
            }

            select {
            }
        }
        .label-column {
            width: 50%;
            float: left;
            font-weight: bold;
            font-size: 21px;
            letter-spacing: 1px;
        }
    }

    .has-error .label-column {
        color: #ed1c24;
    }

    .business-definition-input-group:after {
        clear: both;
    }
}

.business-info-page {

    .info .title-info {
        font-size: 22px;
        letter-spacing: 1px;
    }
    .business-info-holder {
        padding-top: 10px;
    }

    .website-info-block {
        height: 45px;
        padding: 12px 10px 0 0;
        line-height: 34px;
        color: #575757;
        margin: 0;

        .website-label {
            font-weight: bold;
        }

        .website-label,
        .website {
            letter-spacing: 1px;
            font-size: 22px;
            float: right;
            margin-left: 3px;
        }
    }
}

.template-delete-button {
    background-color: #888;
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
    padding: 5px;
    border-radius: 5px;
    border: none;
}

.print-settings {

    .input-block {
        font-size: 21px;
        letter-spacing: 1px;

    }
    .section_title {
        font-size: 28px;
        line-height: 36px;
        margin: 0 0 2px 0;
        letter-spacing: -.4px;
    }
}

.export-settings {

    .nav-tabs{
        background: none;
        border: none;
        margin: 0;
        >li > a{
            border: 1px solid rgba(225, 225, 225, 0.9);
            margin: 0;
            border-bottom: none;
        }
    }
    .tab-pane{
        margin-top: 0;
    }
    .tab-content-wrapper{
        background: #FFF;
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 8px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        padding-top: 6px;
        border: 1px solid rgba(225, 225, 225, 0.9);
        border-top: none;

        table{width: 100%}
        td{
            font-size: 21px;
            letter-spacing: 1px;
        }
        .code-column{
            width: 80px;
        }
        th{
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
            text-align: right;
        }
        td, th{
            padding: 12px;
            vertical-align: middle;
        }

        tr:not(:last-of-type){
            border-bottom: 1px solid #CCC;
        }

        input{
            font-size: 21px;
            letter-spacing: 1px;
        }
    }
}
.subscription-settings{
    .main-tabset {

        >.tab-content{
            padding-top: 40px;
            border: 1px solid #CCC;
            padding-right: 3px;
            padding-left: 3px;
            border-radius: 6px 0 6px 6px;

        }

        > .nav.nav-tabs {

            > li {
                width: auto;
            }
        }
    }

    .my_subscription_box {
        background:#F6F6F6;
        padding:0 34px 25px 34px;
    }

    .my_subscription_title {
        border-bottom:1px solid #CCCCCC;
        margin:0 0 24px 0;
    }

    .my_subscription_title h3 {
        float:right;
        font-size:28px;
        font-weight:normal;
        color:#000;
    }

    .my_subscription_title span {
        font-size:27px;
        vertical-align:bottom;
        color:#000;
        margin:0 20px 0 0;
    }

    .my_subscription_progress_bar {
        height: 51px;
        background: #DDEDFD;
        font-size: 26px;
    }

    .my_subscription_progress_bar > div {
        background:#0D83DD;
        padding:0 10px 0 10px;
        color:#FFF;
        height:100%;
        line-height:51px;
        float:right;
        display:table;
        white-space:nowrap;
        text-align:center;
    }
    .std_block_2.upgrade_subscription_block .std_block_2_header {
        padding:0 6px 0 6px;
        background:none;
    }

    .tabs_type_2_tabs {
        height:39px;
    }

    .tabs_type_2_tabs:after {
        content:'';
        display:table;
        clear:both;
    }

    .tabs_type_2_tabs a {
        float:right;
        border:1px solid #CCC;
        margin:0 0 0 1px;
        background:#F6F6F6;
        border-radius:5px 5px 0 0;
        min-width:110px;
        height:39px;
        line-height:40px;
        font-size:22px;
        text-align:center;
    }

    .tabs_type_2_tabs a:hover {
        background:#FFF;
        text-decoration:none;
    }

    .tabs_type_2_tabs .active {
        background:#FFF;
        height:40px;
        margin:0 0 -1px 1px;
        border-bottom:1px solid #FFF;
        color:#000;
    }

    .tabs_type_2_tabs.upgrade_subscription a {
        width:124px;
    }
    .tab-content > .tab-pane{
        margin-top: 0;
    }
    .nav-tabs{
        margin: 0;
        background: none;
    }
    .nav.nav-tabs{
        height: 41px;
        li.active{
            background: white;
            height: 42px;
            border-bottom: 1px solid white;
        }
        li:hover{
            background: white;
        }
        li{
            cursor: pointer;
            background: #F6F6F6;
            height: 41px;
            width: 124px;
            border-radius: 5px 5px 0 0;
            border: 1px solid #CCC;
            margin-left: 1px;
        }
        li>a:hover{
            background: none;
        }
        li.active>a{
            color: black;
        }
        li>a{
            text-decoration: none;
            width: 100%;
            margin: 0;
            border: none;
            line-height: normal;
            text-align: center;
        }
    }

    .tabs_type_2_tabs_box.upgrade_subscription {
        padding:21px 6px 22px 6px;
    }

    .upgrade_subscription_plans {
        background:#0D83DD;
        display:block;
        border-radius:10px;
        margin:21px 0 0 0;
        position:relative;
    }

    .title-block {
        display:block;
        width:100%;
        height:35px;
        background:#F6F6F6;
        font-weight: bold;
        font-size: 22px;
        padding: 3px 12px 3px 3px;
    }

    .upgrade_subscription_plans:after {
        content:'';
        display:table;
        clear:both;
    }

    .upgrade_subscription_plans li {
        float:right;
        width:14.27%;
        height:174px;
        border-right:1px solid #CCC;
        text-align:center;
        padding:26px 0 8px 0;
        color:#FFF;
        font-size:12px;
    }

    .upgrade_subscription_plans li:first-child {
        border:none;
    }

    .upgrade_subscription_plans h4 {
        line-height:23px;
        display:block;
        margin:0 0 19px 0;
        font-weight:normal;
    }

    .upgrade_subscription_plans h4 b {
        font-size:52px;
        line-height:45px;
        font-weight:bold;
        display:block;
        margin:0 0 -5px 0;
    }

    .upgrade_subscription_plans .subscription-label {
    font-size: 17px;
    }

    .upgrade_subscription_plans h4 span {
        display:block;
        line-height:18px;
    }

    .upgrade_subscription_plans li > span {
        display:block;
        margin:0 0 12px 0;
    }

    .upgrade_subscription_plans li > span b {
        font-size:14px;
        font-weight:bold;
    }

    .upgrade_subscription_plans li > span small {
        font-size:11px;
        font-weight:normal;
    }

    .upgrade_subscription_plans .the_button[disabled] {
        background-color: silver;
    }
    .upgrade_subscription_plans .the_button {
        display:block;
        margin:auto;
        border:none;
        font-size:17px;
        height:25px;
        line-height:30px;
        width:73px;
        padding: 0;
    }

    .your_plan {
        display:none;
        font-size:23.68px;
        font-weight:bold;
        line-height:28px;
        margin:0 0 -3px 0;
        position:relative;
        z-index:10;
    }

    .your_plan:before {
        content:'';
        display:block;
        width:103px;
        height:135px;
        margin:auto;
        margin:-101px auto 2px auto;
        background:url(../../img/img_your_plan_img_1.png);
    }

    .payment_info_box{
        input.has-error,
        select.has-error{
            border-color: #ed1c24!important;
            outline: none;
        }
    }


    .upgrade_subscription_plans li.active {
        background:#6EB5EB;
    }

    .upgrade_subscription_plans li.active .your_plan {
        display:block;
    }

    .upgrade_subscription_plans li.active .the_button {
        display:none;
    }

    .upgrade_subscription_plans li.active h4 {
        margin:0 0 0 0;
    }

    .need-more-text-block{
        font-weight: bold;
        text-align: center;
        font-size: 23px;
    }

    .vat-disclaimer-block{
        text-align: right;
        padding-top: 12px;
        padding-right: 12px;
    }
    .upgrade_subscription_payment_block{

    .std_block_3_header h2 {
        font-size:27px;
        line-height:49px;
        color:#000;
    }
    }
}