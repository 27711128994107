//
// Tables
// --------------------------------------------------

.dataTables_wrapper {
    table {
        table-layout: fixed;
        /*word-wrap: break-word;*/
        word-wrap: break-word;

        td.amount{
            direction: ltr;
            unicode-bidi: normal;
        }
    }
}
table {
  background-color: @table-bg;
}

tfoot {
    display: table-row-group;

    th {
        background-color: @gray-lighter;
    }
}

caption {
  padding-top: @table-cell-padding;
  padding-bottom: @table-cell-padding;
  color: @text-muted;
  text-align: left;
}
th {
  text-align: left;
}


// Baseline styles
    .items_content .table,
.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: @line-height-computed;
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        color: black;
        text-align: center;
        font-size: 16px;
        padding: @table-cell-padding;
        line-height: @line-height-base;
        vertical-align: middle;
        border-top: 1px solid @table-border-color;
      }
    }
  }
  // Bottom align for column headings
  > thead > tr > th {
    vertical-align: middle;
    border-bottom: 2px solid @table-border-color;
    background-color: @brand-primary ;
    color: white;
    text-align:center;
    font-weight: normal;

  }
  // Remove top border from thead by default
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-top: 2px solid @table-border-color;
  }

  // Nesting
  .table {
    background-color: @body-bg;
  }
}
.table>thead>tr>th:hover {
  background-color: @brand-primary;
}

// Condensed table w/ half padding

.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: @table-condensed-cell-padding;
      }
    }
  }
}


// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: 1px solid @table-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid @table-border-color;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  > tbody > tr:nth-of-type(odd) > td {
    background-color: @table-bg-accent;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  > tbody > tr:hover > td {
    background-color: #e7f4fe !important;
  }
}

// Table cell sizing
//
// Reset default table behavior

table col[class*="col-"] {
  position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
  float: none;
  display: table-column;
}
table {
  td,
  th {
    &[class*="col-"] {
      position: static; // Prevent border hiding in Firefox and IE9-11 (see https://github.com/twbs/bootstrap/issues/11623)
      float: none;
      display: table-cell;
    }
  }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
.table-row-variant(active; @table-bg-active);
.table-row-variant(success; @state-success-bg);
.table-row-variant(info; @state-info-bg);
.table-row-variant(warning; @state-warning-bg);
.table-row-variant(danger; @state-danger-bg);


// Responsive tables
//
// Wrap your tables in `.table-responsive` and we'll make them mobile friendly
// by enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
    margin-bottom: (@line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid @table-border-color;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            white-space: nowrap;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
  }
}

.green-text{
    color:#00A000!important;
}
.red-text{
    color:#99251c!important;
}

.row-button.big,
.row-button.big.btn{
    width: 44px;
    height: 42px;
    line-height: 28px;
    padding: 6px;
}

.row-button:focus,
.row-button:active:focus{
    outline: none;
}
.row-button.btn{
    padding: 0;
}
.row-button{
    width: 30px;
    height: 30px;
    position: relative;
    background-color: #888888;
    padding: 0;
}

.row-button:before{
   content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    /* vertical-align: middle; */
    position: relative;
    left: 0;
    /* top: 5px; */
}
.row-button.document-report:before {
    background: url(../../img/sprites.png) no-repeat;
    background-position: -3px -385px;
}
.row-button.document-o:before {
    background: url(../../img/sprites.png) no-repeat;
    background-position: -3px -413px;
}

.row-button.print:before {
    background: url(../../img/sprites.png) no-repeat;
    background-position: -2px -63px;
}
.row-button.export:before {
    background: url(../../img/sprites.png) no-repeat;
    background-position: -3px -162px;
}

.no-table-buttons .dataTables_wrapper{
    top:-75px;
}
.no-table-buttons .dt-buttons{
    display: none;

}
.table-hover > tbody > tr:nth-of-type(even) > td {
    background-color: #ffffff;
}


.he .table-grey {
    direction: rtl;
}

.he .table-grey td,
.he .table-grey th {
    text-align: right !important;
}

.form-inline .table-grey > tbody tr td:first-child {
    text-align: left;
}

.table-grey {
    background-color: transparent !important;
}

.table.table-grey > thead > tr > th {
    padding: 0 40px;
    font-weight: bold;
    box-shadow: none;
    height: 48px;
    background-color: #ffffff;
    background-clip: padding-box;
}

.table-grey > thead th:first-child {
    border-top-left-radius: 5px;
}

.table-grey > thead th:last-child {
    border-top-right-radius: 5px;
}

.table.table-grey > tbody > tr > td {
    padding: 0 40px;
    box-shadow: none;
    border: none;
    height: 42px;
}

.table.table-grey > tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 5px;
}

.table.table-grey > tbody > tr:last-child > td:last-child {
    border-bottom-right-radius: 5px;
}

.table-grey tr.odd > td{
    background-color: #ffffff;
    background-clip: padding-box;
}

.table-grey tr.even > td{
    background-color: #ededed;
    background-clip: padding-box;
}



table tbody tr.selected td,
table tbody tr.selected:nth-of-type(even) td,
table tbody tr.selected:nth-of-type(odd) td,
table tbody tr:hover td,
table tbody tr.disabled:hover td {
    background-color: #e7f4fe;
    text-decoration: underline;
}

table tbody tr.selected td > *, table tbody tr.selected:nth-of-type(even) td > *, table tbody tr.selected:nth-of-type(odd) td > * {
    text-decoration: underline;
}

table tbody tr.disabled td {
    background-color: #f6f6f6;
    color: #B2B2B2;
}

table tbody tr.disabled:nth-of-type(even) td, table tbody tr.disabled:nth-of-type(odd) td {
    background-color: #f6f6f6;
    background-clip: padding-box;
    color: #B2B2B2;
}

table tbody tr.disabled.selected td, table tbody tr.disabled.selected:nth-of-type(even) td, table tbody tr.disabled.selected:nth-of-type(odd) td {
    background-color: #e7f4fe;
}

table.dataTable td.select-checkbox:before,
table.dataTable td.select-checkbox:after {
    display: block;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 48%;
    width: 15px;
    height: 14px;
    box-sizing: border-box;
    pointer-events: none;
}

.items_content .table > thead > tr > th:hover,
.items_content .table > thead > tr > th:focus,
.table > thead > tr > th:hover,
.table > thead > tr > th:focus
{
    background-color: #6eb5eb;

}
.table > thead > tr > th {
    vertical-align: middle;
    position: relative;
}

.table > tbody > tr > td, .table > tfoot > tr > th {
    vertical-align: middle;
}



table tbody tr.selected td,
table tbody tr.selected:nth-of-type(even) td,
table tbody tr.selected:nth-of-type(odd) td {
    background-color:#e7f4fe;
    text-decoration:underline;
}

table tbody tr.selected td > *,
table tbody tr.selected:nth-of-type(even) td > *,
table tbody tr.selected:nth-of-type(odd) td > * {
    text-decoration:underline;
}

table tbody tr.disabled td {
    background-color:#f6f6f6;
    color:#B2B2B2;
}

table tbody tr.disabled:nth-of-type(even) td,
table tbody tr.disabled:nth-of-type(odd) td {
    background-color:#f6f6f6;
    color:#B2B2B2;
}

table tbody tr.disabled.selected td,
table tbody tr.disabled.selected:nth-of-type(even) td,
table tbody tr.disabled.selected:nth-of-type(odd) td {
    background-color:#e7f4fe;
}

.table_blue td {
    min-width: 70px;
}

table .blue-head:hover {
    td{
        background-color: #0D83DD !important;
    }
}

table .blue-head{
    td{
        padding: 5px 10px;
        border-left: 1px solid white;
        background-color: @brand-primary;
        color: white;
        white-space: nowrap;
        text-align: center;
        vertical-align: middle;
    }
}

table {

    td{
        background-clip: padding-box;
        position: static !important;
    }

    tfoot {

        th {
            background-clip: padding-box;
        }
    }
}

.customers-table {

    table {

        thead {
            tr {
                background: #0D83DD;
            }
        }
    }
}

.filter_select {
    width: 70%;
    margin: 0 auto;
    display: block;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        border-top: 7px solid #D9D9D9;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        right: 5px;
        pointer-events: none;
    }

    .form-control {
        width: 100% !important;
    }

    select {
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
        padding: 0 17px 0 3px;

        &::-ms-expand {
            display: none;
        }

    }
}

.he {

    .filter_select {

        &:after {
            right: auto;
            left: 5px;
        }

        select {
            padding: 0 3px 0 17px;
        }
    }
}

.table {
    thead {
        tr {
            th {
                border-color: transparent;
            }
        }
    }
}

.docsTable {
    thead {
        tr {
            th {
                border-color: transparent;
                position: static;
            }
        }
    }
}
