
/*Placeholder mixin*/
.placeholder-size(@fontSize: @size) {

    &:-moz-placeholder            { font-size: @fontSize; } // Firefox 4-18
    &::-moz-placeholder           { font-size: @fontSize; } // Firefox 19+
    &:-ms-input-placeholder       { font-size: @fontSize; } // Internet Explorer 10+
    &::-webkit-input-placeholder  { font-size: @fontSize; } // Safari and Chrome
}


@font-face {
    font-family:'fahrenheit';
    src: url('../../fonts/fahrenheitregularwebfont.eot');
    src: url('../../fonts/fahrenheitregularwebfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/fahrenheitregularwebfont.woff') format('woff'),
    url('../../fonts/fahrenheitregularwebfont.ttf') format('truetype'),
    url('../../fonts/fahrenheitregularwebfont.svg#HelveticaNeueLight') format('svg');
    font-weight:normal;
    font-style:normal;
}


@font-face {
    font-family:'fahrenheit';
    src: url('../../fonts/fahrenheitboldwebfont.eot');
    src: url('../../fonts/fahrenheitboldwebfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/fahrenheitboldwebfont.woff') format('woff'),
    url('../../fonts/fahrenheitboldwebfont.ttf') format('truetype'),
    url('../../fonts/fahrenheitboldwebfont.svg#HelveticaNeueLight') format('svg');
    font-weight:bold;
    font-style:normal;
}


@font-face {
    font-family:'fahrenheit';
    src: url('../../fonts/fahrenheitmediumwebfont.eot');
    src: url('../../fonts/fahrenheitmediumwebfont.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/fahrenheitmediumwebfont.woff') format('woff'),
    url('../../fonts/fahrenheitmediumwebfont.ttf') format('truetype'),
    url('../../fonts/fahrenheitmediumwebfont.svg#HelveticaNeueLight') format('svg');
    font-weight:500;
    font-style:normal;
}

/**! ColResize 2.5.4
 * ©2017 Steven Masala
 */

body {
    min-width: 1230px;
    display: initial;
}

.dt-colresizable-table-wrapper {
    overflow: visible;
    width: 100%;
    position: relative;
    direction: ltr;
    overflow-x: scroll;
}

.documents-table-wrapper .table.dataTable {
    .my_elipsis {
        direction: rtl !important;
    }

    .number_range_filter.form-control{
        width: 40% !important;
    }
    .amount-holder {
        direction: ltr !important;

        .money-amount {
            direction: ltr !important;
        }
    }
}

.he .customer-table-wrapper .table.dataTable {

    .ltr-direction {
        direction: ltr !important;
        text-align: right;
    }

    .my_elipsis {
        direction: rtl !important;
    }

    .amount-holder {
        direction: ltr !important;

        .money-amount {
            direction: ltr !important;
        }
    }
}


.dt-colresizable {
    height: 0;
    position: relative;
    top: 0;
    width: 100% !important;
    //right: 0;
    z-index: 999;
}

// to show draggble line background
.dt-colresizable .dt-colresizable-col {
    display: block;
    position: absolute;
    box-sizing: border-box;
    width: 3px;
    // background: #000;
    cursor: ew-resize;
    direction: ltr !important;
    z-index: 1000;
}

.dt-colresizable-table-wrapper.dt-colresizable-with-scroller {
    overflow-x: auto;
    overflow-y: hidden;
}

.dt-colresizable-scroller-wrapper {
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden; /** FF **/
    width: 100%;
    right: 0;
}

.dt-colresizable-scroller-content-wrapper {
    width: 100%;
}

.dt-colresizable-scroller-content {
    width: 100%;
}

.dt-colresizable-with-scroller table thead,
.dt-colresizable-with-scroller table tbody tr {
    display: table;
    //table-layout: auto !important;
    width: 100%;
}

.dt-colresizable-with-scroller table tbody {
    overflow-y: auto;
    display: block;
}

table.dataTable {
    //table-layout: auto !important;
    margin: 0;
    direction: ltr !important;
}

.he .table.dataTable {
    .my_elipsis {
        direction: rtl !important;
    }

    .amount-holder {
        direction: rtl !important;

        .money-amount {
            direction: rtl !important;
        }
    }
}

table.dataTable,
table.dataTable th,
table.dataTable td {
    box-sizing: border-box
}

table.dataTable thead th,
table.dataTable tbody td,
table.dataTable tfoot td {
    overflow: hidden;
}
.p0 { padding: 0; }
.p5 { padding: 5px; }
.p10 { padding: 10px; }
.p15 { padding: 15px; }
.p20 { padding: 20px; }

.pl0 { padding-left: 0; }
.pl5 { padding-left: 5px; }
.pl10 { padding-left: 10px; }
.pl15 { padding-left: 15px; }
.pl20 { padding-left: 20px; }
.pl40 { padding-left: 40px;}

.pr0 { padding-right: 0; }
.pr5 { padding-right: 5px; }
.pr10 { padding-right: 10px; }
.pr15 { padding-right: 15px; }
.pr20 { padding-right: 20px; }

.pt0 { padding-top: 0; }
.pt5 { padding-top: 5px; }
.pt10 { padding-top: 10px; }
.pt15 { padding-top: 15px; }
.pt20 { padding-top: 20px; }
.pt40 { padding-top: 40px; }

.pb0 { padding-bottom: 0; }
.pb5 { padding-bottom: 5px; }
.pb10 { padding-bottom: 10px; }
.pb15 { padding-bottom: 15px; }
.pb20 { padding-bottom: 20px; }

.m0 { margin: 0; }
.m5 { margin: 5px; }
.m10 { margin: 10px; }
.m15 { margin: 15px; }
.m20 { margin: 20px; }

.ml0 { margin-left: 0; }
.ml5 { margin-left: 5px; }
.ml10 { margin-left: 10px; }
.ml15 { margin-left: 15px; }
.ml20 { margin-left: 20px; }

.mr0 { margin-right: 0; }
.mr5 { margin-right: 5px; }
.mr10 { margin-right: 8px; }
.mr15 { margin-right: 15px; }
.mr20 { margin-right: 20px; }
.mr30 { margin-right: 30px; }

.mt0 { margin-top: 0; }
.mt5 { margin-top: 5px; }
.mt10 { margin-top: 10px; }
.mt15 { margin-top: 15px; }
.mt20 { margin-top: 20px; }

.mb0 { margin-bottom: 0; }
.mb5 { margin-bottom: 5px; }
.mb10 { margin-bottom: 10px; }
.mb15 { margin-bottom: 15px; }
.mb20 { margin-bottom: 35px; }


.bold {
    font-weight: bold;
}

.pointer {
    cursor: pointer;
}

.topNav {
    .search_btn {
        background: darken(@brand-primary, 10%);
        padding: 0px 8px;
    }
}
.nav-pills>li.active>a,.nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
    background-color: #e7f4fe;
    border-radius: 5px;
    color: #0d83dd;
}
.nav-pills>li.active>a:hover{
    background-color: #e7f4fe;
    color: #0d83dd;
}
.big-btn{
    font: 32px 'fahrenheit', Arial, Helvetica, sans-serif!important;
}
.big-btn.button_add:after{
    font: normal normal normal 23px/1 FontAwesome;
}
.notification {
    background: @brand-primary;
    color: white;
    margin-left: 10px;
    margin-top:6px;
    height: 35px;
    position: relative;
    padding: 0 7px;
    &:hover {
        color: white;
    }
    .counter {
        position: absolute;
        bottom: -8px;
        right: -12px;
        min-width: 22px;
        height: 22px;
        border-radius: 100%;
        background: @brand-danger;
        color: white;
        font-size: 14px;
        line-height: 22px;
    }
}

.footer {
    margin-top: 20px;
    width: 100%;
    border: none;
    border-top: 1px solid #ccc;
    padding: 20px 0 68px 0;
    color: #999;
    clear: both;

    h5 {
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: bold;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            line-height: 1;
            margin-bottom: 7px;
            a {
                color: #999;
                font-size: 16px;
            }
        }
    }
    .fb img {
        margin: 30px 0;
    }
    .logo img {
        margin: 10px 0;
    }
    .copyrights {
        border-top: 1px solid #ccc;
        clear: both;
        text-align: left;
        padding: 5px 0 0;
        font-size: 24px;
    }
}
.help-title{
    vertical-align: sub;
}

.upload-button {
    text-align: center;
    padding: 4px;
    display: inline-block;
}

.smb_man {
    position: relative;
    display: inline-block;
    border-left: 1px solid #b5b5b5;


    .smb_man_txt {
        white-space: normal;
        position: absolute;
        font-size: 14px;
        line-height: 17px;
        top: 41px;
        right: 96px;
        color: #fff;
        width: 75px;
        font-weight: 700;
        a {
            font-weight: normal;
            color: white;
            text-decoration: underline;
        }
    }
    width: 208px;
    min-height: 115px;



    .upload-button {
        vertical-align: middle;
        white-space: nowrap;
        min-height: 115px;

        &:after {
            content: '';
            width: 1px;
            height: 115px;
            display: inline-block;
            vertical-align: middle;
        }

        img {
            vertical-align: middle;
        }

    }
}



.en .fromdp{
    line-height: 1;
    position: absolute;
    left: 31px;
    top: 0;
}
.he .fromdp{
    line-height: 1;
    position: absolute;
    right: 17%;
    top: 0;
}
.en .todp{
    line-height: 1;
    position: absolute;
    right: 23px;
    top: 0;
}
.he .todp{
    line-height: 1;
    position: absolute;
    left: 17%;
    top: 0;
}
.he .bootstrap-datetimepicker-widget {
    direction: rtl;
}

// tables
.table.dataTable input.form-control,
.table.dataTable select.form-control {
    height: 23px;
}

table th {

    .filter_column.form-control {
        border: none;
        background: none;
        box-shadow: none;
        padding: 0;
    }

    .filter_column.filter_date_range,
    .filter_column.filter_number_range {
        display: block;
        position: relative;
        min-width: 30px;
        direction: rtl;
    }
    .filter_column.filter_number_range.form-control{
        height: auto;
    }

    .filter_column .form-control {
        width: 100%;
        font-weight: normal;
        font-size: 13px;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            font-size: 14px !important;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            font-size: 14px !important;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            font-size: 14px !important;
        }
        &:-moz-placeholder { /* Firefox 18- */
            font-size: 14px !important;
        }
    }
    .filter_column.filter_number_range .form-control {
        width: 44%;
    }



    .filter_date_range.filter_column .date_range_filter.form-control,
    .filter_number_range.filter_column .number_range_filter.form-control {



        padding: 0;
        width: 44%;
        text-align: center;


        .placeholder-size(20px)
    }
}

.he select.form-control{
    direction: rtl;
}
.he .form-control[disabled]{
    text-align: right;
}

.table_control {
    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 100%;
        margin: -1px 0 0 2px;
        border-top: 2px dashed #ccc;
        border-right: 2px dashed #ccc;
        width: 40px;
        height: 22px;
    }
    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        right: -51px;
        top: 40px;
        border: 10px solid transparent;
        border-top: 17px solid #ccc;
    }
}

.title {
    color: black;
    font-weight: bold;
    font-size: @font-size-large + 4;
    &:hover, &:visited {
        color: black;
        text-decoration: none;
    }
}

.help_btn {
    background: @brand-primary;
    border-radius: @border-radius-base - 1;
    color: white;
    padding: 1px 8px;
    font-size: 24px;
    &:hover, &:visited {
        color: white;
    }
}

.dataTables_info {
    font-size: @font-size-base - 8;
}

.dt-buttons {
    button {
        position: relative;
        span{
            font-size: 22px;
        }
    }
    .btn-sm {
        position: relative;
        padding: 3px 7px;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 3px;
    }
    .btn-info.btn-sm{
        padding: 3px 7px 1px;
    }
}
.table-change tr.active td{
    background-color: #e7f4fe!important;
}
.table-change td,.table-change th{
    padding: 15px 8px!important;
}
.dataTables_wrapper table {
    width: 100% !important;
}

div.dt-button-collection {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 3px;
    padding: 8px 3px 8px 8px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background-color: white;
    overflow: hidden;
    z-index: 2002;
    border-radius: 5px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3);
    z-index: 2002;
    -webkit-column-gap: 8px;
    -moz-column-gap: 8px;
    -ms-column-gap: 8px;
    -o-column-gap: 8px;
    column-gap: 8px;
    button {
        position: relative;
        margin: 4px;

    }
}



div.dt-button-collection > * {
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
}

div.dt-button-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2001;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.big-arrow {
    position: absolute;
    top: 50%;
    cursor: pointer;
    margin-top: -132px;
    &.left {
        left: -100px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 132px 64px 132px 0;
        border-color: transparent #62b4e7 transparent transparent;
    }
    &.right {
        right: -100px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 132px 0 132px 64px;
        border-color: transparent transparent transparent #62b4e7;
    }
    &.disabled {
        cursor: not-allowed;
        .opacity(.5);
    }
}

.datetime-picker-dropdown {
    .btn-sm, small {
        font-size: @font-size-base - 10;
    }
    th {
        vertical-align: bottom;
    }
    > li.date-picker-menu div > table .btn-default {
        border: 0;
    }
}

.margin-top-5 {
    margin-top: 5px;
}


.status-box{
    text-align: center;
}
.status-state{
    width: 32px;
    height: 32px;
    display: inline-block;
    background: url(../../img/sprites.png) no-repeat 0px -28px;
    text-indent: -99999999px;
}
.disabled .status-state{
    width: 27px;
    height: 30px;
    background: url(../../img/sprites.png) no-repeat 50% 11px;
    background-size: 100%;
    overflow: hidden;
}


.bootbox-confirm{
    top:35%;
    z-index: 2002;
    .bootbox-body{
        text-align: center;
        font-size: 20px;
    }

}
.text-center{
    text-align: center!important;
}

.comission_blue_box {

    .custom_select_type_1.cb_select_1 {
        max-width: 265px;
    }
}

.has-error.form-control.ui-select-container {
    border-color: transparent;
}

button.fa {
    font-family: inherit;
}

.smile_man_select {

    button.fa {
        &:before {
            font: normal normal normal 14px/1 FontAwesome;
        }
    }
}