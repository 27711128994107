
.btn-xxs {
    padding: 1px 2px;
    font-size: 11px;
    line-height: 1;
    border-radius: 3px;
}

.message-block
{
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 84%;

    textarea{
        line-height: normal;
        font-size: 16px;
    }
}

.email-block{
    textarea{
        line-height: normal;
        font-size: 16px;
    }
}

.message-block-description
{
    display: inline-block;
    position: relative;
    vertical-align: middle;
    width: 15%;
}

.select-group-holder {
    position: relative;
    display: inline-block;
    margin: 0 10px;
}

.form-control.medium-size {
    min-width: 220px;
}

.send-message-to-recipient-popup {
    padding: 10px 25px 0 25px;
}