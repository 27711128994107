.distributors-dashboard-page{
    .std_block_shadow.pre_pagination{
        position: static;
        margin-bottom: 10px;

    }
}
.distributor_settings_table{
    .the_button.press_to_update:focus {
        border: 2px solid white;
    }
    .the_button.press_to_update {
        width: inherit;
    }
    .comission_blue_box h2 {
        font-size: 40px;
    }

}
.distributors_page{

    .row-button.btn.print{
        display: initial;
        width: 30px;
        height: 30px;
        background-image: none;
    }

    .distributors-commissions-table{

        tbody>tr:hover{
            .button-block
            {
                display: table-cell;
            }
        }
    }

    .name-wrapper{
        min-height: 32px;
        .button-block{
            width: 70px;
            display: none;
        }
        .name-block{
            text-align: right;
            vertical-align: middle;
        }
    }

    .dt-buttons{
        .btn-warning.btn-info.disabled{
            color: #fff;
            background-color: #888;
            border-color: #484848;
        }
    }
}