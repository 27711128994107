.help-description{
    display: none;
}
.help-menu{
    list-style: none;
    padding: 0;
    white-space: normal;
    margin: 0;
    word-wrap: break-word;
}
.help-menu > li.active .help-description{
    display: block;
}
.search-form{
    direction: ltr;
}
.search-input,
.btn-search{
    border: none;
    padding: 5px 10px;
}
.search-input{
    width:78%;
    min-height: 32px;
}
.help-question{
    color:#00F;
    font-size: 20px;
    position:relative;
    padding-right: 20px;
    text-decoration: underline;
}

.help-question:hover{
    color:#00F;
}
.help-question:after{
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-right: 10px solid #9D9D9C;
    position: absolute;
    top: 3px;
    right: 0;
}
.help-menu > li.active .help-question:after{
    border: 7px solid transparent;
    border-top: 10px solid #9D9D9C;
    right: -3px;
}
.underline{
  border-bottom: 1px solid #ccc;
}
.play-video{
    display: flex;
    width: 100%;
    height: 140px;
    border: 1px solid #CCC;
    border-radius: 2px;
    background: #F6F6F6;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    img {
        width: 100%;
    }
}
.play-video:after{
    content: '';
    display: block;
    position: absolute;
    z-index: 100;
    width: 80px;
    height: 80px;
    background: #DADADA url(../../img/ico_play.png);
    border-radius: 50%;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
}
.the_button_grey {
    display: inline-block;
    border: 2px solid #666666;
    border-radius: 3px;
    background-color: #888888;
    color: #FFF;
    padding: 0 8px 0 8px;
    font-weight: bold;
    line-height: 50px;
    text-align: center;
}
.the_button_grey:hover{
    color:#FFF;
}
.the_button_grey.back_button {
    width: auto;
    height: 41px;
    line-height: 40px;
    font-size: 30px;
    float: left;
    clear: both;
    margin: 10px 0 15px 0;
}
.the_button_grey.back_button:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 26px;
    background: url(../../img/ico_back.png);
    margin: 0 0 0 6px;
    position: relative;
    top: 3px;
}.help-question.no-narrow{
    padding-right: 0;
}

.help-question.no-narrow:after{
    content:"";
    display: none;
}
.en .read-more-link{
    direction: ltr;
}
.he .read-more-link{
    direction: rtl;
}
.detailsQuestionText,
span.help-description{
    white-space: normal;
  word-wrap: break-word;
}
.title.help-title small{
  font-size: 100%;
  color: #575756;
  word-break: break-all;
  display: block;
}

.help-page{
    .content-block{
        width: 71%;
        float: left;
        padding-left:15px;
        padding-right:45px;
    }
    .right-help-menu{
        float: left;
        width:29%;
    }
}
.right-help-menu {
    .element-wrapper {
        width: 100%;
        table-layout: fixed;
        .title-block {
            vertical-align: middle;
            padding-right: 8px;
            word-wrap: break-word;
        }
        .image-block {
            width: 41px;
            vertical-align: middle;
        }
    }
}