@import "recent-actions.less";
@import "recent-documents.less";
@import "system-messages.less";
@import "subscription-status.less";
@import "invited-customers.less";

.widget_documents_tbl {
    width: 100%;
    overflow: hidden;
    border-radius: 0 0 9px 9px;
    color: #575757;
    /*
  * Table styles
  */


    table {
        width: 100%;
    }

    thead td,
    thead th {
        font-weight: bold;
        background: #EDEDED;
        color: #1D1D1D;
    }
    thead th:focus,
    thead th:hover
    {
        background: #F6F6F6;
        color: #1D1D1D;
    }


    td,
    th {
        text-align: center;
        border-bottom: 1px solid #CCC;
        padding: 17px 0 15px 0;
    }


    tbody tr:nth-of-type(even) td,
    tbody tr:nth-of-type(even) th {
        background: #f6f6f6;
    }

    tbody tr:last-child td,
    tbody tr:last-child th {
        border-bottom: none;
    }

}
