.menu-terms{
    margin-top: 10px;
    list-style-type: decimal;
    margin-right: 15px;
}
.menu-terms-holder{
    margin-bottom: 15px;
    margin-top: 20px;
}
.menu-terms li{
    font-size: 18px;
}
.menu-header > span{
    font-size: 20px;
    font-weight: bold;
}
.question-mark{
    position: relative;
    top:5px;
}
.page-containter{
    margin-top: 80px;
}
.reverse-page-title .help-title{
    color:#575757;
}
.reverse-page-title .page-description-holder{
    color:#000;
    font-weight: bold;
}

.SumoSelect.open .select-all{
    height: 32px;
    padding-right: 6px;
}
textarea.autogrow{
    resize: none;
}
.autogrow{
    overflow-y: hidden;
    overflow-x: hidden;
}

.input-with-clean-button-holder {

    position: relative;
    display: block;
    float: left;
    width:100%;
    height: 100%;

    input {
        padding-left: 20px;
    }
    .delete-grey {
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        left: 3px;
    }
    .delete-grey{
        line-height: 1;
        height: 18px;
        color: #ccc;
        border:none;
        background: none;
        font-size:12px;
        padding: 3px;
        vertical-align: middle;
        display: inline-block;
    }
}



.money-amount{
    &,
    >span,
    >.ng-binding,
    >.ng-scope{
        direction: ltr;
        unicode-bidi: isolate;
    }
}


.num-link:hover {
    cursor: pointer;
    text-decoration: underline;
}