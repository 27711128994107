.items_content {
    margin: 40px 0;
}
.revert-tabindex{
    float: right;
}
.warning-block {
    text-decoration-color:#8a6d3b ;
}

.items-table{
    .name-block-wrapper{
        direction: ltr;
        display: table;
        width:100%;
        table-layout: fixed;
    }

    tr:not(:hover){
        .name-block-button{

            display:none;
        }
    }
    tr:hover{
        .name-block-button{
            width: 32px;
            text-align: left;
            display:table-cell;
        }
    }

    .name-block-data{
        text-align: right;
        display:table-cell;
    }
}
.items-table td.name-box{
    text-align: right!important;
    word-wrap: break-word;
}


.items-table .sku-box{
    width:116px!important;
}
.items-table .name-box{
    width:205px!important;
}
.items-table .description-box{
    width:328px!important;
    word-wrap: break-word;

}
.items-table .price-box{
    width:242px!important;
}
.items-table .name-box{
    width:auto!important;
}
.items-table td.description-box{
    text-align: right!important;
}
.items-table td,
.items-table th{
    font-size: 22px;
    position: relative;
}
.items-table tfoot th{
    font-size: 22px!important;
    padding:16px 8px!important;
}
.items-table tbody td,
.items-table thead th{
    font-size: 22px!important;
    padding: 20px 8px!important;
}
.items-table tbody td span{
    font-size: 22px;
}
.items_content.items-page{
    margin: 0 0;
}
.en .fromdp.fromLabelDp{
    top:3px;
    left: 58px;
}
.he .fromdp.fromLabelDp{
    top:3px;
    right: 64px;
}
.en .todp.toLabelDp{
    top:3px;
    right: 58px;
}
.he .todp.toLabelDp{
    top:3px;
    left: 62px;
}

.item-form {

    .required-red {
        color: #ed1c24;
    }

    .form-group {
        color: #9d9d9b;

        input,
        select {
            color: inherit;
        }
    }

    .separator {
        display: inline-block;
        vertical-align: middle;
        padding: 0 5px;
    }
}

.name-block-data {
    text-decoration: underline;
    display: inline-block;
}