input[type='file'] {
    color: transparent;
}

h6.signature, h6.logo {
    margin: 5px;
    font-size: 14px;

    small {
        font-size: inherit;
    }
}

label.upload-button {
    cursor: pointer;
    background-color: #888888;
    margin-top: 10px;
    color: white;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 5px;

    &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 10px;
        height: 13px;
        background: url(../../../assets/img/sprite_tbl_icons.png) 0 -1050px no-repeat;
        margin: -4px 0 -2px 5px;
    }
}


hr {
    height: 1.5px !important;
    color: #cccccc;
    background-color: #cccccc;
    border: none;
    margin: 20px 0 20px 0;
}

#dtComment > tfoot {
    display: none;
    visibility: hidden;
}

#dtComment > thead > tr {
    > th:focus, > th:hover {
        color: #fff;
        background-color: #0D83DD;
    }
}

#dtComment > thead > tr > th {
    color: #3c3c3c;
    background: #fff;
    border: transparent;
}


#dtComment:nth-child(odd) {
    background: #fff;
}

#dtComment_info {
    display: none;
    visibility: hidden;
}

#dtComment:nth-child(even) {
    background: #ededed;
}

label {
    color: #3c3c3c;
}

div.alert-custom {
    margin-bottom: 0px;
}

.template-select-block {
    overflow-x: hidden;
    overflow-y: scroll;
    width: 48%;
    height: 100%;

    float: left;
    direction: ltr;
    .template {
        img {
            border: 1px solid silver;
        }
        width: 145px;
        padding: 1px;
        cursor: pointer;
        height: 204px;
        margin-left: 5px;
        margin-bottom: 5px;
    }
    .template.active {
        img {
            border-width: 3px;
        }
    }
}

.selected-template-block {
    img {
        border: 1px solid silver;
    }
}

.templates-block {
    height: 435px;
}
