.simpleDemo ul[dnd-list],
.simpleDemo ul[dnd-list] > li {
    position: relative;
}

.dt-loading {
    text-align: center;
}

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop to it once it's empty
 */
.simpleDemo ul[dnd-list] {
    min-height: 42px;
    padding-left: 0;
}

/**
 * The dndDraggingSource class will be applied to
 * the source element of a drag operation. It makes
 * sense to hide it to give the user the feeling
 * that he's actually moving it.
 */
.simpleDemo ul[dnd-list] .dndDraggingSource {
    display: none;
}

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
.simpleDemo ul[dnd-list] .dndPlaceholder {
    display: block;
    background-color: #ddd;
    min-height: 42px;
}

/**
 * The dnd-lists's child elements currently MUST have
 * position: relative. Otherwise we can not determine
 * whether the mouse pointer is in the upper or lower
 * half of the element we are dragging over. In other
 * browsers we can use event.offsetY for this.
 */
.simpleDemo ul[dnd-list] li {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: block;
    padding: 10px 15px;
    margin-bottom: -1px;
}

/**
 * Show selected elements in green
 */
.simpleDemo ul[dnd-list] li.selected {
    background-color: #dff0d8;
    color: #3c763d;
}

.dragndrop-table tr.ui-sortable-helper {

    width: 100%;
    display: table;
}

.table > tfoot > tr > th {
    padding: 3px 8px;
}

.table > thead > tr > th:hover .x-btn {
    display: block;
}

.x-btn {
    display: none;
}

.active-column {

}

.tpicker-icon {
    font-size: 27px;
    color: #bfbfbf;
}

#time-cominicatiion:before {
    position: absolute;
    left: -30px;
    content: "a";
    width: 10px;
    height: 10px;
}

.button-wrapper {
    float: left;
}

.print_row, .duplicate, .create_document {
    cursor: pointer;
    display: inline-block;
    float: right;
    background-color: #888;
    border: 1px solid #515253;
    border-radius: 2px;
    width: 35px;
    height: 26px;
    text-align: center;
    color: #fff;
    margin: 0 1px;
}

tr:hover .print_row, tr:hover .duplicate, .duplicate.static, .print_row.static, .create_document.static {
    visibility: visible;
    background-image: url(../../img/sprites.png);
    background-repeat: no-repeat;
}

.print_row {
    background-position: 2px -66px;
}

.duplicate {
    background-position: 1px -91px;
}

.create_document {
    background-position: 2px -415px;
}

.print_row, .duplicate {
    visibility: hidden;
}


.dt-buttons {
    position: relative;
    float: right;
    margin-right: 59px;
}

.dt-buttons .btn-info.disabled {
    background-color: #419ee5;
}

.dataTables_wrapper .dt-buttons .btn-sm i {
    margin-left: 7px;
}

.dt-buttons:after {
    position: absolute;
    right: -47px;
    top: 16px;
    content: " ";
    display: inline-block;
    width: 52px;
    height: 46px;
    background-image: url(../../img/arrow-gray.png);
    background-repeat: no-repeat;
}

.x-btn {
    position: absolute;
    content: ' ';
    right: -6px;
    top: -17px;
    width: 25px;
    height: 25px;
    background-image: url(../../img/sprites.png);
    background-repeat: no-repeat;
    background-position: -2px -357px;
    z-index: 9999;
}

.timepicker {
    direction: ltr;
}

.he .indicator-inner {
    float: left;
    margin-right: 5px;
}

.remember-box label {
    float: right;
}

.dragndrop-table tr.ui-sortable-helper td {

}

.dragndrop-table tr.ui-sortable-helper td th:hover {

}

.dataTable {
    direction: ltr;
}

.form-inline tbody.dragndrop-table tr.selected td {
    background: #e7f4fe;
}

table.dataTable td {
    position: relative;
}

table.dataTable th.select-checkbox:before {
    display: none;
}

table.dataTable tr.selected td.select-checkbox .check-area:after {
    font-family: 'FontAwesome';
    font-size: 16px;
    content: "\f00c";
    text-align: center;
    position: absolute;
    display: block;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

}

table.dataTable td.select-checkbox .check-area:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    border: 1px solid #b0aeae;
    width: 16px;
    height: 16px;
    /* border-radius: 3px; */
}

.search-table {
    width: 30px;
    height: 30px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.search-table:after {
    content: " ";
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 2px;
    height: 34px;
    width: 30px;
    margin-left: -15px;
    background: url(../../img/sprites.png) no-repeat 0 -120px;;
    color: #878787;
}


.edit {
    font-size: 13px;
    color: #000;
}

.check-area {
    width: 33px;
    height: 33px;
    position: relative;
    //left: 50%;
    //top: 50%;
    //-webkit-transform: translate(-50%,-50%);
    //-moz-transform: translate(-50%,-50%);
    //-ms-transform: translate(-50%,-50%);
    //-o-transform: translate(-50%,-50%);
    //transform: translate(-50%,-50%);
    cursor: pointer;
    vertical-align: middle;
    display: inline-block;
    //vertical-align: top;
}

.btn-customer-action {
    font-size: 13px;
    padding: 3px 10px;
    font-weight: bold;
}

.btn-edit {
    background-color: #888888;
    color: #fff;
    border: 1px solid #666666;
}

.btn-edit[disabled],
.btn-edit[disabled]:hover {
    background-color: #dadada;
    color: #c6c6c6;
    border: 1px solid #d0d0d0;
}

.btn-edit:hover {
    color: #fff;
}

.btn-customer {
    padding: 5px 12px;
    font-size: 21px;
    font-weight: bold;
}

.btn-customer i {
    font-size: 20px;
    position: relative;
    margin-left: 10px;
    opacity: .7;
}

.customer-edit-title {
    font-size: 30px;
    font-weight: bold;
    margin-top: -5px;
}

.brdr1 {
    border-right: 1px solid #ccc;
}

.edit hr {
    color: #ccc;
}

.icon-help {
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 3px;
    background: #0D83DD url(../../img/ico_help.png) center no-repeat;
    text-indent: -9999px;
    margin: 3px 0 0 0;
}

.edit input, select {
    font-size: 14px;
    height: 25px;
}

.new-communication {
    font-size: 15px;

    textarea {
        resize: none;
    }
    .border-dashed {
        border: 1px solid #ccc;
    }
    .bg-blue {
        background-color: #F6FBFC;
    }
    .btn-file {
        position: relative;
        overflow: hidden;
    }
    .btn-file input[type=file] {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        font-size: 100px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        outline: none;
        background: white;
        cursor: inherit;
    }
    .text-left {
        text-align: left;
    }
}

.wrapper-box {
    text-align: left;
    margin-right: 0;
    padding: 15px 10px 15px 0;
    background: #f6f6f6;
}

.wrapper-box .customer-form-input {
    height: 25px;
}

.file-upload-input {
    background: #f6f6f6
}

.new-communication-modal {
    .modal-footer {
        text-align: left;
    }
}

.expand-button, a.expand-button {
    color: #c7c7c7;
    font-size: 29px;
    vertical-align: middle;
    margin-left: 3px;
    margin-top: -2px;
}

th.tbl_search_find_button {
    border: none !important;
    margin-left: 13px;
    text-align: center;
    margin-top: 10px;
}

.tbl_search_find_button {
    display: block;
    width: 22px;
    height: 28px;
    background: url(../../img/ico_users_tbl_search.png);
    margin: auto;
}

.items_content {
    .nav.nav-tabs {
        background: none;
        margin: 0;
        li {
            margin: 0 -2px 0 6px;
            a {
                color: white;
                background-color: #6eb7eb;
                box-shadow: 0 0 0 1px #0d83dd;
            }
        }
        li.active {
            a {
                background-color: #0D83DD !important;
            }
        }
    }

    .tab-content > .tab-pane {
        margin-top: 0;
    }
    .dataTables_wrapper > .row {
        //display: none;
    }

    .filter-date-range {
        text-align: center;
        .form-control {
            width: 40%;
        }
    }
    .noTableLine tbody tr.disabled:hover td,
    .noTableLine tbody tr.selected td,
    .noTableLine tbody tr.selected:nth-of-type(even) td,
    .noTableLine tbody tr.selected:nth-of-type(odd) td,
    .noTableLine tbody tr:hover td {
        text-decoration: none;
    }

    .noTableLine tbody tr.disabled:hover td span .id-link,
    .noTableLine tbody tr.selected td span .id-link,
    .noTableLine tbody tr.selected:nth-of-type(even) td span .id-link,
    .noTableLine tbody tr.selected:nth-of-type(odd) td span .id-link,
    .noTableLine tbody tr:hover td span.id-link {
        text-decoration: underline;
    }

    //.table > tbody > tr > td,
    //.table > tbody > tr > th,
    //.table > tfoot > tr > td,
    //.table > tfoot > tr > th,
    //.table > thead > tr > td,
    //.table > thead > tr > th {
    //    text-decoration: none;
    //}
    //.table tr td.name {
    //    text-decoration: underline;
    //    cursor: pointer;
    //}
}

.items_content {
    .nav.nav-tabs {
        background: none;
        margin: 0;
        position: relative;
        z-index: 3;
        li {
            margin: 0 -2px 0 6px;
            font-size: 20px;
            a {
                text-decoration: none;
                color: white;
                background-color: #6eb7eb;
                box-shadow: 0 0 0 1px #0d83dd;
                font-size: inherit;
            }
        }
        li.active {
            a {
                font-weight: bold;
                background-color: #0D83DD !important;
            }
        }
    }

    .tab-content > .tab-pane {
        margin-top: 0;
    }
    .dataTables_wrapper > .row {
        //display: none;
    }

    .filter-date-range {
        text-align: center;
        .form-control {
            width: 40%;
        }
    }

}

.business_activity_graph {
    float: right;
    width: 100%;
    width: 628px;
    height: 280px;
    /*
    margin:0 0 0 -230px;
    padding:30px 20px 0 250px;
    */
    padding: 30px 20px 0 20px;
    .gw_graph {
        width: 100%;
    }
}

.modal {
    .graph_widget_tabs {
        width: 136px;
    }
    .business_activity_graph {
        width: 500px;
    }

    .graph_widget_graph_box {
        width: 444px;
        float: left;
        margin: 0 0 0 24px;
        padding: 0;
    }

    .brief_info_box {
        float: left;
        width: 240px;
        border-right: 1px solid #CCC;
        margin: 12px 0 12px 0;
        height: 100%;
        padding: 33px 13px 60px 11px;
        direction: rtl;
        h4 {
            font-size: 26px;
            line-height: 24px;
            color: #000;
            border-bottom: 1px solid #CCC;
        }

        section {
            border-bottom: 1px solid #CCC;
            font-size: 25px;
            margin: 0 0 13px 0;
            padding: 0 4px 0 0;
        }

        section:last-child {
            margin: 0;
        }

        a, .brief-value {
            color: #0D83DD;
        }
    }
}

.nav.nav-tabs {
    .dt-buttons {
        display: none;
    }
}

.customer-documents-chart.std_block {
    .std_block_shadow {
        display: block;
    }
}

.business_activity_graph {
    float: right;
    width: 100%;
    width: 628px;
    height: 280px;
    /*
    margin:0 0 0 -230px;
    padding:30px 20px 0 250px;
    */
    padding: 30px 20px 0 20px;
    .gw_graph {
        width: 100%;
    }
}

.customer-documents-chart.std_block {
    .std_block_shadow {
        display: block;
    }

    .graph_widget_tabs_valign {
        text-align: center;
        padding: 10px;
    }
}

.he {
    .character-indicator {
        direction: rtl;
    }
}

.blue_font {
    color: #0d83dd !important;
}

.blue_font:hover {
    color: #6eb5eb !important;

}

.status-state {
    cursor: pointer;
}

.id-link {
    cursor: pointer;
}

.time-select-popup {

    font-size: 16px;

    .dropdown-menu {
        padding: 6px;
    }
    @slider-gray: silver;

    .btn-time-select-action {
        font-size: 14px;
        padding: 0;
        height: 26px;
    }

    .title {
        border-bottom: 1px solid silver;
        font-size: 17px;
        text-align: right;
        display: block;
        font-weight: 700;
    }

    .timer {
        color: grey;
    }

    .slider.slider-horizontal {

        .slider-selection, .slider-track-high {
            background: white none;
            border-radius: 3px;
            border: 1px solid @slider-gray;
        }

        .slider-handle {
            background: #ededed none;
            width: 15px;
            height: 15px;
            margin-top: -3px;
            border: 1px solid @slider-gray;
            border-radius: 3px;
        }
        .slider-handle:focus {
            background: silver;
        }
    }
}

.select-date-range-popup {
    .input-container > div {
        padding-top: 12px;
        cursor: pointer;
    }
}

.managed_customers_main {

    .items_content .table > tbody > tr > td,
    .items_content .table > tbody > tr > th,
    .items_content .table > tfoot > tr > td,
    .items_content .table > tfoot > tr > th,
    .items_content .table > thead > tr > td,
    .items_content .table > thead > tr > th {
        text-decoration: none;
    }
    .items_content .table tr td.name {
        text-decoration: underline;
        cursor: pointer;
    }

    // Select
    .ui-select-bootstrap > .ui-select-match > .btn {
        background: #F6F6F6;
        border: 2px inset #e5e5e5;
    }
    .ui-select-bootstrap .ui-select-toggle > .caret {
        left: 5px;
        right: inherit;
    }

    .ui-select-match {
        box-shadow: none;
        background: none;
    }

    // Top block
    .smile_man_block {
        background: #F6F6F6;
        min-height: 90px;
        margin: 0 0 24px 0;
        position: relative;
        clear: both;
    }

    /*.title.help-title{
        font-size: 23px;
        border-left: 1px solid black;
        height: 27px;
        display: block;
        float: left;
        padding-left: 5px;
        margin-left: 9px;
        margin-top: 4px;
    }*/
    .smile_man_block:after {
        content: '';
        display: table;
        clear: both;
    }

    .smb_right {
        float: right;
        display: table-cell;
        // border-left:1px solid #b5b5b5;
        // padding:8px 51px 0 19px;
        width: 230px;
        margin: 30px 0 21px 0;
    }

    .smb_left {
        width: 98%;
        float: left;

        padding: 17px 17px 21px 17px;
    }

    .smb_right + .smb_left {
        padding-top: 30px;
        padding-right: 227px;
        margin: 0 -210px 0 0;
    }
}

.managed_customers_inside {

    // Graph
    .graph_2 .graph_widget_graph_box {
        width: 444px;
        float: left;
        margin: 0 -220px 0 24px;
    }
    .graph_widget_container {
        .content {
            width: 80%;
            float: left;
            h3 {
                background: #f6f6f6;
                border-radius: 8px 0 0 0;
                color: #9D9D9C;
                padding: 9px 23px 2px 0;
                font-size: 39px;
                line-height: 45px;
            }
        }
        .graph_widget_tabs {
            width: 20%;
            a {
                height: 190px;
            }
        }
    }

    // Head
    .btn {
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-group .form-control {
        padding: 17px 12px;
    }
    .smile_man_block {
        padding: 20px;
        .smb_right {
            width: 23%;
            float: right;
        }
        .smb_left {
            width: 77%;
            float: left;
        }
    }

    // Table
    .print {
        width: inherit;
        background-image: none;
    }
}

.managed_customers_invited {

    .smb_links > li {
        font-size: 35px;
        line-height: 38px;
        /* float: right; */
        display: inline-block;
        margin: 0 0 0 27px;
        position: relative;
        color: #575756;
        vertical-align: middle;
        min-height: 38px;
    }

    .smb_links > li:after {
        content: '|';
        display: block;
        position: absolute;
        left: -18px;
        top: 0;
    }

    .smb_links > li:first-child {
        margin: 0 0 0 10px;
    }

    .smb_links > li:first-child:after {
        display: none;
    }

    .smb_links > li:last-child {
        margin: 0;
    }

    .smb_links > li:last-child:after {
        display: none;
    }

    .smb_links > li a {
        color: #575756;
        font-size: 35px;
    }

    // Top block
    .smile_man_block {
        background: #F6F6F6;
        min-height: 90px;
        margin: 0 0 24px 0;
        position: relative;
        clear: both;
    }

    .smile_man_block:after {
        content: '';
        display: table;
        clear: both;
    }

    .smb_right {
        float: right;
        display: table-cell;
        // border-left:1px solid #b5b5b5;
        // padding:8px 51px 0 19px;
        width: 230px;
        margin: 30px 0 21px 0;
    }

    .smb_left {
        width: 98%;
        float: left;

        padding: 17px 17px 21px 17px;
    }

    .smb_right + .smb_left {
        padding-top: 30px;
        padding-right: 227px;
        margin: 0 -210px 0 0;
    }

    .the_button.load_more_action:before {
        display: none;
    }
    .the_button.load_more_action {
        font-size: 19px !important;
    }

    .ordering_tbl td {
        font-style: 22px;
        height: 22px;
        line-height: 22px;
        border-right: 2px solid #575757;
        text-align: center;
        border-bottom: 10px solid #FFF;
        padding: 0px 9px 0px 9px;
    }
}

.green_check_mark {
    height: 15px;
    width: 15px;
    background: url(../../img/galochka-check.png) no-repeat center;
    background-size: contain;
}

// Customer poppup
.add_customer_poppup {

    // Links
    .view_all_requests {
        position: relative;
        margin-left: 30px;
        .triangle_left {
            width: 0;
            height: 0;
            border: 9px solid transparent;
            border-right: 9px solid #0969b0;
            margin: 15px auto 0 auto;
            top: -8px;
            left: -25px;
            position: absolute;
        }
        .triangle_down {
            width: 0;
            height: 0;
            border: 9px solid transparent;
            border-top: 9px solid #0969b0;
            margin: 15px auto 0 auto;
            top: -3px;
            left: -25px;
            position: absolute;
        }
    }

    h2 {
        margin-top: 20px;
        font-weight: 700;
        font-size: 25px;
    }

    .select_filter {
        padding: 0;
    }

    textarea {
        width: 100%;
        padding: 15px;
        background: #f6f6f6;
        border: none;
        direction: rtl;
        resize: none;
    }

    .row {
        margin-top: 30px;
    }

    .button_wrapper {
        text-align: center;
        margin-top: 40px;
    }

    .gray_color {
        background: #f6f6f6;
        padding: 15px;
        border: 1px solid #f6f6f6;
        box-shadow: none;
    }

    .col-xs-6 {
        h2 {
            margin: 0;
        }
        .form-control {
            width: 70%;
            margin: auto;
        }
    }

    // Table
    .items_content {
        margin: 0;
    }
    .hidden_area {
        margin: 0;
    }
    .std_block_4 {
        border: none;
        background: none;
    }
    td {
        min-width: 100px;
    }
    .delete_row_td {
        min-width: 30px;
    }
    .red_font {
        color: #ff0904;
    }
    td .has-error .help-block {
        height: 10px;
    }

    .green_check_mark {
        position: absolute;
        right: 11px;
        top: 50%;
        margin-top: -7px;
    }

    // Button
    .the_button {
        font-size: 17px !important;
        min-width: 85px;
        padding: 0;
        padding-left: 5px;
        height: 30px !important;
    }
    .the_button.load_more_action:before {
        display: none;
    }
    .close_button.button_add.big-btn:after {
        transform: rotate(45deg) !important;
    }
    .close_button {
        background: #ff0904;
        border: 1px solid #c71417;
    }
    .close_button:hover {
        background: #ff0904;
        border: 1px solid #c71417;
    }

    .the_button.load_more_action {
        font-size: 19px !important;
    }

    .mb20 {
        margin-bottom: 20px;
        .dt-buttons {
            width: 100%;
            margin-right: 0;
            padding-right: 80px;
        }
        .dt-buttons:after {
            right: 20px;
        }
        .btn {
            padding-right: 35px;
            background-color: #74ab1b;
            border-color: #659C17;
            float: right;
            .description {
                color: #999999;
                text-decoration: none;
            }
        }
        .btn:after {
            content: "\f067";
            font: normal normal normal 23px/1 FontAwesome;
            -webkit-transform: translatey(-50%);
            -ms-transform: translatey(-50%);
            transform: translatey(-50%);
            width: 10px;
            height: 10px;
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    .ml10 {
        font-size: 34px;
    }
}

.items_content .filter-date-range {
    width: 150px;
}

.dt-buttons button {
    margin-bottom: 9px;
}

input.has-error, select.has-error {
    border-color: #ed1c24;
}

input.has-error:focus, select.has-error:focus {
    border-color: #ed1c24;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px #f90b00;
}

.page_title_type_1 h2 span {
    font-size: 40px;
}

.choose_customer {
    background: #ddedfd;

    .col-xs-8 {
        padding: 0;
    }

    // Select
    .ui-select-container {
        width: 30%;
        padding: 10px 5px;
    }
    .ui-select-choices-group {
        width: 100%;
    }
    .ui-select-bootstrap .ui-select-toggle > .caret {
        right: inherit;
        left: 10px;
    }
    .ui-select-bootstrap > .ui-select-match > .btn {
        padding-left: 40px;
    }

    // List
    .nav > li > a {
        padding-top: 0;
        padding-bottom: 0;
        padding: 10px 15px;
        font-size: 20px;
        position: relative;
    }
    .nav > li > a:before {
        content: "";
        position: absolute;
        left: 0;
        top: 5px;
        bottom: 5px;
        width: 1px;
        background: #a3cff4;
    }
    .nav > li:hover {
        background: #a3cff4;
    }
    .nav > li:hover a {
        color: #fff;
        cursor: pointer;
        background: #a3cff4;
    }
    ul {
        li {
            display: inline-block;
            font-size: 18px;
        }
    }
}

.date_col_small {
    width: 150px;
}

.subject_col {
    width: 160px;
}

.contact_col {
    width: 130px;
}

.file_col {
    width: 50px;
}

.title_col {
    width: 150px;
}

.details-wrapper {
    display: table;
    width: 100%;

    .button-block-wrapper {
        display: table-cell;
        width: 1%;
        white-space: nowrap;
    }
    .edit-customer-block {
        display: table-cell;
        word-wrap: break-word;
        width: 60%;

        .attribute-row {
            width: 100%;
            height: 100%;
            display: table-row;

            .label-column {
                display: table-cell;
                padding-right: 15px;
                font-weight: bold;
                .label-icon {

                    float: right;
                    margin-left: 3px;
                }
            }
            .value-column {
                display: table-cell;
                padding-right: 15px;
                white-space: -moz-pre-wrap !important;  /* Mozilla, since 1999 */
                white-space: -webkit-pre-wrap; /*Chrome & Safari */
                white-space: -o-pre-wrap;      /* Opera 7 */
                word-wrap: break-word;         /* Internet Explorer 5.5+ */
                word-break: break-all;
                white-space: normal;
            }
        }
        .no-label-column {
            padding-right: 15px;
            display: table-cell;
        }
        .no-label-column:after{
            display: table-cell;
            content: '';
        }
    }
}

.no-padding {
    padding-left: 0;
    padding-right: 0;
}

.default-buttons {
    .header-button {
        line-height: 16px;
        padding: 1px 1px 2px;
        width: 37px;
        height: 34px;
    }
}

.comminication-buttons .mr10 {
    margin-right: 5px;
}

.btn-add-circle {
    width: 18px;
    height: 18px;
    background: #659C17;
    color: #fff;
    border: none;
    border-radius: 50%;
    font-size: 12px;
    padding: 3px;
    vertical-align: middle;
    display: inline-block;
}

.comunication-edit-popup {
    margin: 50px 15px 35px 90px;
}

.customers-status.status-state {
    background: url(../../img/sprites.png) no-repeat 50% 11px;
}

.disabled .customers-status.status-state {
    background: url(../../img/closed-customer-icon.png) no-repeat;
    background-size: 100%;
    height: 21px;
    background-position: bottom;
}

.exempt_documents {
    .nav-tabs > li > a {
        font-size: 19px;
    }
    .nav > li > a {
        padding: 10px 9px;
    }
}

.heading-document {
    unicode-bidi: embed;
    direction: ltr;
}

.customer-form-input .ui-select-toggle {
    height: 25px;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
}

.ltr-direction {
    direction: ltr !important;
    text-align: right;
}

.customers-table tr:hover {
    .name-box-button-block {
        display: block;
        min-width: 67px;
    }
}

.customers-table {
    thead th {
        white-space: nowrap;
    }
}

.customers-table .name-box {
    cursor: pointer;
    display: block;
    width: 100%;
    direction: ltr;
    .name-box-text-block,
    .name-box-button-block {
        vertical-align: middle;
        display: table-cell;
    }

    .name-box-button-block {
        text-align: left;
        display: none;
        text-decoration: none;
    }
    .name-box-text-block {
        text-align: right;
        text-decoration: underline;
        word-wrap: break-word;
        display: block;
        width: 100%;
        .no-underline-wrapper {
            text-decoration: none;
        }
    }
}

.he {

    .select-date-range-popup {
        direction: rtl;
        .dp-from, .dp-to {
            float: right;
        }
    }
}

.communication-content-row {
    white-space: pre;
}

.import-customers-preview-popup {
    .overflow-wrapper {
        overflow-x: scroll;
    }
    .width-wrapper {
        width: 2500px;
    }
    table {

        direction: rtl !important;

        td.duplicate-error {
            background-color: #fceedb !important;
        }
        td.missing-info-error {
            background-color: #ffc8c6 !important;
        }
    }
}

.select-date-range-popup .input-container > div {
    padding-top: 9px;
    line-height: 1;
}

.export-managed-customer-popup {
    .tab-content > .tab-pane.active {
        padding: 20px;
    }
}

.customer-documents-chart-wrap {

    .brief_info_box {
        margin-top: 75px;
        padding: 33px 13px 35px 11px;
    }

    .graph_2 .graph_widget_graph_box {
        padding-top: 95px;
    }

    .graph_widget_tabs a {
        min-height: 170px;
        color: #0a81dd;
    }
}

.customer-documents-chart-title {
    background: #f6f6f6;
    border-radius: 8px 0 0 0;
    color: #9D9D9C;
    padding: 9px 23px 2px 0;
    font-size: 35px;
    line-height: 45px;
    position: absolute;
    top: 0;
    left: 0;
    right: 136px;

    h3 {
        font-weight: bold;
        color: inherit;
        margin: 0;
        font-size: inherit;

    }
}


.customer-section-popup {
    .big_help_popup {
        width: 400px;
    }
}

.address-title-row {
    font-size: 22px;
}
//.customer-table-wrapper{
//    overflow-x: scroll;
//    table.dataTables_wrapper{
//        min-width: 1650px!important;
//        td{
//        }
//    }
//}

.customer-table-wrapper {
    table.dataTable thead th {
        overflow: visible;
        padding: 5px 10px;

        &.sorting_desc:before, &.sorting_asc:before {
            position: absolute;
            top: 10px;
            left: 0;
            line-height: 1;
        }
    }
}

.amount-holder {
    .money-amount{
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        display: block;
    }
}

th.amount-holder {
    span:first-child:not(.x-btn){
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        display: block;
    }
}

.my_elipsis{
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    display: block;

    //&[my-title]:hover:after {
    //    content: attr(my-title);
    //    padding: 2px;
    //    z-index: 999;
    //    top: 20px;
    //    position: absolute;
    //    left: 10px;
    //    background: #000;
    //    color:#fff;
    //}
}

.edit-attribute-page,
.edit-attribute-value-page {
    font-size: 22px;
}

.edit-attribute-value-page {
    .title-block {
        background-color: #f6f6f6;
        padding: 15px;
        .title-block-wrapper {
            display: table;

            .title-block-value {
                color: #B2B2B2;
            }

            .title-block-label,
            .title-block-value {
                padding: 3px;
                display: table-cell;
            }
        }
    }
}

.attributes-page {

    table.attribute-table {
        width: 100%;
        tbody,
        thead {
            tr {

                th {
                    font-weight: bold;
                    font-size: 16px;
                }
                td {
                    border: 1px solid rgb(221, 221, 211);
                    background-color: #f9f9f9;
                    font-size: 13px;
                }

                td, th {
                    text-align: right;
                    vertical-align: middle;
                    padding: 3px 6px;
                }
                td.actions-column,
                th.actions-column {
                    width: 150px;
                    text-align: center;
                    border: none;
                    background: none;

                    > button {
                        font-size: 13px;
                        font-weight: bold;
                        line-height: 16px;
                    }
                }
            }
        }
    }

}

.edit-attribute-page {

    .add-choice-block {
        a {
            font-size: 22px;
            cursor: pointer;
            color: #679918;
        }
    }
    .choice-wrapper {

        width: 100%;
        display: table;
        .choice-row {
            display: table-row;

            .choice-input {
                padding-bottom: 6px;
                display: table-cell;
            }
            .choice-remove-button {
                padding-left: 6px;
                padding-top: 6px;
                padding-bottom: 6px;
                display: table-cell;
                width: 5px;
                > a {
                    cursor: pointer;
                    color: #ed1c24;
                }
            }
        }
    }
}

.managed-customers-date-filter {

    .dateRangeReportHolder {
        white-space: nowrap;
        margin: 0 -60px;

        input {
            width: 107px !important;
            white-space: normal;
        }
    }
}


.dt-button-collection {

    .buttons-columnVisibility {

        &[data-cv-idx="14"], &[data-cv-idx="13"]{
            display: none;
        }
    }
}
