input.form-control, select.form-control {
    padding: 0 8px;
}
.en input,.en select,.en textarea{
    direction: ltr;
}
.en .form-group div,.en .tab-content .form-group,.en .text-right{
    text-align: left;
}

.en .div.small-padding{
    padding-left: 13px;
    padding-right: 6px;
}
input.business {
    font-size: 22px;
    line-height: 22px;
}

div.language-checkbox {
    margin-right: 15px !important;

    .language-label{

    }
}
.he div.language-checkbox {
    direction: ltr;
    text-align: right;
}


.step .info{
    padding: 40px 50px 20px 20px;
}
.info{
    padding: 40px 50px 20px 25px;
    .address {
        position: relative;
    }
    .title-info{
        font-size: 19px;
        padding-right: 0;
        margin-bottom: 20px;
        color: #575757;
    }
    label{
        float: right;
    }
    .phone-position{
        position: absolute;
        width: 31px;
        height: 36px;
        display: inline-block;
        background: url(../../img/sprite-icon.png) no-repeat 0 -65px;
        right: -18px;
        bottom: -41px;
    }
}
.box-input{
    margin-bottom: 20px;
}
.save-box{
    a{
        margin-top: 5px;
        color: #000000;
        font-size: 20px!important;
        color: #0000FF;
        text-decoration: underline;
        &:hover{
            color: #0000ff;
        }
    }
    button{
        color: #ffffff;
        font-size: 20px;
        padding: 8px 12px;
        line-height: 1!important;
        margin-left: 10px;
        border: 1px solid #659C17!important;
        border-radius: 5px;
        background: #85C51F;
        font-weight: bold!important;
        &:hover{
            border: 1px solid #172205!important;
        }

    }
    a,button{
        float: left;
    }
}
.alert-message{
    margin-top: 8px;
    font-weight: bold;
    
}
.contact-box{
    position: relative;
    .delete-button{
        position: absolute;
        left: -18px;
        display: block;
        direction: ltr;
        z-index: 9999;
        a{
            width: 15px;
            display: block;
        }
    }
}
div.add_address, div.add_contact, div.add_account {
    width: 100%;
    height: 45px;
    padding: 12px 10px 0  0;
    margin: 0;
    line-height: 34px;
    color: #575757;
    border-top: 1px solid #c6e9a2;
    margin-left: 0!important;
    margin-right: 0!important;
    margin-top: 20px;
}
.info .button_add{
    float: left;
}
.button_add {
   position: relative;
    display: inline-block;
    border: 1px solid #659C17;
    border-radius: 5px;
    background: #85C51F;
    color: #FFF;
    font-size: 16px;
    padding: 8px 25px 8px 12px;
    font-weight: 700 !important;
    line-height: 1;
    outline: 0;
    &:hover{
        color: #fff;
        text-decoration: none;
        background-color: #679918;
    }
    &:after{
        position: absolute;
        font: normal normal normal 16px/1 FontAwesome;
        content: "\f067";
        font-weight: 700;
        color: #cee8a5;
        right: 6px;
        top: 50%;
        display: inline-block;
        transform: translatey(-50%);
    }
}
.en .button_add.big-btn{
    padding: 6px 30px 5px 12px;
    font: 32px 'fahrenheit', Arial, Helvetica, sans-serif;
    &:after{
        font: normal normal normal 23px/1 FontAwesome;
        right: 6px;
        transform: translatey(-50%);
    }
}
.he .button_add.big-btn{
    padding: 7px 30px 4px 12px;
}
.en .button_add.normal-btn{
    font: 22px 'fahrenheit', Arial, Helvetica, sans-serif;
    padding: 7px 30px 6px 12px;
    &:after{
        font: normal normal normal 16px/1 FontAwesome;
        right: 6px;
        transform: translatey(-50%);
    }
}
.he .button_add.normal-btn{
    font: 22px 'fahrenheit', Arial, Helvetica, sans-serif;
    padding: 6px 30px 4px 12px;
    &:after{
        font: normal normal normal 16px/1 FontAwesome;
        right: 6px;
        transform: translatey(-50%);
    }
}
.en .button_add.small-btn{
    padding: 6px 25px 4px 15px;
    border-radius: 2px;
    font: 19px 'fahrenheit', Arial, Helvetica, sans-serif;
    &:after{
        font: normal normal normal 16px/1 FontAwesome;
        right: 6px;
        transform: translatey(-50%);
    }
}
.he .button_add.small-btn{
    padding: 7px 30px 6px 20px;
    border-radius: 2px;
    font: 19px 'fahrenheit', Arial, Helvetica, sans-serif;
    &:after{
        font: normal normal normal 16px/1 FontAwesome;
        right: 6px;
        transform: translatey(-50%);
    }
}
body {
    font: 24px/1.4 'fahrenheit', Arial, Helvetica, sans-serif;
    color: #575757;
    -webkit-text-size-adjust: none;
}

div.boxes-container {
    font-size: 18px;
}

div.delete-button {
    padding-top: 5px;
}

div.delete-button > a {
    margin-right: -15px;
}

div.small-padding {
    padding-left: 6px;
    padding-right: 13px;
}

div.form-group.contact_details_title{
    margin: 0;
    padding: 12px 10px 0 0;
}
.step-two-page{

    .flag-column{
        width: 23px;
        float: right;
        margin-right: 35px;
        margin-left: 0;
    }

    .en-row{
        .flags{
            text-align: center;
        }
        input.business{
            text-align: left;
        }
        text-align: left;
    }
}
.mail_info {
    display: inline-block;
    float: left;
    width: 40%;
    .postcode-column{
        width: 50%;
        float: left;
        padding:0 3px;
    }
    .mailbox-column{
        width: 50%;
        float: left;
        padding:0 3px;
    }
}
.address_info{
    display: inline-block;
    float: left;
    width: 60%;
    .city-column{
        width: 60%;
        float: left;
        padding:0 3px;
    }
    .street-column{
        width: 40%;
        float: left;
        padding:0 3px;
    }
}
.definition-name.has-error {
    color: #930600;
}

.house-icon{
    width: 31px;
    height: 28px;
    display: inline-block;
    background: url(../../img/sprite-icon.png) no-repeat 0 0;
    position: absolute;
    right: -22px;
}