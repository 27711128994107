.message_system_links {
  padding: 0 8px 0 8px;

  li {
    border-bottom: 1px solid #CCC;
    padding: 17px 0 14px 0;
    font-size: 24px;
    color: #575756;
  }

  li:last-child {
    border: none;
  }

  li a {
    color: #575756;
    display: inline-block;
    position: relative;
  }

  li .remove-message {

    cursor: pointer;
    margin-left: 6px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: url(../../img/ico_delete.png);
  }
}


