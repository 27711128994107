
.the_button_2.ordering_refresh {
    display:block;
    height:22px;
    width:65px;
    float:left;
    margin:0 7px 0 0;
    font-size:0px;
    text-indent:-9999px;
    background-image:url(../../img/ico_refresh_white.png);
    background-repeat:no-repeat;
    background-position:center;
}

.ordering_box {
    padding:23px 36px 17px 20px;
}

.ordering_box:after {
    content:'';
    display:table;
    clear:both;
}

.ordering_tbl {
    width:100%;
    margin:0 0 22px 0;
}

.ordering_tbl td {
    height:22px;
    line-height:22px;
    border-right:2px solid #575757;
    text-align:center;
    border-bottom:10px solid #FFF;
    padding:0px 9px 0px 9px;
    vertical-align: middle;
}

.ordering_tbl td:first-child {
    border-right:none;
    width:15px;
    text-align:right;
    padding:0;
   
}

.ordering_tbl td:first-child + td {
    border-right:none;
    width:100%;
    padding:0;
    word-wrap: break-word;
}

.ordering_tbl td:last-child {
    padding:0;
}